import 'date-fns';
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import 
{
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

export default function DatePicker(props) {
    
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {/* <Grid container justify="space-around"> */}
                <KeyboardDatePicker 
                    // margin="normal"
                    id={props.id}
                    name={props.name}
                    label={props.label}
                    format={props.format}
                    value={props.value}
                    onChange={props.change}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    inputVariant="outlined"
                />
            {/* </Grid> */}
        </MuiPickersUtilsProvider>
    );

}
