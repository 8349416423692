
import React from 'react';
import { Component } from 'react';

import { 
    Container, 
    Typography,
    Grid, 
    Card,
    CardHeader,
    CardContent,
    Button,
    TextField,
    TextareaAutosize,
    Dialog,
    DialogTitle,
    DialogContent, 
    DialogContentText,
    DialogActions,
    Image,
    List,
    ListItem,
    ListItemText,
    Divider,
} from '@material-ui/core';
import { ThemeProvider, withStyles } from '@material-ui/core/styles';

class DocumentViewer extends Component{

    // componentDidUpdate(prevProps){
    //     if(this.props.selectedFile !== prevProps.selectedFile){
    //         this.setState({appNo: this.props.appNo}, () => {
    //             this.getEncodedGrades();
    //         })
    //     }
    // }

    componentDidMount(){

    }

    render(){

        return (
            <Grid item xs={12} sm={12}> 
                <Typography variant="h6">{this.props.selectedDocName}</Typography>
                <Grid item >
                    <Typography variant="subtitle1" style={{display:"inline"}}>Status: {this.props.selectedFile.isVerified ? 'Verified' : 'Unverified' }</Typography> 
                    <Typography variant="body1" style={{display:"inline",marginLeft:"10px",marginRight:"10px"}}>|</Typography>
                    <Typography variant="subtitle1" style={{display:"inline"}}>Verified By: {this.props.selectedFile.checkedBy}</Typography>
                    {this.props.selectedFile.remarks ? <Typography variant="subtitle1" style={{display:"block"}}>Remarks: {this.props.selectedFile.remarks}</Typography> : null} 
                </Grid>
            </Grid> 
        )
    }
}

export default DocumentViewer;