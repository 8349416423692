import React, { useState } from 'react';
import { 
    Container, 
    Typography,
    Grid, 
    Button,
    Paper,
    TableContainer,
    TableRow,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent, 
    DialogContentText,
    DialogActions,
} from '@material-ui/core';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';

const forgotAccountDialog = (props) => { 
    
    // let [forgotAccountBtnClicked, setForgotAccountBtnClicked] = useState(true);
    
    let [formValues, setFormValues] = useState({
        forgotAccountMiddleName: '',
        forgotAccountBirthdate: '',
        forgotAccountEmail: '',
        forgotAccountMobileNumber: ''
    });

    const handleInputChange = (e) => {    
        const {name, value } = e.target;
        setFormValues({...formValues, [name]: value});
    }

    // const handleCancelConfirmDialog = () => {
    //     setForgotAccountBtnClicked(false);
    // }

    const handleSubmitBtnClick = () => {
        props.forgotAccountHandler(formValues);
    }

    return(
        
        <Dialog fullScreen open >
            <DialogContent>
                <DialogContentText >
                    <Typography paragraph variant="body1">
                        Please provide the following data to retrieve your forgotten account: &nbsp;&nbsp;
                        <br/>
                    </Typography>
                </DialogContentText>
                <TextField 
                    id="forgotAccountMiddleName"
                    name="forgotAccountMiddleName"
                    label="Middle Name"
                    onBlur={handleInputChange}
                    fullWidth
                    variant="outlined"
                    required 
                    style={{marginBottom:'8px'}}
                />
                <TextField 
                    id="forgotAccountBirthdate"
                    name="forgotAccountBirthdate"
                    label="Birthdate"
                    onBlur={handleInputChange}
                    fullWidth
                    variant="outlined"
                    required
                    helperText='Format: mm/dd/yyyy'  
                    style={{marginBottom:'8px'}}
                />
                <TextField 
                    id="forgotAccountEmail"
                    name="forgotAccountEmail"
                    label="Email"
                    onBlur={handleInputChange}
                    fullWidth
                    variant="outlined"
                    required  
                    style={{marginBottom:'8px'}}
                />
                <TextField 
                    id="forgotAccountMobileNumber"
                    name="forgotAccountMobileNumber"
                    label="Mobile Number"
                    onBlur={handleInputChange}
                    fullWidth
                    variant="outlined"
                    required 
                    style={{marginBottom:'8px'}}
                />
                <Button autoFocus onClick={props.handleForgotAccountCancelBtnClicked} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmitBtnClick} color="primary">
                    Submit
                </Button>
            </DialogContent>
        </Dialog>

  
    )

}

export default forgotAccountDialog;
