import React from "react";
import {Route} from "react-router-dom";

import "./App.css";
import {Grid,} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import Header from "./components/Header";
import DocView from "./components/DocView";
// import DocViewMenu from './components/DocViewMenu';
import Rankings from "./components/Rankings";
import AppStatusChecker from "./components/AppStatusChecker";
import ResultChecker from "./components/ResultChecker";
import ResultCheckerBeta from "./components/ResultCheckerBeta";
import LandingPage from "./components/LandingPage";
import ResultsReleasedNotice from "./components/ResultsReleasedNotice";
import RegistrationForm from "./components/RegistrationForm";

// react pdf
import {DownloadNOAP, ViewNOAP} from "./reports/NOAP";

const app = () => {
   const style = makeStyles((theme) => ({
      root: {
         width: "100%",
         background: "transparent",
      },
      helloThereStyle: {
         fontStyle: "oblique",
      },
   }));

   const {root, helloThereStyle} = style();


   return (
      <Grid container direction="column">
         <Header/>
         <Route path="/" exact component={LandingPage}/>
         {/* <Route path="/" exact component={ResultChecker}/> */}
         {/* <Route path="/ForTestingOnly_NotOfficial" exact component={ResultCheckerBeta}/> */}
         <Route path="/ForAdmissionUnitOnly_Confirmation" exact component={ResultCheckerBeta}/>
         {/* <Route path="/" exact component={ResultChecker}/> */}
      </Grid>
   );
};

export default app;
