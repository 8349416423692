import React, { useState, useCallback } from 'react'
import ReactDOM from 'react-dom'
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import ImgDialog from './ImgDialog'
import getCroppedImg from './cropImage'
import {getCroppedImgBlob} from './cropImage'
import { styles } from './styles'
import { Grid } from '@material-ui/core'


const ImageCropper = (props) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [croppedImageBlob, setCroppedImageBlob] = useState(null)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const cancelCroppedImage = () => {
    props.returnFromPreview();
    setCroppedImage(null);
  }

  const handleSaveCroppedImage = () => {
      
  }

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        props.selectedImage,
        croppedAreaPixels,
        rotation
      )
      const croppedImageBlob = await getCroppedImgBlob(
        props.selectedImage,
        croppedAreaPixels,
        rotation
      )
      
      props.onPreview();

      console.log('donee', { croppedImage })
      setCroppedImage(croppedImage)
      setCroppedImageBlob(croppedImageBlob)

    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, rotation])

  const onClose = useCallback(() => {
    setCroppedImage(null)
  }, [])

  return (
    <div>
        {!croppedImage ? (
            // <Grid item container maxWidth="lg" xs={12} sm={12}>
            <React.Fragment style={{margin:"0 auto"}}> 
                <Grid item xs={12} sm={12}>
                    <div className={props.classes.cropContainer} style={{margin:"0 auto",width:"100%"}}>
                        <Cropper
                        image={props.selectedImage}
                        crop={crop}
                        rotation={rotation}
                        zoom={zoom}
                        aspect={3.5 / 4.5}
                        onCropChange={setCrop}
                        onRotationChange={setRotation}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <div className={props.classes.sliderContainer}>
                    <Typography
                        variant="overline"
                        classes={{ root: props.classes.sliderLabel }}
                    >
                        Zoom
                    </Typography>
                    <Slider
                        value={zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom"
                        classes={{ root: props.classes.slider }}
                        onChange={(e, zoom) => setZoom(zoom)}
                    />
                    </div>
                
                    <Button
                    onClick={showCroppedImage}
                    variant="contained"
                    color="primary"
                    classes={{ root: props.classes.cropButton }}
                    >
                    Preview
                    </Button>
                </Grid>
            </React.Fragment> 
            //  </Grid>
        ) : null }

        {croppedImage ? (
          <React.Fragment>
            <Grid item xs={12} sm={12}>
                {/* <div className={props.classes.cropContainer}> */}
                    <img src={croppedImage} width="100%" />
                {/* </div> */}
            </Grid>
            <Grid item xs={12} sm={12}>
                <div>
                    <Button onClick={cancelCroppedImage}>Back</Button>
                    <Button onClick={() => {props.onSave(croppedImage,croppedImageBlob)} }>Save</Button>
                </div>
            </Grid>
          </React.Fragment>
        ) : null }

    </div>
  )
}

export default withStyles(styles)(ImageCropper)
