import React, { createRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import {nanoid} from 'nanoid';
import { Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import Cropper from 'react-easy-crop';
import { format } from 'date-fns';
import AppDocPdf from './AppDocPdf';
import HorizontalStepper from './HorizontalLinearStepper';
import ImageCropper from './ImageCropper';
import PassportPhotoDialog  from './PassportPhotoDialog';
import IDPhotoDialog  from './IDPhotoDialog';
import PdfViewer from './PdfViewer';
import { PDFDownloadLink, BlobProvider, pdf } from '@react-pdf/renderer'
import {isMobile,isDesktop} from 'react-device-detect';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Container from '@material-ui/core/Container';
import { 
    Button, 
    Card, 
    CardContent, 
    CardHeader, 
    Select, 
    MenuItem, 
    TextField, 
    InputLabel, 
    Dialog,
    DialogTitle,
    DialogContent, 
    DialogContentText,
    DialogActions,
    Paper,
    Slider,
    IconButton,
    ListItem,
    AppBar,
    Toolbar,
    ListItemText,
    Divider,
    Box,
    List,
    Avatar,
    CircularProgress,
    Backdrop,
    FormGroup,
    Switch,
    Checkbox
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Component } from 'react';
import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import io from 'socket.io-client';
import DatePicker from './DatePicker';
import * as actionTypes from '../store/actions';

import gradeSubjectInputGuide from './../finalGradeInputGuide.PNG' ;
import gradeSubjectInputGuideMath from './../finalGradeInputGuide-Mat.PNG' ;
import gradeSubjectInputGuideScience from './../finalGradeInputGuide-Sci.PNG' ;
import gradeAvgInputGuide from './../gradeAvgInputGuide.PNG' ;
import gradeAvgInputGuideCollege from './../gradeAvgInputGuide-college.PNG' ;
import landingImage from './../landing-image.png' ;
import dataPrivacyImage from './../DataPrivacy.jpg' ;
import usepLogoHeader from './../usep-logo.png' ; 
import usepLogoAltColor from './../usep-logo-alt-color.PNG' ; 
import passportPhotoSample from './../passport-photo-sample.png' ; 
import idPhotoSample from './../id-photo-sample.png' ; 

import { API_URL } from './config';
// const usep_logo = require('../../src/usep-logo.png');

const barangay_list = require('../phil_barangays_2019.json');
const sexOptions = [
    {type: 'Male', value: 'M'},
    {type: 'Female', value: 'F'},
];
const connectivityOptions = [
    {type: 'FAST (Regular subscription/postpaid. i.e. PLDT, GLOBE, SKY, etc.)', value: '2'},
    {type: 'SLOW (Mobile Data dependent/prepaid)', value: '1'},
    {type: 'POOR or NO internet connection', value: '0'},
];
const suffixOptions = [
    'Sr.',
    'Jr.',
    'I',
    'II',
    'III',
    'IV',
    'VI',
    'VII',
    'VIII',
    'IX',
    'X',
    'XI',
    'XII',
];
const yearGraduatedOptions = [
    '2000','2001','2002','2003','2004','2005','2006','2007','2008','2009',
    '2010','2011','2012','2013','2014','2015','2016','2017','2018','2019','2020','2021','2022'
];
const civilStatusOptions = [
    {type: 'Single', value: 1},
    {type: 'Married', value: 2},
    {type: 'Separated', value: 3},
    // {type: 'Widow/Widower', value: 4},
    {type: 'Single Parent', value: 5},
];
const campusOptions = [
    {type: 'Obrero', value: 1},
    {type: 'Tagum', value: 7},
    {type: 'Mintal', value: 6},
    {type: 'Mabini', value: 8},
];
const numOfChildrenOptions = [
    {type: '1', value: 1},
    {type: '2', value: 2},
    {type: '3', value: 3},
    {type: '4', value: 4},
    {type: '5', value: 5},
    {type: '6', value: 6},
    {type: '7', value: 7},
    {type: '8', value: 8},
    {type: '9', value: 9},
    {type: '10', value: 10},
];
const submitConfirmMsg1 = `By uploading my signature, I hereby certify that all information I have supplied in the foregoing application are true and correct to the best of my knowledge.  Moreover, by affixing my full name it shall be construed that I have hereunto affixed my own official signature.` 

const reviewSteps = [
    {stepID: 1, stepName: 'Review Application Form'},
    {stepID: 2, stepName: 'Review Form 137'},
    {stepID: 3, stepName: 'Review Income Source'},
    {stepID: 4, stepName: 'Review Signature'},
]
const reviewStepsTransferee = [
    {stepID: 1, stepName: 'Review Application Form'},
    {stepID: 2, stepName: 'Review ToR'},
    {stepID: 3, stepName: 'Review Income Source'},
    {stepID: 4, stepName: 'Review Signature'},
]
const reviewStepsIPMember = [
    {stepID: 1, stepName: 'Review Application Form'},
    {stepID: 2, stepName: 'Review Form 137'},
    {stepID: 3, stepName: 'Review Income Source'},
    {stepID: 4, stepName: 'Review Signature'},
    {stepID: 5, stepName: 'Review IP Membership Certificate'},
]
const reviewStepsIPMemberTransferee = [
    {stepID: 1, stepName: 'Review Application Form'},
    {stepID: 2, stepName: 'Review ToR'},
    {stepID: 3, stepName: 'Review Income Source'},
    {stepID: 4, stepName: 'Review Signature'},
    {stepID: 5, stepName: 'Review IP Membership Certificate'},
]


let socket = null;
 
const styles = theme => ({
    formHeader: {
        fontFamily: `"Quicksand", sans-serif`,
        fontWeight: 500,
        color:  `#d58a13`, //"#707070",
        marginTop: `15px`,
        marginBottom: `20px`

    },
    appTypeRadio: {
        // width: `50%`,
        // margin: `0 auto`
    },
    sectionHeader: {
        backgroundColor: `#d58a13`,  //`#707070a6`, //`#dc9011`, //#707070a6
        color: 'white'
    },
    sectionCardHeader: {
        fontSize: 100,
    },
    confirmSubmissionMsg: {
        fontFamily: `"Quicksand", "sans-serif"`,
        fontSize: 18,
        fontWeight: 400,
        color: 'black'
    },
    txtFields: {
        margin: theme.spacing(2)
    },
    modalAppBar: {
        backgroundColor: `#7c0606`,
        position: `relative`
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    pandemicQuestionDiv: {
        padding: `0 10 20 10`,
        borderLeft: `solid 1px silver`,
    },
    pandemicQuestionHeader: {
        color: `black`,
        fontSize: `1.25rem`,
        padding: `0px 5px 5px`,
    } 
    
});

class RegistrationForm extends Component {

    state = {
        isAdmissionOpen: true,
        clientDevice: '',

        fLastNameTemp: '',
        fFirstNameTemp: '',
        fMiddleNameTemp: '',
        fSuffixTemp: '',
        fOccupationTemp: '',
        fAnnualIncomeTemp: '',
        fAnnualIncomeTxtTemp: '',

        mLastNameTemp: '',
        mFirstNameTemp: '',
        mMiddleNameTemp: '',
        mOccupationTemp: '',
        mAnnualIncomeTemp: '',
        mAnnualIncomeTxtTemp: '',

        checkedEnableGuardianInput: false,

        fAnnualIncomeObj: null,
        fAnnualIncomeInputVal: '',
        mAnnualIncomeObj: null,
        mAnnualIncomeInputVal: '',
        fSuffixInputVal: '',

        trackObj: null,
        trackInputVal: '',
        strandObj:null,
        strandInputVal: '',

        presentAddressRegionObj: null,
        presentAddressProvinceObj: null,
        presentAddressCityObj: null,
        presentAddressBrgyObj: null,
        
        permanentAddressRegionObj: null,
        permanentAddressProvinceObj: null,
        permanentAddressCityObj: null,
        permanentAddressBrgyObj: null,

        campus1Obj: null,
        campus2Obj: null,
        campus3Obj: null,
        course1Obj: null,
        course2Obj: null,
        course3Obj: null,
        major1Obj: null,
        major2Obj: null,
        major3Obj: null,
        isMajor1Required: false,
        isMajor2Required: false,
        isMajor3Required: false,

        campus1InputVal: '',
        campus2InputVal: '',
        campus3InputVal: '',
        course1InputVal: '',
        course2InputVal: '',
        course3InputVal: '',
        major1InputVal: '',
        major2InputVal: '',
        major3InputVal: '',

        clientID: '',
        apiResponseError: false,
        showSystemUsabilityTestDialog: false,
        isReviewingPhoto: false,
        choosePassportPhotoClicked: false,
        chooseIDPhotoClicked: false,
        showImageCropper: false,
        alertOnPageLoad: false,
        currentReviewStep: 0,
        isReviewComplete: false,
        file_form137_src: '',
        file_ToR_src: '',
        file_incomeSource_src: '',
        file_applicantSignature_src: '',
        file_IPmembershipCert_src: '',

        permAddressDetailsChanged: false,
        checkedCopyCurrentAdd: false,
        viewSamplePhoto: false,
        selectedSamplePhoto: '',
        submitting: false,
        gLastName: '',
        gLastNameIsValid: {
            status: false,
            message: '',
            propName: `Guardian's Last Name`,
            focused: false
        },
        gFirstName: '',
        gFirstNameIsValid: {
            status: false,
            message: '',
            propName: `Guardian's First Name`,
            focused: false
        },
        gMiddleName: '',
        gMiddleNameIsValid: {
            status: false,
            message: '',
            propName: `Guardian's Middle Name`,
            focused: false
        },
        gOccupation: '',
        gOccupationIsValid: {
            status: false,
            message: '',
            propName: `Guardian's Occupation`,
            focused: false
        },
        gAnnualIncome: '',
        gAnnualIncomeIsValid: {
            status: false,
            message: '',
            propName: `Guardian's Annual Income`,
            focused: false
        },
        gRelationship: '',
        gRelationshipIsValid: {
            status: false,
            message: '',
            propName: `Guardian's Relationship`,
            focused: false
        },
        isApplicationDocDownloaded: false,
        isDownloadApplicatioDocClicked: false,
        isDuplicateApplicant: false,
        showDuplicateApplicantNotice: false,
        fileIsSelected: false,
        uploadedFileInfo: {
            status: false,
            title: '',
            message: ''
        },
        firstPageLoad: true,
        dataPrivacyLoad: false,
        applicationType: '1',
        // applicantType: '',
        lastName: '',
        lastNameIsValid: {
            status: false,
            message: '',
            propName: 'Last Name',
            focused: false
        },
        firstName: '',
        firstNameIsValid: {
            status: false,
            message: '',
            propName: 'First Name',
            focused: false
        },
        middleName: '',
        middleNameIsValid: {
            status: false,
            message: '',
            propName: 'Middle Name',
            focused: false
        },
        suffix: '',
        birthdate: format(new Date(), 'MM/dd/yyyy'), 
        birthdateIsValid: {
            status: false,
            message: '',
            propName: 'Date of Birth',
            focused: false
        },

        birthplace: '',
        birthplaceIsValid: {
            status: false,
            message: '',
            propName: 'Place of Birth',
            focused: false
        },

        gender: '',
        genderIsValid: {
            status: false,
            message: '',
            propName: 'Sex',
            focused: false
        },
        genderTxt: '',

        civilStatus: '',
        civilStatusIsValid: {
            status: false,
            message: '',
            propName: 'Civil Status',
            focused: false
        },
        civilStatusTxt: '',

        singleParentType: '',
        otherSingleParentDetail: '',
        numberOfChildren: '',

        citizenship: '',
        citizenshipIsValid: {
            status: false,
            message: '',
            propName: 'Citizenship',
            focused: false
        },
        citizenshipTxt: '',

        religion: '',
        religionIsValid: {
            status: false,
            message: '',
            propName: 'Religion',
            focused: false
        },
        religionTxt: '',

        email: '',
        emailIsValid: {
            status: false,
            message: '',
            propName: 'Email',
            focused: false
        },

        contactNumber: '',
        contactNumberIsValid: {
            status: false,
            message: '',
            propName: 'Contact Number',
            focused: false
        },

        presentAddressRegion: '',
        presentAddressRegionIsValid: {
            status: false,
            message: '',
            propName: 'Present Address|Region',
            focused: false
        },
        presentAddressProvince: '',
        presentAddressProvinceIsValid: {
            status: false,
            message: '',
            propName: 'Present Address|Province',
            focused: false
        },
        presentAddressCity: '',
        presentAddressCityIsValid: {
            status: false,
            message: '',
            propName: 'Present Address|City',
            focused: false
        },
        presentAddressBrgy: '',
        presentAddressBrgyIsValid: {
            status: false,
            message: '',
            propName: 'Present Address|Brgy',
            focused: false
        },
        presentAddressStreet: '',
        presentAddressStreetIsValid: {
            status: false,
            message: '',
            propName: 'Present Address|Street',
            focused: false
        },
        presentAddressZipCode: '',
        presentAddressZipCodeIsValid: {
            status: false,
            message: '',
            propName: 'Present Address|ZipCode',
            focused: false
        },

        permanentAddressRegion: '',
        permanentAddressRegionIsValid: {
            status: false,
            message: '',
            propName: 'Permanent Address|Region',
            focused: false
        },
        permanentAddressProvince: '',
        permanentAddressProvinceIsValid: {
            status: false,
            message: '',
            propName: 'Permanent Address|Province',
            focused: false
        },
        permanentAddressCity: '',
        permanentAddressCityIsValid: {
            status: false,
            message: '',
            propName: 'Permanent Address|City',
            focused: false
        },
        permanentAddressBrgy: '',
        permanentAddressBrgyIsValid: {
            status: false,
            message: '',
            propName: 'Permanent Address|Brgy',
            focused: false
        },
        permanentAddressStreet: '',
        permanentAddressStreetIsValid: {
            status: false,
            message: '',
            propName: 'Permanent Address|Region',
            focused: false
        },
        permanentAddressZipCode: '',
        permanentAddressZipCodeIsValid: {
            status: false,
            message: '',
            propName: 'Permanent Address|ZipCode',
            focused: false
        },

        hasSpecialNeeds: '0',
        specialNeed: '',
        specialNeedIsValid: {
            status: false,
            message: '',
            propName: 'Special Need',
            focused: false
        },
        otherSpecialNeedDetail: '',

        isMemberOfIP: '0',
        tribe: '',
        tribeIsValid: {
            status: false,
            message: '',
            propName: 'Tribe',
            focused: false
        },
        otherTribeDetail: '',
       
        fParentType: '',
        fParentTypeIsValid: {
            status: false,
            message: '',
            propName: 'Father Information',
            focused: false
        },
        fLastName: '',
        fLastNameIsValid: {
            status: false,
            message: '',
            propName: `Father's Last Name`,
            focused: false
        },
        fFirstName: '',
        fFirstNameIsValid: {
            status: false,
            message: '',
            propName: `Father's First Name`,
            focused: false
        },
        fMiddleName: '',
        fMiddleNameIsValid: {
            status: false,
            message: '',
            propName: `Father's Middle Name`,
            focused: false
        },
        fSuffix: '',
        fOccupation: '',
        fOccupationIsValid: {
            status: false,
            message: '',
            propName: `Father's Occupation`,
            focused: false
        },
        fAnnualIncome: '',
        fAnnualIncomeIsValid: {
            status: false,
            message: '',
            propName: `Father's AnnualIncome`,
            focused: false
        },
        fAnnualIncomeTxt: '',

        mParentType: '',
        mParentTypeIsValid: {
            status: false,
            message: '',
            propName: `Mother Information`,
            focused: false
        },
        mLastName: '',
        mLastNameIsValid: {
            status: false,
            message: '',
            propName: `Mother's Last Name`,
            focused: false
        },
        mFirstName: '',
        mFirstNameIsValid: {
            status: false,
            message: '',
            propName: `Mother's First Name`,
            focused: false
        },
        mMiddleName: '',
        mMiddleNameIsValid: {
            status: false,
            message: '',
            propName: `Mother's Middle Name`,
            focused: false
        },
        mSuffix: '',
        mOccupation: '',
        mOccupationIsValid: {
            status: false,
            message: '',
            propName: `Mother's Occupation`,
            focused: false
        },
        mAnnualIncome: '',
        mAnnualIncomeIsValid: {
            status: false,
            message: '',
            propName: `Mother's Annual Income`,
            focused: false
        },
        mAnnualIncomeTxt: '',

        elemSchool: '',
        elemSchoolIsValid: {
            status: false,
            message: '',
            propName: `Elementary School`,
            focused: false
        },
        elemSchoolType: '',
        elemSchoolTypeIsValid: {
            status: false,
            message: '',
            propName: `Elementary School Type`,
            focused: false
        },
        elemAddress: '',
        elemAddressIsValid: {
            status: false,
            message: '',
            propName: `Elementary School Address`,
            focused: false
        },
        elemYearGraduated: '',
        elemYearGraduatedIsValid: {
            status: false,
            message: '',
            propName: `Year Graduated Elementary School`,
            focused: false
        },
        jhSchool: '',
        jhSchoolIsValid: {
            status: false,
            message: '',
            propName: `Junior High School`,
            focused: false
        },
        jhSchoolType: '',
        jhSchoolTypeIsValid: {
            status: false,
            message: '',
            propName: `Junior High School Type`,
            focused: false
        },
        jhYearGraduated: '',
        jhYearGraduatedIsValid: {
            status: false,
            message: '',
            propName: `Year Graduated Junior High School`,
            focused: false
        },
        jhAddress: '',
        jhAddressIsValid: {
            status: false,
            message: '',
            propName: `Junior High School Address`,
            focused: false
        },
        shSchool: '',
        shSchoolIsValid: {
            status: false,
            message: '',
            propName: `Senior High School`,
            focused: false
        },
        shSchoolType: '',
        shSchoolTypeIsValid: {
            status: false,
            message: '',
            propName: `Senior High School Type`,
            focused: false
        },
        shYearGraduated: '',
        shYearGraduatedIsValid: {
            status: false,
            message: '',
            propName: `Year Graduated Senior High School`,
            focused: false
        },
        shAddress: '',
        shAddressIsValid: {
            status: false,
            message: '',
            propName: `Senior High School Address`,
            focused: false
        },
        collegeSchool: '',
        collegeSchoolIsValid: {
            status: false,
            message: '',
            propName: `College School`,
            focused: false
        },
        collegeSchoolType: '',
        collegeSchoolIsValid: {
            status: false,
            message: '',
            propName: `College School`,
            focused: false
        },
        collegeYearGraduated: '',
        collegeYearGraduatedIsValid: {
            status: false,
            message: '',
            propName: `Year Graduated College`,
            focused: false
        },
        collegeAddress: '',
        collegeAddressIsValid: {
            status: false,
            message: '',
            propName: `College Address`,
            focused: false
        },
        grade9GradeEnglish: '',
        grade9GradeEnglishIsValid: {
            status: false,
            message: '',
            propName: `Grade 9 English Grade`,
            focused: false
        },
        grade10GradeEnglish: '',
        grade10GradeEnglishIsValid: {
            status: false,
            message: '',
            propName: `Grade 10 English Grade`,
            focused: false
        },
        grade11GradeEnglish: '',
        grade11GradeEnglishIsValid: {
            status: false,
            message: '',
            propName: `Grade 11 English Grade`,
            focused: false
        },
        grade12GradeEnglish: '',
        collegeGradeEnglish: '',
        collegeGradeEnglishIsValid: {
            status: false,
            message: '',
            propName: `College English Grade`,
            focused: false
        },
        grade9GradeMath: '',
        grade9GradeMathIsValid: {
            status: false,
            message: '',
            propName: `Grade 9 Math Grade`,
            focused: false
        },
        grade10GradeMath: '',
        grade10GradeMathIsValid: {
            status: false,
            message: '',
            propName: `Grade 10 Math Grade`,
            focused: false
        },
        grade11GradeMath: '',
        grade11GradeMathIsValid: {
            status: false,
            message: '',
            propName: `Grade 11 Math Grade`,
            focused: false
        },
        grade12GradeMath: '',
        collegeGradeMath: '',
        collegeGradeMathIsValid: {
            status: false,
            message: '',
            propName: `College Math Grade`,
            focused: false
        },
        grade9GradeScience: '',
        grade9GradeScienceIsValid: {
            status: false,
            message: '',
            propName: `Grade 9 Science Grade`,
            focused: false
        },
        grade10GradeScience: '',
        grade10GradeScienceIsValid: {
            status: false,
            message: '',
            propName: `Grade 10 Science Grade`,
            focused: false
        },
        grade11GradeScience: '',
        grade11GradeScienceIsValid: {
            status: false,
            message: '',
            propName: `Grade 11 Science Grade`,
            focused: false
        },
        grade12GradeScience: '',
        collegeGradeScience: '',
        collegeGradeScienceIsValid: {
            status: false,
            message: '',
            propName: `College Science Grade`,
            focused: false
        },
        grade9GPA: '',
        grade9GPAIsValid: {
            status: false,
            message: '',
            propName: `Grade 9 GPA`,
            focused: false
        },
        grade10GPA: '',
        grade10GPAIsValid: {
            status: false,
            message: '',
            propName: `Grade 10 GPA`,
            focused: false
        },
        grade11GPA: '',
        grade11GPAIsValid: {
            status: false,
            message: '',
            propName: `Grade 11 GPA`,
            focused: false
        },
        grade12GPA: '',
        collegeGPA: '',
        collegeGPAIsValid: {
            status: false,
            message: '',
            propName: `College GPA`,
            focused: false
        },
        track: 1,
        trackIsValid: {
            status: false,
            message: '',
            propName: `Senior High Track`,
            focused: false
        },
        trackTxt: '',
        strand: 1,
        strandIsValid: {
            status: false,
            message: '',
            propName: `Senior High Strand`,
            focused: false
        },
        strandTxt: '',
        otherStrand: '',
        campus1: 1,
        campus1IsValid: {
            status: false,
            message: '',
            propName: `First Campus Choice`,
            focused: false
        },
        campus2: 1,
        campus2IsValid: {
            status: false,
            message: '',
            propName: `Second Campus Choice`,
            focused: false
        },
        campus3: 1,
        campus3IsValid: {
            status: false,
            message: '',
            propName: `Third Campus Choice`,
            focused: false
        },
        course1: 0,
        course1IsValid: {
            status: false,
            message: '',
            propName: `First Course Choice`,
            focused: false
        },
        course2: 0,
        course2IsValid: {
            status: false,
            message: '',
            propName: `Second Course Choice`,
            focused: false
        },
        course3: 0,
        course3IsValid: {
            status: false,
            message: '',
            propName: `Third Course Choice`,
            focused: false
        },
        major1: 0,
        major1IsValid: {
            status: false,
            message: '',
            propName: `First Major Choice`,
            focused: false
        },
        major2: 0,
        major2IsValid: {
            status: false,
            message: '',
            propName: `Second Major Choice`,
            focused: false
        },
        major3: 0,
        major3IsValid: {
            status: false,
            message: '',
            propName: `Third Major Choice`,
            focused: false
        },
        acadYear: 193,
        emergencyContactName: '',
        emergencyContactNameIsValid: {
            status: false,
            message: '',
            propName: `Emergency Contact Name`,
            focused: false
        },
        emergencyContactRelation: '',
        emergencyContactRelationIsValid: {
            status: false,
            message: '',
            propName: `Emergency Contact Relation`,
            focused: false
        },
        emergencyContactAddress: '',
        emergencyContactAddressIsValid: {
            status: false,
            message: '',
            propName: `Emergency Contact Address`,
            focused: false
        },
        emergencyContactNumber: '',
        emergencyContactNumberIsValid: {
            status: false,
            message: '',
            propName: `Emergency Contact Number`,
            focused: false
        },
        testCenter: '',
        passCode: '', 
        nationalities: [],
        coursesOptions: [],
        courseMajorOptions: [],
        shTrackOptions: [],
        shStrandOptions: [],
        annualIncomeOptions: [],
        singleParentTypeOptions: [],
        religionOptions: [],
        specialNeedOptions: [],
        tribeOptions: [],

        file_passportPhoto: null,
        file_passportPhotoIsValid: {
            status: false,
            message: '',
            propName: `Photo w/ Name Tag`,
            focused: false
        },
        file_passportPhotoImgSrc: null,
        file_idPhoto: null,
        file_idPhotoIsValid: {
            status: false,
            message: '',
            propName: `Photo w/ ID Card`,
            focused: false
        },
        file_idPhotoImgSrc: null,
        file_fParentITR: null,
        file_mParentITR: null,
        file_incomeSource: null,
        file_incomeSourceIsValid: {
            status: false,
            message: '',
            propName: `Income Source`,
            focused: false
        },
        file_form137: null,
        file_form137IsValid: {
            status: false,
            message: '',
            propName: `Form 137`,
            focused: false
        },
        file_ToR: null,
        file_ToRIsValid: {
            status: false,
            message: '',
            propName: `ToR`,
            focused: false
        },
        file_IPmembershipCert: null,
        file_IPmembershipCertIsValid: {
            status: false,
            message: '',
            propName: `IP Membership Certificate`,
            focused: false
        },
        file_applicantSignature: null,
        file_applicantSignatureIsValid: {
            status: false,
            message: '',
            propName: `Applicant's signature`,
            focused: false
        },

        hasConfirmedSubmission: false,
        hasClickedReviewForm: false,
        hasSubmittedInvalidForm: false,
        hasClickedGradeSubjectInput: false,
        selectedGradeSubjectInput: '',
        hasClickedGradeAverageInput: false,
        isSubmitting: false,
        isSubmissionSuccessful: false,

        responsePassCode: null,
        responseAppNo: null,

        crop: { x:0, y:0 },
        zoom: 1,
        aspect: 3.5 / 4.5,
        croppedAreaPixels: null,
        croppedImage: null,

        choosePhotoClicked: false,
        currDiplayedImgSrc: null,

        emptyFields: [],

        hasPwdId: '0',
        pwdId: '',
        pwdIdIsValid: {
            status: false,
            message: '',
            propName: `PWD ID`,
            focused: false
        },

        connectivity: '',
        connectivityIsValid: {
            status: false,
            message: '',
            propName: `Connectivity`,
            focused: false
        },

        gadgetPcChecked: false,
        gadgetCpChecked: true,
        gadgetNone: false,

        vaccinationStatus: '2',
        hasVaccinationPlan: '1'

    };

    requiredValidators = [
        'lastNameIsValid',
        'firstNameIsValid',
        'birthplaceIsValid',
        'genderIsValid',

        'civilStatusIsValid',
        'citizenshipIsValid',
        'religionIsValid',

        'presentAddressRegionIsValid',
        'presentAddressProvinceIsValid',
        'presentAddressCityIsValid',
        'presentAddressBrgyIsValid',
        'presentAddressStreetIsValid',
        'presentAddressZipCodeIsValid',

        'permanentAddressRegionIsValid',
        'permanentAddressProvinceIsValid',
        'permanentAddressCityIsValid',
        'permanentAddressBrgyIsValid',
        'permanentAddressStreetIsValid',
        'permanentAddressZipCodeIsValid',

        'emailIsValid',
        'contactNumberIsValid',

        'fParentTypeIsValid',
        // 'fLastNameIsValid',
        // 'fFirstNameIsValid',
        // 'fOccupationIsValid',
        // 'fAnnualIncomeIsValid',
        'mParentTypeIsValid',
        // 'mLastNameIsValid',
        // 'mFirstNameIsValid',
        // 'mOccupationIsValid',
        // 'mAnnualIncomeIsValid',

        'elemSchoolIsValid',
        'elemSchoolTypeIsValid',
        'elemAddressIsValid',
        'elemYearGraduatedIsValid',
        'jhSchoolIsValid',
        'jhSchoolTypeIsValid',
        'jhYearGraduatedIsValid',
        'jhAddressIsValid',
        'shSchoolIsValid',
        'shSchoolTypeIsValid',
        'shYearGraduatedIsValid',
        'shAddressIsValid',
        // 'collegeSchoolIsValid',
        // 'collegeSchoolTypeIsValid',
        // 'collegeYearGraduatedIsValid',
        // 'collegeAddressIsValid',

        'grade9GradeEnglishIsValid',
        'grade10GradeEnglishIsValid',
        'grade11GradeEnglishIsValid',
        'grade12GradeEnglishIsValid',
        'grade9GradeMathIsValid',
        'grade10GradeMathIsValid',
        'grade11GradeMathIsValid',
        'grade12GradeMathIsValid',
        'grade9GradeScienceIsValid',
        'grade10GradeScienceIsValid',
        'grade11GradeScienceIsValid',
        'grade12GradeScienceIsValid',
        'grade9GPAIsValid',
        'grade10GPAIsValid',
        'grade11GPAIsValid',
        'grade12GPAIsValid',
        // 'collegeGPAIsValid',
        'trackIsValid',
        'strandIsValid',
        'campus1IsValid',
        'campus2IsValid',
        'campus3IsValid',
        'course1IsValid',
        'course2IsValid',
        'course3IsValid',
        // 'major1IsValid',
        // 'major2IsValid',
        // 'major3IsValid',

        'file_form137IsValid',
        // 'file_ToRIsValid',
        'file_incomeSourceIsValid',
        'file_passportPhotoIsValid',
        'file_idPhotoIsValid',
        'file_applicantSignatureIsValid',
        // 'file_IPmembershipCertIsValid',

        'emergencyContactNameIsValid',
        'emergencyContactRelationIsValid',
        'emergencyContactAddressIsValid',
        'emergencyContactNumberIsValid',

        'connectivityIsValid'
    ]

    requiredFields = [
        'applicationType',
        'lastName',
        'firstName',
        'middleName',
        'suffix',
        'birthdate',
        'birthplace',
        'gender',
        'civilStatus',
        'citizenship',
        'religion',

        'presentAddressRegion',
        'presentAddressProvince',
        'presentAddressCity',
        'presentAddressBrgy',
        'presentAddressStreet',
        'presentAddressZipCode',

        'permanentAddressRegion',
        'permanentAddressProvince',
        'permanentAddressCity',
        'permanentAddressBrgy',
        'permanentAddressStreet',
        'permanentAddressZipCode',

        'hasSpecialNeeds',
        'specialNeed',
        'otherSpecialNeedDetail',
        'isMemberOfIP',
        'tribe',
        'otherTribeDetail',

        'email',
        'contactNumber',

        'fParentType',
        'fLastName',
        'fFirstName',
        'fMiddleName',
        'fSuffix',
        'fOccupation',
        'fAnnualIncome',

        'mParentType',
        'mLastName',
        'mFirstName',
        'mMiddleName',
        'mOccupation',
        'mAnnualIncome',

        'gLastName',
        'gFirstName',
        'gMiddleName',
        'gOccupation',
        'gAnnualIncome',
        'gRelationship',

        'elemSchool',
        'elemSchoolType',
        'elemAddress',
        'elemYearGraduated',
        'jhSchool',
        'jhSchoolType',
        'jhYearGraduated',
        'jhAddress',
        'shSchool',
        'shSchoolType',
        'shYearGraduated',
        'shAddress',
        'collegeSchool',
        'collegeSchoolType',
        'collegeYearGraduated',
        'collegeAddress',

        'grade9GradeEnglish',
        'grade10GradeEnglish',
        'grade11GradeEnglish',
        'grade12GradeEnglish',
        'grade9GradeMath',
        'grade10GradeMath',
        'grade11GradeMath',
        'grade12GradeMath',
        'grade9GradeScience',
        'grade10GradeScience',
        'grade11GradeScience',
        'grade12GradeScience',
        'grade9GPA',
        'grade10GPA',
        'grade11GPA',
        'grade12GPA',
        'collegeGPA',
        'track',
        'strand',
        'otherStrand',
        'campus1',
        'campus2',
        'campus3',
        'course1',
        'course2',
        'course3',
        'major1',
        'major2',
        'major3',

        'file_form137',
        'file_ToR',
        'file_incomeSource',
        'file_passportPhoto',
        'file_idPhoto',
        'file_applicantSignature',
        'file_IPmembershipCert',

        'emergencyContactName',
        'emergencyContactRelation',
        'emergencyContactAddress',
        'emergencyContactNumber',

        'acadYear',

        'hasPwdId',
        'pwdId',
        'connectivity',
        
        'gadgetPcChecked',
        'gadgetCpChecked',
        'gadgetNone',
        'gadgets',

        'vaccinationStatus',
        'hasVaccinationPlan'

    ];

    handleEnableGuardianInput = (event) => {
        const isChecked = event.target.checked;
        if(isChecked){
            this.setState({
                checkedEnableGuardianInput: true
            });
        }else{
            this.setState({
                checkedEnableGuardianInput: false,
                gFirstName: '',
                gLastName: '',
                gMiddleName: '',
                gRelationship: '',
                gOccupation: '',
                gAnnualIncome: '',
                gFirstNameIsValid: {...this.state.gFirstNameIsValid, status:true, message: ''},
                gLastNameIsValid: {...this.state.gLastNameIsValid, status:true, message: ''},
                gMiddleNameIsValid: {...this.state.gMiddleNameIsValid, status:true, message: ''},
                gRelationshipIsValid: {...this.state.gRelationshipIsValid, status:true, message: ''},
                gOccupationIsValid: {...this.state.gOccupationIsValid, status:true, message: ''},
                gAnnualIncomeIsValid: {...this.state.gAnnualIncomeIsValid, status:true, message: ''},
            });
        }
        
    }

    handleReviewStepChange = (step) => {
        this.setState({currentReviewStep: step}, () => {
            // alert(`step: ${step}`)
            if(step === 10){ //denotes review completed
                this.setState({isReviewComplete: true});
            }
            if(step === 2 && this.state.applicationType === '1'){
                const reader = new FileReader();
                if(this.state.file_form137){
                    // reader.readAsDataURL(this.state.file_form137);
                    reader.onload = (e) => {
                        this.setState({file_form137_src: e.target.result});
                    }
                    reader.readAsDataURL(this.state.file_form137);
                }else{
                    this.setState({file_form137_src: ''});
                }
            }
            if(step === 2 && this.state.applicationType === '2'){
                const reader = new FileReader();
                if(this.state.file_ToR){
                    // reader.readAsDataURL(this.state.file_ToR);
                    reader.onload = (e) => {
                        this.setState({file_ToR_src: e.target.result});
                    }
                    reader.readAsDataURL(this.state.file_ToR);
                }else{
                    this.setState({file_ToR_src: ''});
                }
            }
            if(step === 3){
                const reader = new FileReader();
                if(this.state.file_incomeSource){
                    // reader.readAsDataURL(this.state.file_incomeSource);
                    reader.onload = (e) => {
                        this.setState({file_incomeSource_src: e.target.result});
                    }
                    reader.readAsDataURL(this.state.file_incomeSource);
                }else{
                    this.setState({file_incomeSource_src: ''});
                }
            }
            if(step === 4){
                const reader = new FileReader();
                if(this.state.file_applicantSignature){
                    // reader.readAsDataURL(this.state.file_applicantSignature);
                    reader.onload = (e) => {
                        this.setState({file_applicantSignature_src: e.target.result});
                    }
                    reader.readAsDataURL(this.state.file_applicantSignature);
                }else{
                    this.setState({file_applicantSignature_src: ''});
                }
            }
            if(step === 5 && this.state.isMemberOfIP === '1'){
                const reader = new FileReader();
                if(this.state.file_IPmembershipCert){
                    // reader.readAsDataURL(this.state.file_IPmembershipCert);
                    reader.onload = (e) => {
                        this.setState({file_IPmembershipCert_src: e.target.result});
                    }
                    reader.readAsDataURL(this.state.file_IPmembershipCert);
                }else{
                    this.setState({file_IPmembershipCert_src: ''});
                }
                
            }
        });
        
    }

    handleCopyCurrentAdd = (event) => {
        const isChecked = event.target.checked;
        console.log(`isChecked: ${isChecked}`);

        if(isChecked){
            this.setState({
                checkedCopyCurrentAdd: event.target.checked,
                permanentAddressRegion: this.state.presentAddressRegion,
                permanentAddressRegionIsValid: true,
                permanentAddressProvince: this.state.presentAddressProvince,
                permanentAddressProvinceIsValid: true,
                permanentAddressCity: this.state.presentAddressCity,
                permanentAddressCityIsValid: true,
                permanentAddressBrgy: this.state.presentAddressBrgy,
                permanentAddressBrgyIsValid: true,
                permanentAddressStreet: this.state.presentAddressStreet,
                permanentAddressStreetIsValid: true,
                permanentAddressZipCode: this.state.presentAddressZipCode,
                permanentAddressZipCodeIsValid: true
            }, () => {
                // this.changeHandler('permanentAddressRegion', this.state.permanentAddressRegion);
                // this.changeHandler('permanentAddressProvince', this.state.permanentAddressProvince);
                // this.changeHandler('permanentAddressCity', this.state.permanentAddressCity);
                // this.changeHandler('permanentAddressBrgy', this.state.permanentAddressBrgy);
                // this.changeHandler('permanentAddressStreet', this.state.permanentAddressStreet);
                // this.changeHandler('permanentAddressZipCode', this.state.permanentAddressZipCode);
            });
        }else{
            this.setState({
                checkedCopyCurrentAdd: event.target.checked,
                // permanentAddressRegion: '',
                // permanentAddressRegionIsValid: false,
                // permanentAddressProvince: '',
                // permanentAddressProvinceIsValid: false,
                // permanentAddressCity: '',
                // permanentAddressCityIsValid: false,
                // permanentAddressBrgy: '',
                // permanentAddressBrgyIsValid: false,
                // permanentAddressStreet: '',
                // permanentAddressStreetIsValid: false,
                // permanentAddressZipCode: '',
                // permanentAddressZipCodeIsValid: false
            }, () => {
                this.changeHandler('permanentAddressRegion', '');
                this.changeHandler('permanentAddressProvince', '');
                this.changeHandler('permanentAddressCity', '');
                this.changeHandler('permanentAddressBrgy', '');
                this.changeHandler('permanentAddressStreet', '');
                this.changeHandler('permanentAddressZipCode', '');
            });
        }
        
    }

    handleViewPhotoSample = (type) => {
        if(type === 'withNameTag'){
            this.setState({
                viewSamplePhoto: true,
                selectedSamplePhoto: 'withNameTag'
            });
        }else{
            this.setState({
                viewSamplePhoto: true,
                selectedSamplePhoto: 'withIDCard'
            });
        }
    }

    handleViewPhotoSampleClose = () => {
        this.setState({
            viewSamplePhoto: false
        })
    }

    handleAppDocDownload = () => {
        this.setState({isDownloadApplicatioDocClicked:true,isApplicationDocDownloaded:true});
    }

    handleCloseAppDocDownload = () => {
        this.setState({isDownloadApplicatioDocClicked:false});
    }

    initializeRequiredFieldValidators = () => {
        const validatorsToExcludeIfTransferee = [
            'file_form137IsValid',
            'grade9GradeEnglishIsValid',
            'grade10GradeEnglishIsValid',
            'grade11GradeEnglishIsValid',
            'grade12GradeEnglishIsValid',
            'grade9GradeMathIsValid',
            'grade10GradeMathIsValid',
            'grade11GradeMathIsValid',
            'grade12GradeMathIsValid',
            'grade9GradeScienceIsValid',
            'grade10GradeScienceIsValid',
            'grade11GradeScienceIsValid',
            'grade12GradeScienceIsValid',
            'grade9GPAIsValid',
            'grade10GPAIsValid',
            'grade11GPAIsValid',
            'grade12GPAIsValid',
            'trackIsValid',
            'strandIsValid',
        ]
        // handly only transferee application type because the default required
        // validators are already tailored for freshmen
        if(this.state.applicationType === '2'){
            let newRequiredValidators = this.requiredValidators.filter( (validator) => {
                return !validatorsToExcludeIfTransferee.includes(validator)
            });
            newRequiredValidators = [...newRequiredValidators, 
                    'file_ToRIsValid',
                    'collegeGPAIsValid',
                    'collegeSchoolIsValid',
                    'collegeSchoolTypeIsValid',
                    'collegeYearGraduatedIsValid',
                    'collegeAddressIsValid',
                ];
            this.requiredValidators = newRequiredValidators;
        }

    }

    handleCloseLandingPage = (appType) => {
        this.setState({
            firstPageLoad: false,
            dataPrivacyLoad: true,
            applicationType: appType
        });
    }

    handleCloseDuplicateApplicantDialog = () => {
        this.setState({showDuplicateApplicantNotice: false });
    }

    handleDataPrivacyAgreement = (choice) => {
        this.initializeRequiredFieldValidators();
        if(choice === 0){
            this.setState({
                firstPageLoad: true,
                dataPrivacyLoad: false,
            })
        }else{
            
            this.setState({
                dataPrivacyLoad: false,
                alertOnPageLoad: true
            })
        }
    }

    handleFileUploadValidation = (fileType,fileToValidate) => {
        if(!fileToValidate) return;

        if(fileType === 'doc'){
            const allowedExtensions = ['pdf'];
            const sizeLimit = 2000000;
            const MB_UNIT = 1000000;

            const { name:fileName, size:fileSize } = fileToValidate;
            const fileExtension = fileName.split(".").pop();

            if(!allowedExtensions.includes(fileExtension)){
                const uploadedFileInfo = {...this.state.uploadedFileInfo};
                uploadedFileInfo.status = false;
                uploadedFileInfo.title = 'Invalid File Type';
                uploadedFileInfo.message = 'Please upload a valid PDF file.'
                // this.setState({uploadedFileInfo: uploadedFileInfo})
                return uploadedFileInfo;
               
            }else if(fileSize > sizeLimit){
                const uploadedFileInfo = {...this.state.uploadedFileInfo};
                uploadedFileInfo.status = false;
                uploadedFileInfo.title = 'File Too Large';
                uploadedFileInfo.message = `Please upload a file not exceeding ${sizeLimit/MB_UNIT}MB`;
                // this.setState({uploadedFileInfo: uploadedFileInfo})
                return uploadedFileInfo;
            }else{
                const uploadedFileInfo = {...this.state.uploadedFileInfo};
                uploadedFileInfo.status = true;
                uploadedFileInfo.title = 'Successful File Upload ';
                uploadedFileInfo.message = 'The selected file has been uploaded.'
                // this.setState({uploadedFileInfo: uploadedFileInfo})
                return uploadedFileInfo;
            }
        }else if(fileType === 'img'){
            const allowedExtensions = ['jpg','png'];
            const sizeLimit = 2000000;
            const MB_UNIT = 1000000;

            const { name:fileName, size:fileSize } = fileToValidate;
            const fileExtension = fileName.split(".").pop();

            if(!allowedExtensions.includes(fileExtension)){
                const uploadedFileInfo = {...this.state.uploadedFileInfo};
                uploadedFileInfo.status = false;
                uploadedFileInfo.title = 'Invalid File Type';
                uploadedFileInfo.message = 'Please upload a valid JPG/PNG image file.'
                // this.setState({uploadedFileInfo: uploadedFileInfo})
                return uploadedFileInfo;
               
            }else if(fileSize > sizeLimit){
                const uploadedFileInfo = {...this.state.uploadedFileInfo};
                uploadedFileInfo.status = false;
                uploadedFileInfo.title = 'File Too Large';
                uploadedFileInfo.message = `Please upload a file not exceeding ${sizeLimit/MB_UNIT}MB`;
                // this.setState({uploadedFileInfo: uploadedFileInfo})
                return uploadedFileInfo;
            }else{
                const uploadedFileInfo = {...this.state.uploadedFileInfo};
                uploadedFileInfo.status = true;
                uploadedFileInfo.title = 'Successful File Upload ';
                uploadedFileInfo.message = 'The selected file has been uploaded.'
                // this.setState({uploadedFileInfo: uploadedFileInfo})
                return uploadedFileInfo;
            }
        }
    }

    changeHandler = (prop,value,txtValue=null) => {
        console.log(`prop: ${prop}; value: ${value} `);
        let isValueValid = true;
        
        if(prop === "trackObj"){
            this.setState({
                trackObj: value,
                strandObj: null,
                strand: '',
                strandInputVal: '',
            })
        }
        if(prop === "strandObj"){
            this.setState({
                strandObj: value,
            })
        }
        if(prop === "course1Obj"){
            this.setState({
                course1Obj: value,
                major1Obj: null,
                major1: '',
            })
        }
        if(prop === "course2Obj"){
            this.setState({
                course2Obj: value,
                major2Obj: null,
                major2: '',
            })
        }
        if(prop === "course3Obj"){
            this.setState({
                course3Obj: value,
                major3Obj: null,
                major3: '',
            })
        }

        if(prop === "presentAddressRegionObj"){
            this.setState({
                presentAddressRegionObj: value,
                presentAddressProvince: '',
                presentAddressCity: '',
                presentAddressBrgy: '',
                presentAddressStreet: ''
            }, () => {
                
            });
        }
        if(prop === "presentAddressProvinceObj"){
            this.setState({
                presentAddressProvinceObj: value,
                presentAddressCityObj: null,
                presentAddressCity: '',
                presentAddressBrgy: '',
                presentAddressStreet: ''
            }, () => {
                
            });
        }
        if(prop === "presentAddressCityObj"){
            this.setState({
                presentAddressCityObj: value,
                presentAddressBrgyObj: null,
                presentAddressBrgy: '',
                presentAddressStreet: ''
            }, () => {
                
            });
        }
        if(prop === "presentAddressBrgyObj"){
            this.setState({
                presentAddressBrgyObj: value,
                presentAddressStreet: ''
            }, () => {
                
            });
        }

        if(prop === "permanentAddressRegionObj"){
            this.setState({
                permanentAddressRegionObj: value,
                permanentAddressProvinceObj: null,
                permanentAddressProvince: '',
                permanentAddressCityObj: null,
                permanentAddressCity: '',
                permanentAddressBrgyObj: null,
                permanentAddressBrgy: '',
                permanentAddressStreet: ''
            }, () => {
                
            });
        }
        if(prop === "permanentAddressProvinceObj"){
            this.setState({
                permanentAddressProvinceObj: value,
                permanentAddressCityObj: null,
                permanentAddressCity: '',
                permanentAddressBrgyObj: null,
                permanentAddressBrgy: '',
                permanentAddressStreet: ''
            }, () => {
                
            });
        }
        if(prop === "permanentAddressCityObj"){
            this.setState({
                permanentAddressCityObj: value,
                permanentAddressBrgyObj: null,
                permanentAddressBrgy: '',
                permanentAddressStreet: ''
            }, () => {
                
            });
        }
        if(prop === "presentAddressBrgyObj"){
            this.setState({
                presentAddressBrgyObj: value,
                presentAddressStreet: ''
            }, () => {
                
            });
        }

        if(prop === "presentAddressRegion"){
            this.setState({
                presentAddressRegion: value,
                presentAddressProvinceObj: null,
                presentAddressProvince: '',
                presentAddressCityObj: null,
                presentAddressCity: '',
                presentAddressBrgyObj: null,
                presentAddressBrgy: '',
                presentAddressStreet: ''
            }, () => {
                
            });
        }else if(prop === "presentAddressProvince"){
            this.setState({
                presentAddressProvince: value,
                presentAddressCityObj: null,
                presentAddressCity: '',
                presentAddressBrgyObj: null,
                presentAddressBrgy: '',
                presentAddressStreet: ''
            }, () => {
                
            });
        }else if(prop === "presentAddressCity"){
            this.setState({
                presentAddressCity: value,
                presentAddressBrgyObj: null,
                presentAddressBrgy: '',
                presentAddressStreet: ''
            }, () => {
                
            });
        }else if(prop === "presentAddressBrgy"){
            this.setState({
                presentAddressBrgy: value,
                presentAddressStreet: ''
            }, () => {
                
            });
        }else if(prop === "permanentAddressRegion"){
            this.setState({
                permanentAddressRegion: value,
                permanentAddressProvinceObj: null,
                permanentAddressProvince: '',
                permanentAddressCityObj: null,
                permanentAddressCity: '',
                permanentAddressBrgyObj: null,
                permanentAddressBrgy: '',
                permanentAddressStreet: ''
            }, () => {
                
            });
        }else if(prop === "permanentAddressProvince"){
            this.setState({
                permanentAddressProvince: value,
                permanentAddressCityObj: null,
                permanentAddressCity: '',
                permanentAddressBrgyObj: null,
                permanentAddressBrgy: '',
                permanentAddressStreet: ''
            }, () => {
                
            });
        }else if(prop === "permanentAddressCity"){
            this.setState({
                permanentAddressCity: value,
                permanentAddressBrgyObj: null,
                permanentAddressBrgy: '',
                permanentAddressStreet: ''
            }, () => {
                
            });
        }else if(prop === "permanentAddressBrgy"){
            this.setState({
                permanentAddressBrgy: value,
                permanentAddressStreet: ''
            }, () => {
                
            });
       
        }else if(prop === "fParentType"){
            if(value === 'Living'){ //living
                const validatorsToAdd = [
                    'fLastNameIsValid',
                    'fFirstNameIsValid',
                    'fOccupationIsValid',
                    'fAnnualIncomeIsValid',
                ]
                this.updateRequiredValidators('add', validatorsToAdd)
                
            }else if(value === 'Deceased'){ //deceased
                const validatorsToExclude = [
                    'fOccupationIsValid',
                    'fAnnualIncomeIsValid',
                ]
                const validatorsToAdd = [
                    'fLastNameIsValid',
                    'fFirstNameIsValid',
                ]
    
                this.setState({
                    fOccupation: '',
                    fAnnualIncome: '',
                    fAnnualIncomeObj: null,
                    fAnnualIncomeTxt: '',
                }, () => {
                    this.updateRequiredValidators('remove', validatorsToExclude)
                    this.updateRequiredValidators('add', validatorsToAdd)
                })
            }else if(value === 'Unknown'){ //unknown
                const validatorsToExclude = [
                    'fLastNameIsValid',
                    'fFirstNameIsValid',
                    'fOccupationIsValid',
                    'fAnnualIncomeIsValid',
                ]
                this.setState({
                    fLastName: '',
                    fFirstName: '',
                    fMiddleName: '',
                    fSuffix: '',
                    fOccupation: '',
                    fAnnualIncome: '',
                    fAnnualIncomeObj: null,
                    fAnnualIncomeTxt: '',
                }, () => {
                    this.updateRequiredValidators('remove', validatorsToExclude)
                })
            }
            this.setState({
                fParentType: value
            });
        }else if(prop === "mParentType"){
            if(value === 'Living'){
                const validatorsToAdd = [
                    'mLastNameIsValid',
                    'mFirstNameIsValid',
                    'mOccupationIsValid',
                    'mAnnualIncomeIsValid',
                ];
                this.updateRequiredValidators('add', validatorsToAdd);
            }else if(value === 'Deceased'){ //deceased
                const validatorsToExclude = [
                    'mOccupationIsValid',
                    'mAnnualIncomeIsValid',
                ]
                const validatorsToAdd = [
                    'mLastNameIsValid',
                    'mFirstNameIsValid',
                ]
                this.setState({
                    mOccupation: '',
                    mAnnualIncome: '',
                    mAnnualIncomeObj: null,
                    mAnnualIncomeTxt: '',
                }, () => {
                    this.updateRequiredValidators('remove', validatorsToExclude)
                    this.updateRequiredValidators('add', validatorsToAdd)
                })
                
            }else if(value === 'Unknown'){ //unknown
                const validatorsToExclude = [
                    'mLastNameIsValid',
                    'mFirstNameIsValid',
                    'mOccupationIsValid',
                    'mAnnualIncomeIsValid',
                ]
                this.setState({
                    mLastName: '',
                    mFirstName: '',
                    mMiddleName: '',
                    mOccupation: '',
                    mAnnualIncome: '',
                    mAnnualIncomeObj: null,
                    mAnnualIncomeTxt: ''
                }, () => {
                    this.updateRequiredValidators('remove', validatorsToExclude)
                })
            }
            this.setState({
                mParentType: value
            });
        }else{
            this.setState({
                [prop]: value,
            }, () => {
                
                if(prop === "isMemberOfIP"){
                    if(value === '1'){
                        const validatorsToAdd = [
                            "file_IPmembershipCertIsValid",
                            "tribeIsValid"
                        ]
                        this.updateRequiredValidators('add', validatorsToAdd)
                    }else{
                        const validatorsToExclude = [
                            "file_IPmembershipCertIsValid",
                            "tribeIsValid"
                        ]
                        this.updateRequiredValidators('remove', validatorsToExclude)
                    }
                }

                if(prop === "hasSpecialNeeds"){
                    if(value === '1'){
                        const validatorsToAdd = [
                            "specialNeedIsValid"
                        ]
                        this.updateRequiredValidators('add', validatorsToAdd)
                    }else{
                        const validatorsToExclude = [
                            "specialNeedIsValid"
                        ]
                        this.updateRequiredValidators('remove', validatorsToExclude)
                    }
                }

                if(prop === "hasPwdId"){
                    if(value === '1' && this.state.hasSpecialNeeds === '1'){
                        const validatorsToAdd = [
                            "pwdIdIsValid"
                        ]
                        this.updateRequiredValidators('add', validatorsToAdd)
                    }else{
                        const validatorsToExclude = [
                            "pwdIdIsValid"
                        ]
                        this.updateRequiredValidators('remove', validatorsToExclude)
                    }
                }


                if(prop === "track"){
                    this.setState({trackTxt:txtValue});
                }
                if(prop === "strand"){
                    this.setState({strandTxt:txtValue});
                }
                if(prop === "citizenship"){
                    this.setState({citizenshipTxt:txtValue});
                }
                if(prop === "civilStatus"){
                    this.setState({civilStatusTxt:txtValue});
                }
                if(prop === "religion"){
                    this.setState({religionTxt:txtValue});
                }
                if(prop === "fAnnualIncome"){
                    this.setState({
                        fAnnualIncome: value,

                        fAnnualIncomeTxt:txtValue
                    })
                }
                if(prop === "mAnnualIncome"){
                    this.setState({mAnnualIncomeTxt:txtValue})
                }
                if(prop === "campus1"){
                    this.setState({
                        campus1Txt:txtValue,
                        course1Obj: null,
                        course1: '',
                        course1InputVal: '',
                        major1Obj: null,
                        major1: '',
                        major1InputVal: ''
                    })
                }
                if(prop === "campus2"){
                    this.setState({
                        campus2Txt:txtValue,
                        course2Obj: null,
                        course2: '',
                        course2InputVal: '',
                        major2Obj: null,
                        major2: '',
                        major2InputVal: ''
                    })
                }
                if(prop === "campus3"){
                    this.setState({
                        campus3Txt:txtValue,
                        course3Obj: null,
                        course3: '',
                        course3InputVal: '',
                        major3Obj: null,
                        major3: '',
                        major3InputVal: ''
                    })
                }
                if(prop === "major1"){
                    this.setState({major1Txt:txtValue})
                }
                if(prop === "major2"){
                    this.setState({major2Txt:txtValue})
                }
                if(prop === "major3"){
                    this.setState({major3Txt:txtValue})
                }
                if(prop === "lastName" || prop === "firstName" || prop === "middleName"){
                    this.checkDuplicateApplicant();
                }
                if(prop === "course1"){
                    this.setState({
                        course1: value,
                        major1: '',
                        course1Txt: txtValue
                    },() => {
                        console.log(`course1: ${this.state.course1}`);
                        const hasMajor =  this.state.courseMajorOptions.filter( (course) => {
                            return course.ProgID == this.state.course1 && course.MajorID !== 0;
                        });
                        if(hasMajor.length > 0){
                            const validatorsToAdd = [
                                'major1IsValid'
                            ]
                            this.updateRequiredValidators('add', validatorsToAdd)
                            this.changeHandler('major1', '');
                            this.setState({
                                isMajor1Required: true,
                                major1InputVal: ''
                            })
                            
                        }else{
                            const validatorsToExclude = [
                                'major1IsValid'
                            ]
                            this.updateRequiredValidators('remove', validatorsToExclude)
                            this.setState({
                                isMajor1Required: false,
                                major1InputVal: 'None',
                                major1IsValid: {...this.state.major1IsValid, status: true, message: ''}
                            })
                        }
                    })
                    
                }else if(prop === "course2"){
                    this.setState({
                        course2: value,
                        major2: '',
                        course2Txt: txtValue
                    },() => {
                        const hasMajor =  this.state.courseMajorOptions.filter( (course) => {
                            return course.ProgID == this.state.course2 && course.MajorID !== 0;
                        });
                        if(hasMajor.length > 0){
                            const validatorsToAdd = [
                                'major2IsValid'
                            ]
                            this.updateRequiredValidators('add', validatorsToAdd);
                            this.changeHandler('major2', '');
                            this.setState({
                                isMajor2Required: true,
                                major2InputVal: ''
                            })
                        }else{
                            const validatorsToExclude = [
                                'major2IsValid'
                            ]
                            this.updateRequiredValidators('remove', validatorsToExclude)
                            this.setState({
                                isMajor2Required: false,
                                major2InputVal: 'None',
                                major2IsValid: {...this.state.major2IsValid, status: true, message: ''}
                            })
                        }
                    })  
                    
                }else if(prop === "course3"){
                    this.setState({
                        course3: value,
                        major3: '',
                        course3Txt: txtValue
                    },() => {
                        const hasMajor =  this.state.courseMajorOptions.filter( (course) => {
                            return course.ProgID == this.state.course3 && course.MajorID !== 0;
                        });
                        if(hasMajor.length > 0){
                            const validatorsToAdd = [
                                'major3IsValid'
                            ]
                            this.updateRequiredValidators('add', validatorsToAdd)
                            this.changeHandler('major3', '');
                            this.setState({
                                isMajor3Required: true,
                                major3InputVal: ''
                            })
                        }else{
                            const validatorsToExclude = [
                                'major3IsValid'
                            ]
                            this.updateRequiredValidators('remove', validatorsToExclude)
                            this.setState({
                                isMajor3Required: false,
                                major3InputVal: 'None',
                                major3IsValid: {...this.state.major3IsValid, status: true, message: ''}
                            })
                        }
                    })
                    
                }
                if(prop === "gadgetPcChecked" && value){
                    this.setState({
                        gadgetNone: false
                    })
                }
                if(prop === "gadgetCpChecked" && value){
                    this.setState({
                        gadgetNone: false
                    })
                }
                if(prop === "gadgetNone" && value){
                    this.setState({
                        gadgetPcChecked: false,
                        gadgetCpChecked: false
                    })
                }
            });
        }
  
        this.handleValidation(prop,value); 
      
    }

    updateRequiredValidators = (action,validatorUpdates) => {
        let newRequiredValidators = [];
        switch (action) {
            case 'add':
                for(const validatorUpdate of validatorUpdates){
                    const existingValidator = this.requiredValidators.filter( (key) => {
                        return key === validatorUpdate;
                    });
                    // const existingValidator = [];
                    // for(const requiredValidator of this.requiredValidators){
                    //     if(requiredValidator === validatorUpdate){
                    //         console.log(`${requiredValidator} = ${validatorUpdate}`);
                    //         existingValidator = [...existingValidator, requiredValidator];
                    //     }
                    // }
                    console.log(`existingValidator: ${JSON.stringify(existingValidator)}`);
                    if(existingValidator.length === 0){
                        newRequiredValidators = [...this.requiredValidators, 
                            validatorUpdate
                        ];
                        this.requiredValidators = newRequiredValidators;
                    }
                }
                // newRequiredValidators = [...this.requiredValidators, 
                //     ...validatorUpdates
                // ];
                // this.requiredValidators = newRequiredValidators;
                break;
            case 'remove':
                newRequiredValidators = this.requiredValidators.filter( (validator) => {
                    return !validatorUpdates.includes(validator)
                });
                this.requiredValidators = newRequiredValidators;
                break;
            default:

        }
    }

    handleFatherIsLivingType = e => {
        
        this.setState({fParentType: e.target.value }, () => {
            
        });
        
    }

    handleDateChange = (date) => {
        try{
            this.setState({birthdate: format(date, 'MM/dd/yyyy')});
        }catch(err){
            
        }
        
    };

    handleCancelSubmit = () => {
        this.setState({hasConfirmedSubmission: false});
    }
    
    handleCloseSubmissionDialog = () => {
        this.setState({
            isSubmissionSuccessful: false,
            showSystemUsabilityTestDialog: true
        });
        // window.location.reload();
    }

    handleCloseSystemUsabilityDialog = () => {
        this.setState({
            showSystemUsabilityTestDialog: false
        }, () => {
            window.location.reload();
        })
    }

    handleCloseFileSelection = () => {
        this.setState({fileIsSelected: false});
    }
    
    handleChoosePhotoClicked = () => {
        this.setState({choosePhotoClicked: true});
        // this.setState({showImageCropper: true});
    }

    handleChoosePhotoClosed = () => {
        this.setState({showImageCropper: false});
    }

    handleChoosePassportPhotoClicked = () => {
        this.setState({choosePassportPhotoClicked: true});
    }

    handleChoosePassportPhotoClosed = () => {
        this.setState({choosePassportPhotoClicked: false});
    }

    handleChooseIDPhotoClicked = () => {
        this.setState({chooseIDPhotoClicked: true});
    }

    handleChooseIDPhotoClosed = () => {
        this.setState({chooseIDPhotoClicked: false});
    }

    initialSubmitHandler = (e) => {
        // e.preventDefault();
        // this.handleReviewFormClose();
        this.setState({hasConfirmedSubmission: true});
    }

    handleReviewFormClick = () => {
        // socket.emit('admission:currentlyReviewingApplication');
        // this.setState({
        //     hasClickedReviewForm: true,
        //     hasSubmittedInvalidForm: false
        // });
        
        if(this.state.isDuplicateApplicant){
            this.setState({
                showDuplicateApplicantNotice: true,
                hasClickedReviewForm: false,
            });
        }else{
            const isFormValid = this.isFormValid();
            
            if(isFormValid){
                socket.emit('admission:currentlyReviewingApplication',{clientID: this.state.clientID, clientDevice: this.state.clientDevice});
                this.setState({
                    hasClickedReviewForm: true,
                    hasSubmittedInvalidForm: false
                });
                
            }else{
                this.setState({
                    hasSubmittedInvalidForm: true,
                    hasClickedReviewForm: false,
                });
            }
        }
    }

    // Ensure that all required fields are filled out prior to form submission
    isFormValid = () => {
        let emptyFields = [];

        Object.keys(this.state).forEach( (key) => {
            for(const field of this.requiredValidators){
                if(key === field){
                    if(this.state[key].status === false){
                        const tempEmpty = [...emptyFields, this.state[key].propName]
                        emptyFields = tempEmpty;
                        // emptyFields.push(this.state[key].propName);
                    }
                }
            }
        });

        this.setState({emptyFields: [...emptyFields]})

        if(emptyFields.length > 0){
            return false;
        }else{
            return true;
        }

    }

    // HANDLE APPLICATION FORM SUBMISSION
    submitHandler = e => {
        e.preventDefault();
        
        // For testing submit dialog. DO NOT FORGET TO COMMENT OUT IN PRODUCTION
        // this.setState({
        //     isSubmissionSuccessful: true,
        //     hasConfirmedSubmission: false,
        //     responseAppNo: '2021-000000019',
        //     responsePassCode: '69xyz-12',
        //     submitting: false
        // })

        socket.emit('admission:currentlySubmittingApplication',{clientID: this.state.clientID, clientDevice: this.state.clientDevice});
        this.setState({submitting: true}, () => {
            const formData = new FormData();
            Object.keys(this.state).forEach( (key) => {
                for(const field of this.requiredFields){
                    if(key === field){
                        formData.append(key, this.state[key]);
                    }
                }
            });

            axios.post(`${API_URL}/admission/saveAdmissionInfo`, formData)
                .then(response => { 
                    console.log(`responseStatus: ${JSON.stringify(response.data)}`);
                    if(response.data.status){
                        socket.emit('admission:successfulApplicationSubmission',{clientID: this.state.clientID, clientDevice: this.state.clientDevice});
                        this.setState({
                            isSubmissionSuccessful: true,
                            hasConfirmedSubmission: false,
                            responseAppNo: response.data.appNo,
                            responsePassCode: response.data.passCode,
                            submitting: false
                        });
                    }else{
                        this.setState({
                            isSubmissionSuccessful: false,
                            hasConfirmedSubmission: false,
                            submitting: false
                        })
                        alert(response.data.msg);
                    }
                })
                .catch(error => {
                    this.setState({
                        hasConfirmedSubmission: false,
                        submitting: false
                    })
                    alert(`Something went wrong. The system can't process your submission. This may be a system/network related error or an issue with your uploaded file(s). Please check your submitted data/files and try again.`);
                    // window.location.reload();
                });
        });
       
    }

    handleReviewFormClose = () => {
        this.setState({hasClickedReviewForm: false});
        socket.emit('admission:currentlyFillingOutForm',{clientID: this.state.clientID});
    }

    handleCloseInvalidSubmissionDialog = () => {
        this.setState({hasSubmittedInvalidForm: false});
    }

    handleGradeSubjectInputClick = (subject) => {
        this.setState({
            hasClickedGradeSubjectInput: true,
            selectedGradeSubjectInput: subject
        });
    }

    handleGradeSubjectInputClosed = () => {
        this.setState({hasClickedGradeSubjectInput: false});
    }

    handleGradeAverageInputClick = () => {
        this.setState({hasClickedGradeAverageInput: true});
    }

    handleGradeAverageInputClosed = () => {
        this.setState({hasClickedGradeAverageInput: false});
    }

    isNameValid = (str) => {
        // let result = /^\s*([A-Za-z]{1,}([\.,] |[-']| ))+[A-Za-z]+\.?\s*$/.test(str);
        let result = /^([A-Za-z\u00C0-\u02AB\s'-\.])+$/.test(str);
        // let result = /^[a-z\u00C0-\u02AB'´`]+\.?\s([a-z\u00C0-\u02AB'´`]+\.?\s?)+$/.test(str);
        // let result = /^[A-Za-z\u00C0-\u02AB'´`]+$/.test(str);
        console.log(`regExResult: ${result}`);
        return result;
    }
    isAddressValid = (str) => {
        // let result = /^\s*([A-Za-z]{1,}([\.,] |[-']| ))+[A-Za-z]+\.?\s*$/.test(str);
        let result = /^([A-Za-z0-9\u00C0-\u02AB\s'-\.])+$/.test(str);
        // let result = /^[a-z\u00C0-\u02AB'´`]+\.?\s([a-z\u00C0-\u02AB'´`]+\.?\s?)+$/.test(str);
        // let result = /^[A-Za-z\u00C0-\u02AB'´`]+$/.test(str);
        console.log(`regExResult: ${result}`);
        return result;
    }

    isContactNumberValid = (str) => {
        let result = /^([0-9]{11})$/.test(str);
        console.log(`contactRegExResult: ${result}`);
        return result;
    }

    isEmailValid = (str) => {
        let result = /\S+@\S+\.\S+/.test(str);
        return result;
    }

    // Handle individual field validation
    handleValidation = (prop,value) => {
        if(prop === "lastName"){
            if(value === ""){
                let lastNameIsValid = {...this.state.lastNameIsValid};
                lastNameIsValid.status = false;
                lastNameIsValid.focused = true;
                lastNameIsValid.message = 'This is a required field';
                this.setState({lastNameIsValid:lastNameIsValid});
            }else if(!this.isNameValid(value)){
                let lastNameIsValid = {...this.state.lastNameIsValid};
                lastNameIsValid.status = false;
                lastNameIsValid.focused = true;
                lastNameIsValid.message = 'Please enter a valid name';
                this.setState({lastNameIsValid:lastNameIsValid});
            }else{
                this.checkDuplicateApplicant();
                let lastNameIsValid = {...this.state.lastName};
                lastNameIsValid.status = true;
                lastNameIsValid.focused = true;
                lastNameIsValid.message = '';
                this.setState({lastNameIsValid:lastNameIsValid});
            }  
        }else if(prop === "firstName"){
            if(value === ""){
                let firstNameIsValid = {...this.state.firstNameIsValid};
                firstNameIsValid.status = false;
                firstNameIsValid.focused = true;
                firstNameIsValid.message = 'This is a required field';
                this.setState({firstNameIsValid:firstNameIsValid});
            }else if(!this.isNameValid(value)){
                let firstNameIsValid = {...this.state.firstNameIsValid};
                firstNameIsValid.status = false;
                firstNameIsValid.focused = true;
                firstNameIsValid.message = 'Please enter a valid name';
                this.setState({firstNameIsValid:firstNameIsValid});
            }else{
                let firstNameIsValid = {...this.state.firstName};
                firstNameIsValid.status = true;
                firstNameIsValid.focused = true;
                firstNameIsValid.message = '';
                this.setState({firstNameIsValid:firstNameIsValid});
            }   
        }else if (prop === "middleName"){
            if(value === ""){
                let middleNameIsValid = {...this.state.middleNameIsValid};
                middleNameIsValid.status = true;
                middleNameIsValid.focused = true;
                middleNameIsValid.message = '';
                this.setState({middleNameIsValid:middleNameIsValid});
            }
            else if(!this.isNameValid(value)){
                let middleNameIsValid = {...this.state.middleNameIsValid};
                middleNameIsValid.status = false;
                middleNameIsValid.focused = true;
                middleNameIsValid.message = 'Please enter a valid name';
                this.setState({middleNameIsValid:middleNameIsValid});
            }else{
                let middleNameIsValid = {...this.state.middleNameIsValid};
                middleNameIsValid.status = true;
                middleNameIsValid.focused = true;
                middleNameIsValid.message = '';
                this.setState({middleNameIsValid:middleNameIsValid});
            }
        }else if(prop === "gender"){
            if(value === ""){
                let genderIsValid = {...this.state.genderIsValid};
                genderIsValid.status = false;
                genderIsValid.focused = true;
                genderIsValid.message = 'This is a required field';
                this.setState({genderIsValid:genderIsValid});
            }else{
                let genderIsValid = {...this.state.gender};
                genderIsValid.status = true;
                genderIsValid.focused = true;
                genderIsValid.message = '';
                this.setState({genderIsValid:genderIsValid});
            }   
        }else if(prop === "birthplace"){
            if(value === ""){
                let birthplaceIsValid = {...this.state.birthplaceIsValid};
                birthplaceIsValid.status = false;
                birthplaceIsValid.focused = true;
                birthplaceIsValid.message = 'This is a required field';
                this.setState({birthplaceIsValid:birthplaceIsValid});
            }else{
                let birthplaceIsValid = {...this.state.birthplace};
                birthplaceIsValid.status = true;
                birthplaceIsValid.focused = true;
                birthplaceIsValid.message = '';
                this.setState({birthplaceIsValid:birthplaceIsValid});
            }   
        }else if(prop === "civilStatus"){
            if(value === ""){
                let civilStatusIsValid = {...this.state.civilStatusIsValid};
                civilStatusIsValid.status = false;
                civilStatusIsValid.focused = true;
                civilStatusIsValid.message = 'This is a required field';
                this.setState({civilStatusIsValid:civilStatusIsValid});
            }else{
                let civilStatusIsValid = {...this.state.civilStatusIsValid};
                civilStatusIsValid.status = true;
                civilStatusIsValid.focused = true;
                civilStatusIsValid.message = '';
                this.setState({civilStatusIsValid:civilStatusIsValid});
            }   
        }else if(prop === "citizenship"){
            if(value === ""){
                let citizenshipIsValid = {...this.state.citizenshipIsValid};
                citizenshipIsValid.status = false;
                citizenshipIsValid.focused = true;
                citizenshipIsValid.message = 'This is a required field';
                this.setState({citizenshipIsValid:citizenshipIsValid});
            }else{
                let citizenshipIsValid = {...this.state.citizenship};
                citizenshipIsValid.status = true;
                citizenshipIsValid.focused = true;
                citizenshipIsValid.message = '';
                this.setState({citizenshipIsValid:citizenshipIsValid});
            }   
        }else if(prop === "religion"){
            if(value === ""){
                let religionIsValid = {...this.state.religionIsValid};
                religionIsValid.status = false;
                religionIsValid.focused = true;
                religionIsValid.message = 'This is a required field';
                this.setState({religionIsValid:religionIsValid});
            }else{
                let religionIsValid = {...this.state.religion};
                religionIsValid.status = true;
                religionIsValid.focused = true;
                religionIsValid.message = '';
                this.setState({religionIsValid:religionIsValid});
            }   
        }else if(prop === "email"){
            if(value === ""){
                let emailIsValid = {...this.state.emailIsValid};
                emailIsValid.status = false;
                emailIsValid.focused = true;
                emailIsValid.message = 'This is a required field';
                this.setState({emailIsValid:emailIsValid});
            }else if(!this.isEmailValid(value)){
                let emailIsValid = {...this.state.emailIsValid};
                emailIsValid.status = false;
                emailIsValid.focused = true;
                emailIsValid.message = 'Please enter a valid email address';
                this.setState({emailIsValid:emailIsValid});
            }else{
                let emailIsValid = {...this.state.emailIsValid};
                emailIsValid.status = true;
                emailIsValid.focused = true;
                emailIsValid.message = '';
                this.setState({emailIsValid:emailIsValid});
            }   
        }else if(prop === "fParentType"){
            if(value === ""){
                let fParentTypeIsValid = {...this.state.fParentTypeIsValid};
                fParentTypeIsValid.status = false;
                fParentTypeIsValid.focused = true;
                fParentTypeIsValid.message = 'This is a required field';
                this.setState({fParentTypeIsValid:fParentTypeIsValid});
            }else if(value === "Living"){
                let fParentTypeIsValid = {...this.state.fParentTypeIsValid};
                fParentTypeIsValid.status = true;
                fParentTypeIsValid.focused = true;
                fParentTypeIsValid.message = '';

                let fFirstNameIsValid = {...this.state.fFirstNameIsValid};
                fFirstNameIsValid.status = this.state.fFirstName === '' ? false : true;
                fFirstNameIsValid.focused = true;
                fFirstNameIsValid.message = '';
                
                let fLastNameIsValid = {...this.state.fLastNameIsValid};
                fLastNameIsValid.status = this.state.fLastName === '' ? false : true;
                fLastNameIsValid.focused = true;
                fLastNameIsValid.message = '';
               
                let fOccupationIsValid = {...this.state.fOccupationIsValid};
                fOccupationIsValid.status = this.state.fOccupation === '' ? false : true;
                fOccupationIsValid.focused = true;
                fOccupationIsValid.message = '';
                
                let fAnnualIncomeIsValid = {...this.state.fAnnualIncomeIsValid};
                fAnnualIncomeIsValid.status = this.state.fAnnualIncome === '' ? false : true;
                fAnnualIncomeIsValid.focused = true;
                fAnnualIncomeIsValid.message = '';

                this.setState({
                    fParentTypeIsValid:fParentTypeIsValid,
                    fOccupationIsValid:fOccupationIsValid,
                    fAnnualIncomeIsValid:fAnnualIncomeIsValid,
                    fFirstNameIsValid:fFirstNameIsValid,
                    fLastNameIsValid:fLastNameIsValid
                });
            }else if(value === "Deceased"){
                let fParentTypeIsValid = {...this.state.fParentTypeIsValid};
                fParentTypeIsValid.status = true;
                fParentTypeIsValid.focused = true;
                fParentTypeIsValid.message = '';

                let fFirstNameIsValid = {...this.state.fFirstNameIsValid};
                fFirstNameIsValid.status = this.state.fFirstName === '' ? false : true;
                fFirstNameIsValid.focused = true;
                fFirstNameIsValid.message = '';
                
                let fLastNameIsValid = {...this.state.fLastNameIsValid};
                fLastNameIsValid.status = this.state.fLastName === '' ? false : true;
                fLastNameIsValid.focused = true;
                fLastNameIsValid.message = '';

                let fOccupationIsValid = {...this.state.fOccupationIsValid};
                fOccupationIsValid.status = true;
                fOccupationIsValid.focused = true;
                fOccupationIsValid.message = '';
                
                let fAnnualIncomeIsValid = {...this.state.fAnnualIncomeIsValid};
                fAnnualIncomeIsValid.status = true;
                fAnnualIncomeIsValid.focused = true;
                fAnnualIncomeIsValid.message = '';

                this.setState({
                    fParentTypeIsValid:fParentTypeIsValid,
                    fOccupationIsValid:fOccupationIsValid,
                    fAnnualIncomeIsValid:fAnnualIncomeIsValid,
                    fFirstNameIsValid:fFirstNameIsValid,
                    fLastNameIsValid:fLastNameIsValid
                });
            }else if(value === "Unknown"){
                let fParentTypeIsValid = {...this.state.fParentTypeIsValid};
                fParentTypeIsValid.status = true;
                fParentTypeIsValid.focused = true;
                fParentTypeIsValid.message = '';

                let fFirstNameIsValid = {...this.state.fFirstNameIsValid};
                fFirstNameIsValid.status = true;
                fFirstNameIsValid.focused = true;
                fFirstNameIsValid.message = '';
                
                let fLastNameIsValid = {...this.state.fLastNameIsValid};
                fLastNameIsValid.status = true;
                fLastNameIsValid.focused = true;
                fLastNameIsValid.message = '';
               
                let fOccupationIsValid = {...this.state.fOccupationIsValid};
                fOccupationIsValid.status = true;
                fOccupationIsValid.focused = true;
                fOccupationIsValid.message = '';
                
                let fAnnualIncomeIsValid = {...this.state.fAnnualIncomeIsValid};
                fAnnualIncomeIsValid.status = true;
                fAnnualIncomeIsValid.focused = true;
                fAnnualIncomeIsValid.message = '';

                this.setState({
                    fParentTypeIsValid:fParentTypeIsValid,
                    fOccupationIsValid:fOccupationIsValid,
                    fAnnualIncomeIsValid:fAnnualIncomeIsValid,
                    fFirstNameIsValid:fFirstNameIsValid,
                    fLastNameIsValid:fLastNameIsValid
                });
            }   
        }else if(prop === "mParentType"){
            if(value === ""){
                let mParentTypeIsValid = {...this.state.mParentTypeIsValid};
                mParentTypeIsValid.status = false;
                mParentTypeIsValid.focused = true;
                mParentTypeIsValid.message = 'This is a required field';
                this.setState({mParentTypeIsValid:mParentTypeIsValid});
            }else if(value === "Living"){
                let mParentTypeIsValid = {...this.state.mParentTypeIsValid};
                mParentTypeIsValid.status = true;
                mParentTypeIsValid.focused = true;
                mParentTypeIsValid.message = '';

                let mFirstNameIsValid = {...this.state.mFirstNameIsValid};
                mFirstNameIsValid.status = this.state.mFirstName === '' ? false : true;
                mFirstNameIsValid.focused = true;
                mFirstNameIsValid.message = '';
                
                let mLastNameIsValid = {...this.state.mLastNameIsValid};
                mLastNameIsValid.status = this.state.mLastName === '' ? false : true;
                mLastNameIsValid.focused = true;
                mLastNameIsValid.message = '';
               
                let mOccupationIsValid = {...this.state.mOccupationIsValid};
                mOccupationIsValid.status = this.state.mOccupation === '' ? false : true;
                mOccupationIsValid.focused = true;
                mOccupationIsValid.message = '';
                
                let mAnnualIncomeIsValid = {...this.state.mAnnualIncomeIsValid};
                mAnnualIncomeIsValid.status = this.state.mAnnualIncome === '' ? false : true;
                mAnnualIncomeIsValid.focused = true;
                mAnnualIncomeIsValid.message = '';

                this.setState({
                    mParentTypeIsValid:mParentTypeIsValid,
                    mOccupationIsValid:mOccupationIsValid,
                    mAnnualIncomeIsValid:mAnnualIncomeIsValid,
                    mFirstNameIsValid:mFirstNameIsValid,
                    mLastNameIsValid:mLastNameIsValid
                });
            }else if(value === "Deceased"){
                let mParentTypeIsValid = {...this.state.mParentTypeIsValid};
                mParentTypeIsValid.status = true;
                mParentTypeIsValid.focused = true;
                mParentTypeIsValid.message = '';

                let mFirstNameIsValid = {...this.state.mFirstNameIsValid};
                mFirstNameIsValid.status = this.state.mFirstName === '' ? false : true;
                mFirstNameIsValid.focused = true;
                mFirstNameIsValid.message = '';
                
                let mLastNameIsValid = {...this.state.mLastNameIsValid};
                mLastNameIsValid.status = this.state.mLastName === '' ? false : true;
                mLastNameIsValid.focused = true;
                mLastNameIsValid.message = '';
               
                let mOccupationIsValid = {...this.state.mOccupationIsValid};
                mOccupationIsValid.status = true;
                mOccupationIsValid.focused = true;
                mOccupationIsValid.message = '';
                
                let mAnnualIncomeIsValid = {...this.state.mAnnualIncomeIsValid};
                mAnnualIncomeIsValid.status = true;
                mAnnualIncomeIsValid.focused = true;
                mAnnualIncomeIsValid.message = '';

                this.setState({
                    mParentTypeIsValid:mParentTypeIsValid,
                    mOccupationIsValid:mOccupationIsValid,
                    mAnnualIncomeIsValid:mAnnualIncomeIsValid,
                    mFirstNameIsValid:mFirstNameIsValid,
                    mLastNameIsValid:mLastNameIsValid
                });
            }else if(value === "Unknown"){
                let mParentTypeIsValid = {...this.state.mParentTypeIsValid};
                mParentTypeIsValid.status = true;
                mParentTypeIsValid.focused = true;
                mParentTypeIsValid.message = '';

                let mFirstNameIsValid = {...this.state.mFirstNameIsValid};
                mFirstNameIsValid.status = true;
                mFirstNameIsValid.focused = true;
                mFirstNameIsValid.message = '';
                
                let mLastNameIsValid = {...this.state.mLastNameIsValid};
                mLastNameIsValid.status = true;
                mLastNameIsValid.focused = true;
                mLastNameIsValid.message = '';
               
                let mOccupationIsValid = {...this.state.mOccupationIsValid};
                mOccupationIsValid.status = true;
                mOccupationIsValid.focused = true;
                mOccupationIsValid.message = '';
                
                let mAnnualIncomeIsValid = {...this.state.mAnnualIncomeIsValid};
                mAnnualIncomeIsValid.status = true;
                mAnnualIncomeIsValid.focused = true;
                mAnnualIncomeIsValid.message = '';
  
                this.setState({
                    mParentTypeIsValid:mParentTypeIsValid,
                    mOccupationIsValid:mOccupationIsValid,
                    mAnnualIncomeIsValid:mAnnualIncomeIsValid,
                    mFirstNameIsValid:mFirstNameIsValid,
                    mLastNameIsValid:mLastNameIsValid

                });
            }   
        }else if(prop === "mFirstName"){
            if(value === ""){
                let mFirstNameIsValid = {...this.state.mFirstNameIsValid};
                mFirstNameIsValid.status = false;
                mFirstNameIsValid.focused = true;
                mFirstNameIsValid.message = 'This is a required field';
                this.setState({mFirstNameIsValid:mFirstNameIsValid});
            }else if(!this.isNameValid(value)){
                let mFirstNameIsValid = {...this.state.mFirstNameIsValid};
                mFirstNameIsValid.status = false;
                mFirstNameIsValid.focused = true;
                mFirstNameIsValid.message = 'Please enter a valid name';
                this.setState({mFirstNameIsValid:mFirstNameIsValid});
            }else{
                let mFirstNameIsValid = {...this.state.mFirstNameIsValid};
                mFirstNameIsValid.status = true;
                mFirstNameIsValid.focused = true;
                mFirstNameIsValid.message = '';
                this.setState({mFirstNameIsValid:mFirstNameIsValid});
            }   
        }else if(prop === "mLastName"){
            if(value === ""){
                let mLastNameIsValid = {...this.state.mLastNameIsValid};
                mLastNameIsValid.status = false;
                mLastNameIsValid.focused = true;
                mLastNameIsValid.message = 'This is a required field';
                this.setState({mLastNameIsValid:mLastNameIsValid});
            }else if(!this.isNameValid(value)){
                let mLastNameIsValid = {...this.state.mLastNameIsValid};
                mLastNameIsValid.status = false;
                mLastNameIsValid.focused = true;
                mLastNameIsValid.message = 'Please enter a valid name';
                this.setState({mLastNameIsValid:mLastNameIsValid});
            }else{
                let mLastNameIsValid = {...this.state.mLastNameIsValid};
                mLastNameIsValid.status = true;
                mLastNameIsValid.focused = true;
                mLastNameIsValid.message = '';
                this.setState({mLastNameIsValid:mLastNameIsValid});
            }   
        }else if(prop === "mMiddleName"){
            if(value === ""){
                let mMiddleNameIsValid = {...this.state.mMiddleNameIsValid};
                mMiddleNameIsValid.status = true;
                mMiddleNameIsValid.focused = true;
                mMiddleNameIsValid.message = '';
                this.setState({mMiddleNameIsValid:mMiddleNameIsValid});
            }
            else if(!this.isNameValid(value)){
                let mMiddleNameIsValid = {...this.state.mMiddleNameIsValid};
                mMiddleNameIsValid.status = false;
                mMiddleNameIsValid.focused = true;
                mMiddleNameIsValid.message = 'Please enter a valid name';
                this.setState({mMiddleNameIsValid:mMiddleNameIsValid});
            }else{
                let mMiddleNameIsValid = {...this.state.mMiddleNameIsValid};
                mMiddleNameIsValid.status = true;
                mMiddleNameIsValid.focused = true;
                mMiddleNameIsValid.message = '';
                this.setState({mMiddleNameIsValid:mMiddleNameIsValid});
            }   
        }else if(prop === "mOccupation"){
            if(value === ""){
                let mOccupationIsValid = {...this.state.mOccupationIsValid};
                mOccupationIsValid.status = false;
                mOccupationIsValid.focused = true;
                mOccupationIsValid.message = 'This is a required field';
                this.setState({mOccupationIsValid:mOccupationIsValid});
            }else if(!this.isNameValid(value)){
                let mOccupationIsValid = {...this.state.mOccupationIsValid};
                mOccupationIsValid.status = false;
                mOccupationIsValid.focused = true;
                mOccupationIsValid.message = 'Please enter a valid occupation';
                this.setState({mOccupationIsValid:mOccupationIsValid});
            }else{
                let mOccupationIsValid = {...this.state.mParentType};
                mOccupationIsValid.status = true;
                mOccupationIsValid.focused = true;
                mOccupationIsValid.message = '';
                this.setState({mOccupationIsValid:mOccupationIsValid});
            }   
        }else if(prop === "mAnnualIncome"){
            if(value === ""){
                let mAnnualIncomeIsValid = {...this.state.mAnnualIncomeIsValid};
                mAnnualIncomeIsValid.status = false;
                mAnnualIncomeIsValid.focused = true;
                mAnnualIncomeIsValid.message = 'This is a required field';
                this.setState({mAnnualIncomeIsValid:mAnnualIncomeIsValid});
            }else{
                let mAnnualIncomeIsValid = {...this.state.mAnnualIncomeIsValid};
                mAnnualIncomeIsValid.status = true;
                mAnnualIncomeIsValid.focused = true;
                mAnnualIncomeIsValid.message = '';
                this.setState({mAnnualIncomeIsValid:mAnnualIncomeIsValid});
            }   
        }else if(prop === "fFirstName"){
            if(value === ""){
                let fFirstNameIsValid = {...this.state.fFirstNameIsValid};
                fFirstNameIsValid.status = false;
                fFirstNameIsValid.focused = true;
                fFirstNameIsValid.message = 'This is a required field';
                this.setState({fFirstNameIsValid:fFirstNameIsValid});
            }else if(!this.isNameValid(value)){
                let fFirstNameIsValid = {...this.state.fFirstNameIsValid};
                fFirstNameIsValid.status = false;
                fFirstNameIsValid.focused = true;
                fFirstNameIsValid.message = 'Please enter a valid name';
                this.setState({fFirstNameIsValid:fFirstNameIsValid});
            }else{
                let fFirstNameIsValid = {...this.state.fFirstNameIsValid};
                fFirstNameIsValid.status = true;
                fFirstNameIsValid.focused = true;
                fFirstNameIsValid.message = '';
                this.setState({fFirstNameIsValid:fFirstNameIsValid});
            }   
        }else if(prop === "fLastName"){
            if(value === ""){
                let fLastNameIsValid = {...this.state.fLastNameIsValid};
                fLastNameIsValid.status = false;
                fLastNameIsValid.focused = true;
                fLastNameIsValid.message = 'This is a required field';
                this.setState({fLastNameIsValid:fLastNameIsValid});
            }else if(!this.isNameValid(value)){
                let fLastNameIsValid = {...this.state.fLastNameIsValid};
                fLastNameIsValid.status = false;
                fLastNameIsValid.focused = true;
                fLastNameIsValid.message = 'Please enter a valid name';
                this.setState({fLastNameIsValid:fLastNameIsValid});
            }else{
                let fLastNameIsValid = {...this.state.fLastNameIsValid};
                fLastNameIsValid.status = true;
                fLastNameIsValid.focused = true;
                fLastNameIsValid.message = '';
                this.setState({fLastNameIsValid:fLastNameIsValid});
            }   
        }else if(prop === "fMiddleName"){
            if(value === ""){
                let fMiddleNameIsValid = {...this.state.fMiddleNameIsValid};
                fMiddleNameIsValid.status = true;
                fMiddleNameIsValid.focused = true;
                fMiddleNameIsValid.message = '';
                this.setState({fMiddleNameIsValid:fMiddleNameIsValid});
            }
            else if(!this.isNameValid(value)){
                let fMiddleNameIsValid = {...this.state.fMiddleNameIsValid};
                fMiddleNameIsValid.status = false;
                fMiddleNameIsValid.focused = true;
                fMiddleNameIsValid.message = 'Please enter a valid name';
                this.setState({fMiddleNameIsValid:fMiddleNameIsValid});
            }else{
                let fMiddleNameIsValid = {...this.state.fMiddleNameIsValid};
                fMiddleNameIsValid.status = true;
                fMiddleNameIsValid.focused = true;
                fMiddleNameIsValid.message = '';
                this.setState({fMiddleNameIsValid:fMiddleNameIsValid});
            }   
        }else if(prop === "fOccupation"){
            if(value === ""){
                let fOccupationIsValid = {...this.state.fOccupationIsValid};
                fOccupationIsValid.status = false;
                fOccupationIsValid.focused = true;
                fOccupationIsValid.message = 'This is a required field';
                this.setState({fOccupationIsValid:fOccupationIsValid});
            }else if(!this.isNameValid(value)){
                let fOccupationIsValid = {...this.state.fOccupationIsValid};
                fOccupationIsValid.status = false;
                fOccupationIsValid.focused = true;
                fOccupationIsValid.message = 'Please enter a valid occupation';
                this.setState({fOccupationIsValid:fOccupationIsValid});
            }else{
                let fOccupationIsValid = {...this.state.fOccupationIsValid};
                fOccupationIsValid.status = true;
                fOccupationIsValid.focused = true;
                fOccupationIsValid.message = '';
                this.setState({fOccupationIsValid:fOccupationIsValid});
            }   
        }else if(prop === "fAnnualIncome"){
            if(value === ""){
                let fAnnualIncomeIsValid = {...this.state.fAnnualIncomeIsValid};
                fAnnualIncomeIsValid.status = false;
                fAnnualIncomeIsValid.focused = true;
                fAnnualIncomeIsValid.message = 'This is a required field';
                this.setState({fAnnualIncomeIsValid:fAnnualIncomeIsValid});
            }else{
                let fAnnualIncomeIsValid = {...this.state.fAnnualIncomeIsValid};
                fAnnualIncomeIsValid.status = true;
                fAnnualIncomeIsValid.focused = true;
                fAnnualIncomeIsValid.message = '';
                this.setState({fAnnualIncomeIsValid:fAnnualIncomeIsValid});
            }   
        }else if(prop === "gLastName"){
            if(value === ""){
                let gLastNameIsValid = {...this.state.gLastNameIsValid};
                gLastNameIsValid.status = false;
                gLastNameIsValid.focused = true;
                gLastNameIsValid.message = 'This is a required field';
                this.setState({gLastNameIsValid:gLastNameIsValid});
            }else if(!this.isNameValid(value)){
                let gLastNameIsValid = {...this.state.gLastNameIsValid};
                gLastNameIsValid.status = false;
                gLastNameIsValid.focused = true;
                gLastNameIsValid.message = 'Please enter a valid name';
                this.setState({gLastNameIsValid:gLastNameIsValid});
            }else{
                let gLastNameIsValid = {...this.state.gLastNameIsValid};
                gLastNameIsValid.status = true;
                gLastNameIsValid.focused = true;
                gLastNameIsValid.message = '';
                this.setState({gLastNameIsValid:gLastNameIsValid});
            }   
        }else if(prop === "gMiddleName"){
            if(value === ""){
                let gMiddleNameIsValid = {...this.state.gMiddleNameIsValid};
                gMiddleNameIsValid.status = true;
                gMiddleNameIsValid.focused = true;
                gMiddleNameIsValid.message = '';
                this.setState({gMiddleNameIsValid:gMiddleNameIsValid});
            }else if(!this.isNameValid(value)){
                let gMiddleNameIsValid = {...this.state.gMiddleNameIsValid};
                gMiddleNameIsValid.status = false;
                gMiddleNameIsValid.focused = true;
                gMiddleNameIsValid.message = 'Please enter a valid name';
                this.setState({gMiddleNameIsValid:gMiddleNameIsValid});
            }else{
                let gMiddleNameIsValid = {...this.state.gMiddleNameIsValid};
                gMiddleNameIsValid.status = true;
                gMiddleNameIsValid.focused = true;
                gMiddleNameIsValid.message = '';
                this.setState({gMiddleNameIsValid:gMiddleNameIsValid});
            }   
        }else if(prop === "gFirstName"){
            if(value === ""){
                let gFirstNameIsValid = {...this.state.gFirstNameIsValid};
                gFirstNameIsValid.status = false;
                gFirstNameIsValid.focused = true;
                gFirstNameIsValid.message = 'This is a required field';
                this.setState({gFirstNameIsValid:gFirstNameIsValid});
            }else if(!this.isNameValid(value)){
                let gFirstNameIsValid = {...this.state.gFirstNameIsValid};
                gFirstNameIsValid.status = false;
                gFirstNameIsValid.focused = true;
                gFirstNameIsValid.message = 'Please enter a valid name';
                this.setState({gFirstNameIsValid:gFirstNameIsValid});
            }else{
                let gFirstNameIsValid = {...this.state.gFirstNameIsValid};
                gFirstNameIsValid.status = true;
                gFirstNameIsValid.focused = true;
                gFirstNameIsValid.message = '';
                this.setState({gFirstNameIsValid:gFirstNameIsValid});
            }   
        }else if(prop === "gFirstName"){
            if(value === ""){
                let gFirstNameIsValid = {...this.state.gFirstNameIsValid};
                gFirstNameIsValid.status = false;
                gFirstNameIsValid.focused = true;
                gFirstNameIsValid.message = 'This is a required field';
                this.setState({gFirstNameIsValid:gFirstNameIsValid});
            }else if(!this.isNameValid(value)){
                let gFirstNameIsValid = {...this.state.gFirstNameIsValid};
                gFirstNameIsValid.status = false;
                gFirstNameIsValid.focused = true;
                gFirstNameIsValid.message = 'Please enter a valid name';
                this.setState({gFirstNameIsValid:gFirstNameIsValid});
            }else{
                let gFirstNameIsValid = {...this.state.gFirstNameIsValid};
                gFirstNameIsValid.status = true;
                gFirstNameIsValid.focused = true;
                gFirstNameIsValid.message = '';
                this.setState({gFirstNameIsValid:gFirstNameIsValid});
            }   
        }else if(prop === "gOccupation"){
            if(value === ""){
                let gOccupationIsValid = {...this.state.gOccupationIsValid};
                gOccupationIsValid.status = false;
                gOccupationIsValid.focused = true;
                gOccupationIsValid.message = 'This is a required field';
                this.setState({gOccupationIsValid:gOccupationIsValid});
            }else if(!this.isNameValid(value)){
                let gOccupationIsValid = {...this.state.gOccupationIsValid};
                gOccupationIsValid.status = false;
                gOccupationIsValid.focused = true;
                gOccupationIsValid.message = 'Please enter a valid occupation';
                this.setState({gOccupationIsValid:gOccupationIsValid});
            }else{
                let fOccupationIsValid = {...this.state.fOccupationIsValid};
                fOccupationIsValid.status = true;
                fOccupationIsValid.focused = true;
                fOccupationIsValid.message = '';
                this.setState({fOccupationIsValid:fOccupationIsValid});
            }   
        }else if(prop === "gRelationship"){
            if(value === ""){
                let gRelationshipIsValid = {...this.state.gRelationshipIsValid};
                gRelationshipIsValid.status = false;
                gRelationshipIsValid.focused = true;
                gRelationshipIsValid.message = 'This is a required field';
                this.setState({gRelationshipIsValid:gRelationshipIsValid});
            }else if(!this.isNameValid(value)){
                let gRelationshipIsValid = {...this.state.gRelationshipIsValid};
                gRelationshipIsValid.status = false;
                gRelationshipIsValid.focused = true;
                gRelationshipIsValid.message = 'Please enter valid characters only';
                this.setState({gRelationshipIsValid:gRelationshipIsValid});
            }else{
                let gRelationshipIsValid = {...this.state.gRelationshipIsValid};
                gRelationshipIsValid.status = true;
                gRelationshipIsValid.focused = true;
                gRelationshipIsValid.message = '';
                this.setState({gRelationshipIsValid:gRelationshipIsValid});
            }   
        }else if(prop === "gAnnualIncome"){
            if(value === ""){
                let gAnnualIncomeIsValid = {...this.state.gAnnualIncomeIsValid};
                gAnnualIncomeIsValid.status = false;
                gAnnualIncomeIsValid.focused = true;
                gAnnualIncomeIsValid.message = 'This is a required field';
                this.setState({gAnnualIncomeIsValid:gAnnualIncomeIsValid});
            }else{
                let gAnnualIncomeIsValid = {...this.state.gAnnualIncomeIsValid};
                gAnnualIncomeIsValid.status = true;
                gAnnualIncomeIsValid.focused = true;
                gAnnualIncomeIsValid.message = '';
                this.setState({gAnnualIncomeIsValid:gAnnualIncomeIsValid});
            }   
        }else if(prop === "contactNumber"){
            if(value === ""){
                let contactNumberIsValid = {...this.state.contactNumberIsValid};
                contactNumberIsValid.status = false;
                contactNumberIsValid.focused = true;
                contactNumberIsValid.message = 'This is a required field';
                this.setState({contactNumberIsValid:contactNumberIsValid});
            }else if(!this.isContactNumberValid(value)){
                let contactNumberIsValid = {...this.state.contactNumberIsValid};
                contactNumberIsValid.status = false;
                contactNumberIsValid.focused = true;
                contactNumberIsValid.message = 'Please enter a valid 11-digit contact number';
                this.setState({contactNumberIsValid:contactNumberIsValid});
            }else{
                let contactNumberIsValid = {...this.state.contactNumberIsValid};
                contactNumberIsValid.status = true;
                contactNumberIsValid.focused = true;
                contactNumberIsValid.message = '';
                this.setState({contactNumberIsValid:contactNumberIsValid});
            }   
        }else if(prop === "presentAddressRegion"){
            if(value === ""){
                let presentAddressRegionIsValid = {...this.state.presentAddressRegionIsValid};
                presentAddressRegionIsValid.status = false;
                presentAddressRegionIsValid.focused = true;
                presentAddressRegionIsValid.message = 'This is a required field';
                this.setState({presentAddressRegionIsValid:presentAddressRegionIsValid});
            }else{
                let presentAddressRegionIsValid = {...this.state.presentAddressRegionIsValid};
                presentAddressRegionIsValid.status = true;
                presentAddressRegionIsValid.focused = true;
                presentAddressRegionIsValid.message = '';
                this.setState({presentAddressRegionIsValid:presentAddressRegionIsValid});
            }   
        }else if(prop === "presentAddressProvince"){
            if(value === ""){
                let presentAddressProvinceIsValid = {...this.state.presentAddressProvinceIsValid};
                presentAddressProvinceIsValid.status = false;
                presentAddressProvinceIsValid.focused = true;
                presentAddressProvinceIsValid.message = 'This is a required field';
                this.setState({presentAddressProvinceIsValid:presentAddressProvinceIsValid});
            }else{
                let presentAddressProvinceIsValid = {...this.state.presentAddressProvinceIsValid};
                presentAddressProvinceIsValid.status = true;
                presentAddressProvinceIsValid.focused = true;
                presentAddressProvinceIsValid.message = '';
                this.setState({presentAddressProvinceIsValid:presentAddressProvinceIsValid});
            }   
        }else if(prop === "presentAddressCity"){
            if(value === ""){
                let presentAddressCityIsValid = {...this.state.presentAddressCityIsValid};
                presentAddressCityIsValid.status = false;
                presentAddressCityIsValid.focused = true;
                presentAddressCityIsValid.message = 'This is a required field';
                this.setState({presentAddressCityIsValid:presentAddressCityIsValid});
            }else{
                let presentAddressCityIsValid = {...this.state.presentAddressCityIsValid};
                presentAddressCityIsValid.status = true;
                presentAddressCityIsValid.focused = true;
                presentAddressCityIsValid.message = '';
                this.setState({presentAddressCityIsValid:presentAddressCityIsValid});
            }   
        }else if(prop === "presentAddressBrgy"){
            if(value === ""){
                let presentAddressBrgyIsValid = {...this.state.presentAddressBrgyIsValid};
                presentAddressBrgyIsValid.status = false;
                presentAddressBrgyIsValid.focused = true;
                presentAddressBrgyIsValid.message = 'This is a required field';
                this.setState({presentAddressBrgyIsValid:presentAddressBrgyIsValid});
            }else{
                let presentAddressBrgyIsValid = {...this.state.presentAddressBrgyIsValid};
                presentAddressBrgyIsValid.status = true;
                presentAddressBrgyIsValid.focused = true;
                presentAddressBrgyIsValid.message = '';
                this.setState({presentAddressBrgyIsValid:presentAddressBrgyIsValid});
            }   
        }else if(prop === "presentAddressStreet"){
            if(value === ""){
                let presentAddressStreetIsValid = {...this.state.presentAddressStreetIsValid};
                presentAddressStreetIsValid.status = false;
                presentAddressStreetIsValid.focused = true;
                presentAddressStreetIsValid.message = 'This is a required field';
                this.setState({presentAddressStreetIsValid:presentAddressStreetIsValid});
            }else{
                let presentAddressStreetIsValid = {...this.state.presentAddressStreetIsValid};
                presentAddressStreetIsValid.status = true;
                presentAddressStreetIsValid.focused = true;
                presentAddressStreetIsValid.message = '';
                this.setState({presentAddressStreetIsValid:presentAddressStreetIsValid});
            }   
        }else if(prop === "presentAddressZipCode"){
            if(value === ""){
                let presentAddressZipCodeIsValid = {...this.state.presentAddressZipCodeIsValid};
                presentAddressZipCodeIsValid.status = false;
                presentAddressZipCodeIsValid.focused = true;
                presentAddressZipCodeIsValid.message = 'This is a required field';
                this.setState({presentAddressZipCodeIsValid:presentAddressZipCodeIsValid});
            }else{
                let presentAddressZipCodeIsValid = {...this.state.presentAddressZipCodeIsValid};
                presentAddressZipCodeIsValid.status = true;
                presentAddressZipCodeIsValid.focused = true;
                presentAddressZipCodeIsValid.message = '';
                this.setState({presentAddressZipCodeIsValid:presentAddressZipCodeIsValid});
            }   
        }else if(prop === "permanentAddressRegion"){
            if(value === ""){
                let permanentAddressRegionIsValid = {...this.state.permanentAddressRegionIsValid};
                permanentAddressRegionIsValid.status = false;
                permanentAddressRegionIsValid.focused = true;
                permanentAddressRegionIsValid.message = 'This is a required field';
                this.setState({permanentAddressRegionIsValid:permanentAddressRegionIsValid});
            }else{
                let permanentAddressRegionIsValid = {...this.state.permanentAddressRegionIsValid};
                permanentAddressRegionIsValid.status = true;
                permanentAddressRegionIsValid.focused = true;
                permanentAddressRegionIsValid.message = '';
                this.setState({permanentAddressRegionIsValid:permanentAddressRegionIsValid});
            }   
        }else if(prop === "permanentAddressProvince"){
            if(value === ""){
                let permanentAddressProvinceIsValid = {...this.state.permanentAddressProvinceIsValid};
                permanentAddressProvinceIsValid.status = false;
                permanentAddressProvinceIsValid.focused = true;
                permanentAddressProvinceIsValid.message = 'This is a required field';
                this.setState({permanentAddressProvinceIsValid:permanentAddressProvinceIsValid});
            }else{
                let permanentAddressProvinceIsValid = {...this.state.permanentAddressProvinceIsValid};
                permanentAddressProvinceIsValid.status = true;
                permanentAddressProvinceIsValid.focused = true;
                permanentAddressProvinceIsValid.message = '';
                this.setState({permanentAddressProvinceIsValid:permanentAddressProvinceIsValid});
            }   
        }else if(prop === "permanentAddressCity"){
            if(value === ""){
                let permanentAddressCityIsValid = {...this.state.permanentAddressCityIsValid};
                permanentAddressCityIsValid.status = false;
                permanentAddressCityIsValid.focused = true;
                permanentAddressCityIsValid.message = 'This is a required field';
                this.setState({permanentAddressCityIsValid:permanentAddressCityIsValid});
            }else{
                let permanentAddressCityIsValid = {...this.state.permanentAddressCityIsValid};
                permanentAddressCityIsValid.status = true;
                permanentAddressCityIsValid.focused = true;
                permanentAddressCityIsValid.message = '';
                this.setState({permanentAddressCityIsValid:permanentAddressCityIsValid});
            }   
        }else if(prop === "permanentAddressBrgy"){
            if(value === ""){
                let permanentAddressBrgyIsValid = {...this.state.permanentAddressBrgyIsValid};
                permanentAddressBrgyIsValid.status = false;
                permanentAddressBrgyIsValid.focused = true;
                permanentAddressBrgyIsValid.message = 'This is a required field';
                this.setState({permanentAddressBrgyIsValid:permanentAddressBrgyIsValid});
            }else{
                let permanentAddressBrgyIsValid = {...this.state.permanentAddressBrgyIsValid};
                permanentAddressBrgyIsValid.status = true;
                permanentAddressBrgyIsValid.focused = true;
                permanentAddressBrgyIsValid.message = '';
                this.setState({permanentAddressBrgyIsValid:permanentAddressBrgyIsValid});
            }   
        }else if(prop === "permanentAddressStreet"){
            if(value === ""){
                let permanentAddressStreetIsValid = {...this.state.permanentAddressStreetIsValid};
                permanentAddressStreetIsValid.status = false;
                permanentAddressStreetIsValid.focused = true;
                permanentAddressStreetIsValid.message = 'This is a required field';
                this.setState({permanentAddressStreetIsValid:permanentAddressStreetIsValid});
            }else{
                let permanentAddressStreetIsValid = {...this.state.permanentAddressStreetIsValid};
                permanentAddressStreetIsValid.status = true;
                permanentAddressStreetIsValid.focused = true;
                permanentAddressStreetIsValid.message = '';
                this.setState({permanentAddressStreetIsValid:permanentAddressStreetIsValid});
            }   
        }else if(prop === "permanentAddressZipCode"){
            if(value === ""){
                let permanentAddressZipCodeIsValid = {...this.state.permanentAddressZipCodeIsValid};
                permanentAddressZipCodeIsValid.status = false;
                permanentAddressZipCodeIsValid.focused = true;
                permanentAddressZipCodeIsValid.message = 'This is a required field';
                this.setState({permanentAddressZipCodeIsValid:permanentAddressZipCodeIsValid});
            }else{
                let permanentAddressZipCodeIsValid = {...this.state.permanentAddressZipCodeIsValid};
                permanentAddressZipCodeIsValid.status = true;
                permanentAddressZipCodeIsValid.focused = true;
                permanentAddressZipCodeIsValid.message = '';
                this.setState({permanentAddressZipCodeIsValid:permanentAddressZipCodeIsValid});
            }   
        }else if(prop === "grade9GradeEnglish"){
            if(value === ""){
                
                let grade9GradeEnglishIsValid = {...this.state.grade9GradeEnglishIsValid};
                grade9GradeEnglishIsValid.status = false;
                grade9GradeEnglishIsValid.focused = true;
                grade9GradeEnglishIsValid.message = 'This is a required field';
                this.setState({grade9GradeEnglishIsValid:grade9GradeEnglishIsValid});
            }else if(parseInt(value) < 0){
                
                let grade9GradeEnglishIsValid = {...this.state.grade9GradeEnglishIsValid};
                grade9GradeEnglishIsValid.status = false;
                grade9GradeEnglishIsValid.focused = true;
                grade9GradeEnglishIsValid.message = 'Invalid grade';
                this.setState({grade9GradeEnglishIsValid:grade9GradeEnglishIsValid});
            }else if(parseInt(value) > 100){
                let grade9GradeEnglishIsValid = {...this.state.grade9GradeEnglishIsValid};
                grade9GradeEnglishIsValid.status = false;
                grade9GradeEnglishIsValid.focused = true;
                grade9GradeEnglishIsValid.message = 'The maximum grade is 100';
                this.setState({grade9GradeEnglishIsValid:grade9GradeEnglishIsValid});
            }else if(isNaN(value)){
                let grade9GradeEnglishIsValid = {...this.state.grade9GradeEnglishIsValid};
                grade9GradeEnglishIsValid.status = false;
                grade9GradeEnglishIsValid.focused = true;
                grade9GradeEnglishIsValid.message = 'Please enter a valid number';
                this.setState({grade9GradeEnglishIsValid:grade9GradeEnglishIsValid});

            }else{
                let grade9GradeEnglishIsValid = {...this.state.grade9GradeEnglish};
                grade9GradeEnglishIsValid.status = true;
                grade9GradeEnglishIsValid.focused = true;
                grade9GradeEnglishIsValid.message = '';
                this.setState({grade9GradeEnglishIsValid:grade9GradeEnglishIsValid});
            }   
        }else if(prop === "grade10GradeEnglish"){
            if(value === ""){
                let grade10GradeEnglishIsValid = {...this.state.grade10GradeEnglishIsValid};
                grade10GradeEnglishIsValid.status = false;
                grade10GradeEnglishIsValid.focused = true;
                grade10GradeEnglishIsValid.message = 'This is a required field';
                this.setState({grade10GradeEnglishIsValid:grade10GradeEnglishIsValid});
            }else if(parseInt(value) < 0){
                let grade10GradeEnglishIsValid = {...this.state.grade10GradeEnglishIsValid};
                grade10GradeEnglishIsValid.status = false;
                grade10GradeEnglishIsValid.focused = true;
                // grade10GradeEnglishIsValid.message = 'The minimum required grade is 85';
                grade10GradeEnglishIsValid.message = 'Invalid grade';
                this.setState({grade10GradeEnglishIsValid:grade10GradeEnglishIsValid});
            }else if(parseInt(value) > 100){
                let grade10GradeEnglishIsValid = {...this.state.grade10GradeEnglishIsValid};
                grade10GradeEnglishIsValid.status = false;
                grade10GradeEnglishIsValid.focused = true;
                grade10GradeEnglishIsValid.message = 'The maximum grade is 100';
                this.setState({grade10GradeEnglishIsValid:grade10GradeEnglishIsValid});
            }else if(isNaN(value)){
                let grade10GradeEnglishIsValid = {...this.state.grade10GradeEnglishIsValid};
                grade10GradeEnglishIsValid.status = false;
                grade10GradeEnglishIsValid.focused = true;
                grade10GradeEnglishIsValid.message = 'Please enter a valid number';
                this.setState({grade10GradeEnglishIsValid:grade10GradeEnglishIsValid});

            }else{
                let grade10GradeEnglishIsValid = {...this.state.grade10GradeEnglish};
                grade10GradeEnglishIsValid.status = true;
                grade10GradeEnglishIsValid.focused = true;
                grade10GradeEnglishIsValid.message = '';
                this.setState({grade10GradeEnglishIsValid:grade10GradeEnglishIsValid});
            }   
        }else if(prop === "grade11GradeEnglish"){
            if(value === ""){
                let grade11GradeEnglishIsValid = {...this.state.grade11GradeEnglishIsValid};
                grade11GradeEnglishIsValid.status = false;
                grade11GradeEnglishIsValid.focused = true;
                grade11GradeEnglishIsValid.message = 'This is a required field';
                this.setState({grade11GradeEnglishIsValid:grade11GradeEnglishIsValid});
            }else if(parseInt(value) < 0){
                let grade11GradeEnglishIsValid = {...this.state.grade11GradeEnglishIsValid};
                grade11GradeEnglishIsValid.status = false;
                grade11GradeEnglishIsValid.focused = true;
                // grade11GradeEnglishIsValid.message = 'The minimum required grade is 85';
                grade11GradeEnglishIsValid.message = 'Invalid grade';
                this.setState({grade11GradeEnglishIsValid:grade11GradeEnglishIsValid});
            }else if(parseInt(value) > 100){
                let grade11GradeEnglishIsValid = {...this.state.grade11GradeEnglishIsValid};
                grade11GradeEnglishIsValid.status = false;
                grade11GradeEnglishIsValid.focused = true;
                grade11GradeEnglishIsValid.message = 'The maximum grade is 100';
                this.setState({grade11GradeEnglishIsValid:grade11GradeEnglishIsValid});
            }else if(isNaN(value)){
                let grade11GradeEnglishIsValid = {...this.state.grade11GradeEnglishIsValid};
                grade11GradeEnglishIsValid.status = false;
                grade11GradeEnglishIsValid.focused = true;
                grade11GradeEnglishIsValid.message = 'Please enter a valid number';
                this.setState({grade11GradeEnglishIsValid:grade11GradeEnglishIsValid});

            }else{
                let grade11GradeEnglishIsValid = {...this.state.grade11GradeEnglish};
                grade11GradeEnglishIsValid.status = true;
                grade11GradeEnglishIsValid.focused = true;
                grade11GradeEnglishIsValid.message = '';
                this.setState({grade11GradeEnglishIsValid:grade11GradeEnglishIsValid});
            }   
        }else if(prop === "grade9GradeMath"){
            if(value === ""){
                let grade9GradeMathIsValid = {...this.state.grade9GradeMathIsValid};
                grade9GradeMathIsValid.status = false;
                grade9GradeMathIsValid.focused = true;
                grade9GradeMathIsValid.message = 'This is a required field';
                this.setState({grade9GradeMathIsValid:grade9GradeMathIsValid});
            }else if(parseInt(value) < 0){
                let grade9GradeMathIsValid = {...this.state.grade9GradeMathIsValid};
                grade9GradeMathIsValid.status = false;
                grade9GradeMathIsValid.focused = true;
                // grade9GradeMathIsValid.message = 'The minimum required grade is 85';
                grade9GradeMathIsValid.message = 'Invalid grade';
                this.setState({grade9GradeMathIsValid:grade9GradeMathIsValid});
            }else if(parseInt(value) > 100){
                let grade9GradeMathIsValid = {...this.state.grade9GradeMathIsValid};
                grade9GradeMathIsValid.status = false;
                grade9GradeMathIsValid.focused = true;
                grade9GradeMathIsValid.message = 'The maximum grade is 100';
                this.setState({grade9GradeMathIsValid:grade9GradeMathIsValid});
            }else if(isNaN(value)){
                let grade9GradeMathIsValid = {...this.state.grade9GradeMathIsValid};
                grade9GradeMathIsValid.status = false;
                grade9GradeMathIsValid.focused = true;
                grade9GradeMathIsValid.message = 'Please enter a valid number';
                this.setState({grade9GradeMathIsValid:grade9GradeMathIsValid});

            }else{
                let grade9GradeMathIsValid = {...this.state.grade9GradeMath};
                grade9GradeMathIsValid.status = true;
                grade9GradeMathIsValid.focused = true;
                grade9GradeMathIsValid.message = '';
                this.setState({grade9GradeMathIsValid:grade9GradeMathIsValid});
            }   
        }else if(prop === "grade10GradeMath"){
            if(value === ""){
                let grade10GradeMathIsValid = {...this.state.grade10GradeMathIsValid};
                grade10GradeMathIsValid.status = false;
                grade10GradeMathIsValid.focused = true;
                grade10GradeMathIsValid.message = 'This is a required field';
                this.setState({grade10GradeMathIsValid:grade10GradeMathIsValid});
            }else if(parseInt(value) < 0){
                let grade10GradeMathIsValid = {...this.state.grade10GradeMathIsValid};
                grade10GradeMathIsValid.status = false;
                grade10GradeMathIsValid.focused = true;
                // grade10GradeMathIsValid.message = 'The minimum required grade is 85';
                grade10GradeMathIsValid.message = 'Invalid grade';
                this.setState({grade10GradeMathIsValid:grade10GradeMathIsValid});
            }else if(parseInt(value) > 100){
                let grade10GradeMathIsValid = {...this.state.grade10GradeMathIsValid};
                grade10GradeMathIsValid.status = false;
                grade10GradeMathIsValid.focused = true;
                grade10GradeMathIsValid.message = 'The maximum grade is 100';
                this.setState({grade10GradeMathIsValid:grade10GradeMathIsValid});
            }else if(isNaN(value)){
                let grade10GradeMathIsValid = {...this.state.grade10GradeMathIsValid};
                grade10GradeMathIsValid.status = false;
                grade10GradeMathIsValid.focused = true;
                grade10GradeMathIsValid.message = 'Please enter a valid number';
                this.setState({grade10GradeMathIsValid:grade10GradeMathIsValid});

            }else{
                let grade10GradeMathIsValid = {...this.state.grade10GradeMath};
                grade10GradeMathIsValid.status = true;
                grade10GradeMathIsValid.focused = true;
                grade10GradeMathIsValid.message = '';
                this.setState({grade10GradeMathIsValid:grade10GradeMathIsValid});
            }   
        }else if(prop === "grade11GradeMath"){
            if(value === ""){
                let grade11GradeMathIsValid = {...this.state.grade11GradeMathIsValid};
                grade11GradeMathIsValid.status = false;
                grade11GradeMathIsValid.focused = true;
                grade11GradeMathIsValid.message = 'This is a required field';
                this.setState({grade11GradeMathIsValid:grade11GradeMathIsValid});
            }else if(parseInt(value) < 0){
                let grade11GradeMathIsValid = {...this.state.grade11GradeMathIsValid};
                grade11GradeMathIsValid.status = false;
                grade11GradeMathIsValid.focused = true;
                // grade11GradeMathIsValid.message = 'The minimum required grade is 85';
                grade11GradeMathIsValid.message = 'Invalid grade';
                this.setState({grade11GradeMathIsValid:grade11GradeMathIsValid});
            }else if(parseInt(value) > 100){
                let grade11GradeMathIsValid = {...this.state.grade11GradeMathIsValid};
                grade11GradeMathIsValid.status = false;
                grade11GradeMathIsValid.focused = true;
                grade11GradeMathIsValid.message = 'The maximum grade is 100';
                this.setState({grade11GradeMathIsValid:grade11GradeMathIsValid});
            }else if(isNaN(value)){
                let grade11GradeMathIsValid = {...this.state.grade11GradeMathIsValid};
                grade11GradeMathIsValid.status = false;
                grade11GradeMathIsValid.focused = true;
                grade11GradeMathIsValid.message = 'Please enter a valid number';
                this.setState({grade11GradeMathIsValid:grade11GradeMathIsValid});

            }else{
                let grade11GradeMathIsValid = {...this.state.grade11GradeMath};
                grade11GradeMathIsValid.status = true;
                grade11GradeMathIsValid.focused = true;
                grade11GradeMathIsValid.message = '';
                this.setState({grade11GradeMathIsValid:grade11GradeMathIsValid});
            }   
        }else if(prop === "grade9GradeScience"){
            if(value === ""){
                let grade9GradeScienceIsValid = {...this.state.grade9GradeScienceIsValid};
                grade9GradeScienceIsValid.status = false;
                grade9GradeScienceIsValid.focused = true;
                grade9GradeScienceIsValid.message = 'This is a required field';
                this.setState({grade9GradeScienceIsValid:grade9GradeScienceIsValid});
            }else if(parseInt(value) < 0){
                let grade9GradeScienceIsValid = {...this.state.grade9GradeScienceIsValid};
                grade9GradeScienceIsValid.status = false;
                grade9GradeScienceIsValid.focused = true;
                // grade9GradeScienceIsValid.message = 'The minimum required grade is 85';
                grade9GradeScienceIsValid.message = 'Invalid grade';
                this.setState({grade9GradeScienceIsValid:grade9GradeScienceIsValid});
            }else if(parseInt(value) > 100){
                let grade9GradeScienceIsValid = {...this.state.grade9GradeScienceIsValid};
                grade9GradeScienceIsValid.status = false;
                grade9GradeScienceIsValid.focused = true;
                grade9GradeScienceIsValid.message = 'The maximum grade is 100';
                this.setState({grade9GradeScienceIsValid:grade9GradeScienceIsValid});
            }else if(isNaN(value)){
                let grade9GradeScienceIsValid = {...this.state.grade9GradeScienceIsValid};
                grade9GradeScienceIsValid.status = false;
                grade9GradeScienceIsValid.focused = true;
                grade9GradeScienceIsValid.message = 'Please enter a valid number';
                this.setState({grade9GradeScienceIsValid:grade9GradeScienceIsValid});

            }else{
                let grade9GradeScienceIsValid = {...this.state.grade9GradeScience};
                grade9GradeScienceIsValid.status = true;
                grade9GradeScienceIsValid.focused = true;
                grade9GradeScienceIsValid.message = '';
                this.setState({grade9GradeScienceIsValid:grade9GradeScienceIsValid});
            }   
        }else if(prop === "grade10GradeScience"){
            if(value === ""){
                let grade10GradeScienceIsValid = {...this.state.grade10GradeScienceIsValid};
                grade10GradeScienceIsValid.status = false;
                grade10GradeScienceIsValid.focused = true;
                grade10GradeScienceIsValid.message = 'This is a required field';
                this.setState({grade10GradeScienceIsValid:grade10GradeScienceIsValid});
            }else if(parseInt(value) < 0){
                let grade10GradeScienceIsValid = {...this.state.grade10GradeScienceIsValid};
                grade10GradeScienceIsValid.status = false;
                grade10GradeScienceIsValid.focused = true;
                // grade10GradeScienceIsValid.message = 'The minimum required grade is 85';
                grade10GradeScienceIsValid.message = 'Invalid grade';
                this.setState({grade10GradeScienceIsValid:grade10GradeScienceIsValid});
            }else if(parseInt(value) > 100){
                let grade10GradeScienceIsValid = {...this.state.grade10GradeScienceIsValid};
                grade10GradeScienceIsValid.status = false;
                grade10GradeScienceIsValid.focused = true;
                grade10GradeScienceIsValid.message = 'The maximum grade is 100';
                this.setState({grade10GradeScienceIsValid:grade10GradeScienceIsValid});
            }else if(isNaN(value)){
                let grade10GradeScienceIsValid = {...this.state.grade10GradeScienceIsValid};
                grade10GradeScienceIsValid.status = false;
                grade10GradeScienceIsValid.focused = true;
                grade10GradeScienceIsValid.message = 'Please enter a valid number';
                this.setState({grade10GradeScienceIsValid:grade10GradeScienceIsValid});

            }else{
                let grade10GradeScienceIsValid = {...this.state.grade10GradeScience};
                grade10GradeScienceIsValid.status = true;
                grade10GradeScienceIsValid.focused = true;
                grade10GradeScienceIsValid.message = '';
                this.setState({grade10GradeScienceIsValid:grade10GradeScienceIsValid});
            }   
        }else if(prop === "grade11GradeScience"){
            if(value === ""){
                let grade11GradeScienceIsValid = {...this.state.grade11GradeScienceIsValid};
                grade11GradeScienceIsValid.status = false;
                grade11GradeScienceIsValid.focused = true;
                grade11GradeScienceIsValid.message = 'This is a required field';
                this.setState({grade11GradeScienceIsValid:grade11GradeScienceIsValid});
            }else if(parseInt(value) < 0){
                let grade11GradeScienceIsValid = {...this.state.grade11GradeScienceIsValid};
                grade11GradeScienceIsValid.status = false;
                grade11GradeScienceIsValid.focused = true;
                // grade11GradeScienceIsValid.message = 'The minimum required grade is 85';
                grade11GradeScienceIsValid.message = 'Invalid grade';
                this.setState({grade11GradeScienceIsValid:grade11GradeScienceIsValid});
            }else if(parseInt(value) > 100){
                let grade11GradeScienceIsValid = {...this.state.grade11GradeScienceIsValid};
                grade11GradeScienceIsValid.status = false;
                grade11GradeScienceIsValid.focused = true;
                grade11GradeScienceIsValid.message = 'The maximum grade is 100';
                this.setState({grade11GradeScienceIsValid:grade11GradeScienceIsValid});
            }else if(isNaN(value)){
                let grade11GradeScienceIsValid = {...this.state.grade11GradeScienceIsValid};
                grade11GradeScienceIsValid.status = false;
                grade11GradeScienceIsValid.focused = true;
                grade11GradeScienceIsValid.message = 'Please enter a valid number';
                this.setState({grade11GradeScienceIsValid:grade11GradeScienceIsValid});

            }else{
                let grade11GradeScienceIsValid = {...this.state.grade11GradeScience};
                grade11GradeScienceIsValid.status = true;
                grade11GradeScienceIsValid.focused = true;
                grade11GradeScienceIsValid.message = '';
                this.setState({grade11GradeScienceIsValid:grade11GradeScienceIsValid});
            }   
        }else if(prop === "grade9GPA"){
            if(value === ""){
                let grade9GPAIsValid = {...this.state.grade9GPAIsValid};
                grade9GPAIsValid.status = false;
                grade9GPAIsValid.focused = true;
                grade9GPAIsValid.message = 'This is a required field';
                this.setState({grade9GPAIsValid:grade9GPAIsValid});
            }else if(parseInt(value) < 0){
                let grade9GPAIsValid = {...this.state.grade9GPAIsValid};
                grade9GPAIsValid.status = false;
                grade9GPAIsValid.focused = true;
                // grade9GPAIsValid.message = 'The minimum required grade is 85';
                grade9GPAIsValid.message = 'Invalid grade';
                this.setState({grade9GPAIsValid:grade9GPAIsValid});
            }else if(parseInt(value) > 100){
                let grade9GPAIsValid = {...this.state.grade9GPAIsValid};
                grade9GPAIsValid.status = false;
                grade9GPAIsValid.focused = true;
                grade9GPAIsValid.message = 'The maximum grade is 100';
                this.setState({grade9GPAIsValid:grade9GPAIsValid});
            }else if(isNaN(value)){
                let grade9GPAIsValid = {...this.state.grade9GPAIsValid};
                grade9GPAIsValid.status = false;
                grade9GPAIsValid.focused = true;
                grade9GPAIsValid.message = 'Please enter a valid number';
                this.setState({grade9GPAIsValid:grade9GPAIsValid});

            }else{
                let grade9GPAIsValid = {...this.state.grade9GPA};
                grade9GPAIsValid.status = true;
                grade9GPAIsValid.focused = true;
                grade9GPAIsValid.message = '';
                this.setState({grade9GPAIsValid:grade9GPAIsValid});
            }   
        }else if(prop === "grade10GPA"){
            if(value === ""){
                let grade10GPAIsValid = {...this.state.grade10GPAIsValid};
                grade10GPAIsValid.status = false;
                grade10GPAIsValid.focused = true;
                grade10GPAIsValid.message = 'This is a required field';
                this.setState({grade10GPAIsValid:grade10GPAIsValid});
            }else if(parseInt(value) < 0){
                let grade10GPAIsValid = {...this.state.grade10GPAIsValid};
                grade10GPAIsValid.status = false;
                grade10GPAIsValid.focused = true;
                // grade10GPAIsValid.message = 'The minimum required grade is 85';
                grade10GPAIsValid.message = 'Invalid grade';
                this.setState({grade10GPAIsValid:grade10GPAIsValid});
            }else if(parseInt(value) > 100){
                let grade10GPAIsValid = {...this.state.grade10GPAIsValid};
                grade10GPAIsValid.status = false;
                grade10GPAIsValid.focused = true;
                grade10GPAIsValid.message = 'The maximum grade is 100';
                this.setState({grade10GPAIsValid:grade10GPAIsValid});
            }else if(isNaN(value)){
                let grade10GPAIsValid = {...this.state.grade10GPAIsValid};
                grade10GPAIsValid.status = false;
                grade10GPAIsValid.focused = true;
                grade10GPAIsValid.message = 'Please enter a valid number';
                this.setState({grade10GPAIsValid:grade10GPAIsValid});

            }else{
                let grade10GPAIsValid = {...this.state.grade10GPA};
                grade10GPAIsValid.status = true;
                grade10GPAIsValid.focused = true;
                grade10GPAIsValid.message = '';
                this.setState({grade10GPAIsValid:grade10GPAIsValid});
            }   
        }else if(prop === "grade11GPA"){
            if(value === ""){
                let grade11GPAIsValid = {...this.state.grade11GPAIsValid};
                grade11GPAIsValid.status = false;
                grade11GPAIsValid.focused = true;
                grade11GPAIsValid.message = 'This is a required field';
                this.setState({grade11GPAIsValid:grade11GPAIsValid});
            }else if(parseInt(value) < 0){
                let grade11GPAIsValid = {...this.state.grade11GPAIsValid};
                grade11GPAIsValid.status = false;
                grade11GPAIsValid.focused = true;
                // grade11GPAIsValid.message = 'The minimum required grade is 85';
                grade11GPAIsValid.message = 'Invalid grade';
                this.setState({grade11GPAIsValid:grade11GPAIsValid});
            }else if(parseInt(value) > 100){
                let grade11GPAIsValid = {...this.state.grade11GPAIsValid};
                grade11GPAIsValid.status = false;
                grade11GPAIsValid.focused = true;
                grade11GPAIsValid.message = 'The maximum grade is 100';
                this.setState({grade11GPAIsValid:grade11GPAIsValid});
            }else if(isNaN(value)){
                let grade11GPAIsValid = {...this.state.grade11GPAIsValid};
                grade11GPAIsValid.status = false;
                grade11GPAIsValid.focused = true;
                grade11GPAIsValid.message = 'Please enter a valid number';
                this.setState({grade11GPAIsValid:grade11GPAIsValid});

            }else{
                let grade11GPAIsValid = {...this.state.grade11GPA};
                grade11GPAIsValid.status = true;
                grade11GPAIsValid.focused = true;
                grade11GPAIsValid.message = '';
                this.setState({grade11GPAIsValid:grade11GPAIsValid});
            }   
        }else if(prop === "collegeGPA"){
            if(value === ""){
                let collegeGPAIsValid = {...this.state.collegeGPAIsValid};
                collegeGPAIsValid.status = false;
                collegeGPAIsValid.focused = true;
                collegeGPAIsValid.message = 'This is a required field';
                this.setState({collegeGPAIsValid:collegeGPAIsValid});
            // }else if(parseInt(value) < 85){
            //     let collegeGPAIsValid = {...this.state.collegeGPAIsValid};
            //     collegeGPAIsValid.status = false;
            //     collegeGPAIsValid.focused = true;
            //     collegeGPAIsValid.message = 'The minimum required grade is 85';
            //     this.setState({collegeGPAIsValid:collegeGPAIsValid});
            // }else if(parseInt(value) > 100){
            //     let collegeGPAIsValid = {...this.state.collegeGPAIsValid};
            //     collegeGPAIsValid.status = false;
            //     collegeGPAIsValid.focused = true;
            //     collegeGPAIsValid.message = 'The maximum grade is 100';
            //     this.setState({collegeGPAIsValid:collegeGPAIsValid});
            }else if(isNaN(value)){
                let collegeGPAIsValid = {...this.state.collegeGPAIsValid};
                collegeGPAIsValid.status = false;
                collegeGPAIsValid.focused = true;
                collegeGPAIsValid.message = 'Please enter a valid number';
                this.setState({collegeGPAIsValid:collegeGPAIsValid});

            }else{
                let collegeGPAIsValid = {...this.state.collegeGPA};
                collegeGPAIsValid.status = true;
                collegeGPAIsValid.focused = true;
                collegeGPAIsValid.message = '';
                this.setState({collegeGPAIsValid:collegeGPAIsValid});
            }   
        }else if(prop === "file_form137"){
            if(!value){
                let file_form137IsValid = {...this.state.file_form137IsValid};
                file_form137IsValid.status = false;
                file_form137IsValid.focused = true;
                file_form137IsValid.message = 'This is a required field';
                this.setState({file_form137IsValid:file_form137IsValid});
            }else{
                this.setState({fileIsSelected: true});
                const fileValidationResult = this.handleFileUploadValidation('doc',value);
                if(fileValidationResult.status){
                    let file_form137IsValid = {...this.state.file_form137IsValid};
                    file_form137IsValid.status = true;
                    file_form137IsValid.focused = true;
                    file_form137IsValid.message = '';
                    this.setState({file_form137IsValid:file_form137IsValid,uploadedFileInfo:fileValidationResult});
                }else{
                    let file_form137IsValid = {...this.state.file_form137IsValid};
                    file_form137IsValid.status = false;
                    file_form137IsValid.focused = false;
                    file_form137IsValid.message = '';
                    this.setState({
                        uploadedFileInfo:fileValidationResult,
                        file_form137: null,
                        file_form137_src:'',
                        file_form137IsValid: file_form137IsValid
                    });

                }
                
            }   
        }else if(prop === "file_ToR"){
            if(!value){
                let file_ToRIsValid = {...this.state.file_ToRIsValid};
                file_ToRIsValid.status = false;
                file_ToRIsValid.focused = true;
                file_ToRIsValid.message = 'This is a required field';
                this.setState({file_ToRIsValid:file_ToRIsValid});
            }else{
                this.setState({fileIsSelected: true});
                const fileValidationResult = this.handleFileUploadValidation('doc',value);
                if(fileValidationResult.status){
                    let file_ToRIsValid = {...this.state.file_ToRIsValid};
                    file_ToRIsValid.status = true;
                    file_ToRIsValid.focused = true;
                    file_ToRIsValid.message = '';
                    this.setState({file_ToRIsValid:file_ToRIsValid,uploadedFileInfo:fileValidationResult});
                }else{
                    let file_ToRIsValid = {...this.state.file_ToRIsValid};
                    file_ToRIsValid.status = false;
                    file_ToRIsValid.focused = false;
                    file_ToRIsValid.message = '';
                    this.setState({
                        file_ToR: null,
                        file_ToR_src: '',
                        uploadedFileInfo:fileValidationResult,
                        file_ToRIsValid: file_ToRIsValid
                    });
                }
            }   
        }else if(prop === "file_passportPhoto"){
            if(!value){
                let file_passportPhotoIsValid = {...this.state.file_passportPhotoIsValid};
                file_passportPhotoIsValid.status = false;
                file_passportPhotoIsValid.focused = true;
                file_passportPhotoIsValid.message = 'This is a required field';
                this.setState({file_passportPhotoIsValid:file_passportPhotoIsValid});
            }else{
                let file_passportPhotoIsValid = {...this.state.file_passportPhotoIsValid};
                file_passportPhotoIsValid.status = true;
                file_passportPhotoIsValid.focused = true;
                file_passportPhotoIsValid.message = '';
                this.setState({file_passportPhotoIsValid:file_passportPhotoIsValid});
            }   
        }else if(prop === "file_idPhoto"){
            if(value === ""){
                let file_idPhotoIsValid = {...this.state.file_idPhotoIsValid};
                file_idPhotoIsValid.status = false;
                file_idPhotoIsValid.focused = true;
                file_idPhotoIsValid.message = 'This is a required field';
                this.setState({file_idPhotoIsValid:file_idPhotoIsValid});
            }else{
                let file_idPhotoIsValid = {...this.state.file_idPhotoIsValid};
                file_idPhotoIsValid.status = true;
                file_idPhotoIsValid.focused = true;
                file_idPhotoIsValid.message = '';
                this.setState({file_idPhotoIsValid:file_idPhotoIsValid});
            }   
        }else if(prop === "file_incomeSource"){
            if(!value){
                let file_incomeSourceIsValid = {...this.state.file_incomeSourceIsValid};
                file_incomeSourceIsValid.status = false;
                file_incomeSourceIsValid.focused = true;
                file_incomeSourceIsValid.message = 'This is a required field';
                this.setState({file_incomeSourceIsValid:file_incomeSourceIsValid});
            }else{
                this.setState({fileIsSelected: true});
                const fileValidationResult = this.handleFileUploadValidation('doc',value);
                if(fileValidationResult.status){
                    let file_incomeSourceIsValid = {...this.state.file_incomeSourceIsValid};
                    file_incomeSourceIsValid.status = true;
                    file_incomeSourceIsValid.focused = true;
                    file_incomeSourceIsValid.message = '';
                    this.setState({file_incomeSourceIsValid:file_incomeSourceIsValid,uploadedFileInfo:fileValidationResult});
                }else{
                    let file_incomeSourceIsValid = {...this.state.file_incomeSourceIsValid};
                    file_incomeSourceIsValid.status = false;
                    file_incomeSourceIsValid.focused = false;
                    file_incomeSourceIsValid.message = '';
                    this.setState({
                        file_incomeSource: null,
                        file_incomeSource_src: '',
                        uploadedFileInfo:fileValidationResult,
                        file_incomeSourceIsValid: file_incomeSourceIsValid
                    });
                }
            }   
        }else if(prop === "file_applicantSignature"){
            if(!value){
                let file_applicantSignatureIsValid = {...this.state.file_applicantSignatureIsValid};
                file_applicantSignatureIsValid.status = false;
                file_applicantSignatureIsValid.focused = true;
                file_applicantSignatureIsValid.message = 'This is a required field';
                this.setState({file_applicantSignatureIsValid:file_applicantSignatureIsValid});
            }else{
                this.setState({fileIsSelected: true});
                const fileValidationResult = this.handleFileUploadValidation('img',value);
                if(fileValidationResult.status){
                    let file_applicantSignatureIsValid = {...this.state.file_applicantSignatureIsValid};
                    file_applicantSignatureIsValid.status = true;
                    file_applicantSignatureIsValid.focused = true;
                    file_applicantSignatureIsValid.message = '';
                    this.setState({file_applicantSignatureIsValid:file_applicantSignatureIsValid,uploadedFileInfo:fileValidationResult});
                }else{
                    let file_applicantSignatureIsValid = {...this.state.file_applicantSignatureIsValid};
                    file_applicantSignatureIsValid.status = false;
                    file_applicantSignatureIsValid.focused = false;
                    file_applicantSignatureIsValid.message = '';
                    this.setState({
                        file_applicantSignature: null,
                        file_applicantSignature_src: '',
                        uploadedFileInfo:fileValidationResult,
                        file_applicantSignatureIsValid: file_applicantSignatureIsValid
                    });
                }    
            }   
        }else if(prop === "file_IPmembershipCert"){
            if(!value){
                let file_IPmembershipCertIsValid = {...this.state.file_IPmembershipCertIsValid};
                file_IPmembershipCertIsValid.status = false;
                file_IPmembershipCertIsValid.focused = true;
                file_IPmembershipCertIsValid.message = 'This is a required field';
                this.setState({file_IPmembershipCertIsValid:file_IPmembershipCertIsValid});
            }else{
                this.setState({fileIsSelected: true});
                const fileValidationResult = this.handleFileUploadValidation('doc',value);
                if(fileValidationResult.status){
                    let file_IPmembershipCertIsValid = {...this.state.file_IPmembershipCertIsValid};
                    file_IPmembershipCertIsValid.status = true;
                    file_IPmembershipCertIsValid.focused = true;
                    file_IPmembershipCertIsValid.message = '';
                    this.setState({file_IPmembershipCertIsValid:file_IPmembershipCertIsValid,uploadedFileInfo:fileValidationResult});
                }else{
                    let file_IPmembershipCertIsValid = {...this.state.file_IPmembershipCertIsValid};
                    file_IPmembershipCertIsValid.status = false;
                    file_IPmembershipCertIsValid.focused = false;
                    file_IPmembershipCertIsValid.message = '';
                    this.setState({
                        file_IPmembershipCert: null,
                        file_IPmembershipCert_src: '',
                        uploadedFileInfo:fileValidationResult,
                        file_IPmembershipCertIsValid: file_IPmembershipCertIsValid
                    });
                }    
            }   
        }else if(prop === "track"){
            if(value === ""){
                let trackIsValid = {...this.state.trackIsValid};
                trackIsValid.status = false;
                trackIsValid.focused = true;
                trackIsValid.message = 'This is a required field';
                this.setState({trackIsValid:trackIsValid});
            }else{
                let trackIsValid = {...this.state.trackIsValid};
                trackIsValid.status = true;
                trackIsValid.focused = true;
                trackIsValid.message = '';
                this.setState({trackIsValid:trackIsValid});
            }   
        }else if(prop === "strand"){
            if(value === ""){
                let strandIsValid = {...this.state.strandIsValid};
                strandIsValid.status = false;
                strandIsValid.focused = true;
                strandIsValid.message = 'This is a required field';
                this.setState({strandIsValid:strandIsValid});
            }else{
                let strandIsValid = {...this.state.strandIsValid};
                strandIsValid.status = true;
                strandIsValid.focused = true;
                strandIsValid.message = '';
                this.setState({strandIsValid:strandIsValid});
            }   
        }else if(prop === "elemSchool"){
            if(value === ""){
                let elemSchoolIsValid = {...this.state.elemSchoolIsValid};
                elemSchoolIsValid.status = false;
                elemSchoolIsValid.focused = true;
                elemSchoolIsValid.message = 'This is a required field';
                this.setState({elemSchoolIsValid:elemSchoolIsValid});
            }else if(!this.isNameValid(value)){
                let elemSchoolIsValid = {...this.state.elemSchoolIsValid};
                elemSchoolIsValid.status = false;
                elemSchoolIsValid.focused = true;
                elemSchoolIsValid.message = 'Please enter a valid name';
                this.setState({elemSchoolIsValid:elemSchoolIsValid});
            }else{
                let elemSchoolIsValid = {...this.state.elemSchoolIsValid};
                elemSchoolIsValid.status = true;
                elemSchoolIsValid.focused = true;
                elemSchoolIsValid.message = '';
                this.setState({elemSchoolIsValid:elemSchoolIsValid});
            }   
        }else if(prop === "elemSchoolType"){
            if(value === ""){
                let elemSchoolTypeIsValid = {...this.state.elemSchoolTypeIsValid};
                elemSchoolTypeIsValid.status = false;
                elemSchoolTypeIsValid.focused = true;
                elemSchoolTypeIsValid.message = 'This is a required field';
                this.setState({elemSchoolTypeIsValid:elemSchoolTypeIsValid});
            }else{
                let elemSchoolTypeIsValid = {...this.state.elemSchoolTypeIsValid};
                elemSchoolTypeIsValid.status = true;
                elemSchoolTypeIsValid.focused = true;
                elemSchoolTypeIsValid.message = '';
                this.setState({elemSchoolTypeIsValid:elemSchoolTypeIsValid});
            }   
        }else if(prop === "elemYearGraduated"){
            if(value === ""){
                let elemYearGraduatedIsValid = {...this.state.elemYearGraduatedIsValid};
                elemYearGraduatedIsValid.status = false;
                elemYearGraduatedIsValid.focused = true;
                elemYearGraduatedIsValid.message = 'This is a required field';
                this.setState({elemYearGraduatedIsValid:elemYearGraduatedIsValid});
            }else{
                let elemYearGraduatedIsValid = {...this.state.elemYearGraduatedIsValid};
                elemYearGraduatedIsValid.status = true;
                elemYearGraduatedIsValid.focused = true;
                elemYearGraduatedIsValid.message = '';
                this.setState({elemYearGraduatedIsValid:elemYearGraduatedIsValid});
            }   
        }else if(prop === "elemAddress"){
            if(value === ""){
                let elemAddressIsValid = {...this.state.elemAddressIsValid};
                elemAddressIsValid.status = false;
                elemAddressIsValid.focused = true;
                elemAddressIsValid.message = 'This is a required field';
                this.setState({elemAddressIsValid:elemAddressIsValid});
            }else if(!this.isAddressValid(value)){
                let elemAddressIsValid = {...this.state.elemAddressIsValid};
                elemAddressIsValid.status = false;
                elemAddressIsValid.focused = true;
                elemAddressIsValid.message = 'Please enter a valid address';
                this.setState({elemAddressIsValid:elemAddressIsValid});
            }else{
                let elemAddressIsValid = {...this.state.elemAddressIsValid};
                elemAddressIsValid.status = true;
                elemAddressIsValid.focused = true;
                elemAddressIsValid.message = '';
                this.setState({elemAddressIsValid:elemAddressIsValid});
            }   
        }else if(prop === "jhSchool"){
            if(value === ""){
                let jhSchoolIsValid = {...this.state.jhSchoolIsValid};
                jhSchoolIsValid.status = false;
                jhSchoolIsValid.focused = true;
                jhSchoolIsValid.message = 'This is a required field';
                this.setState({jhSchoolIsValid:jhSchoolIsValid});
            }else if(!this.isNameValid(value)){
                let jhSchoolIsValid = {...this.state.jhSchoolIsValid};
                jhSchoolIsValid.status = false;
                jhSchoolIsValid.focused = true;
                jhSchoolIsValid.message = 'Please enter a valid name';
                this.setState({jhSchoolIsValid:jhSchoolIsValid});
            }else{
                let jhSchoolIsValid = {...this.state.jhSchoolIsValid};
                jhSchoolIsValid.status = true;
                jhSchoolIsValid.focused = true;
                jhSchoolIsValid.message = '';
                this.setState({jhSchoolIsValid:jhSchoolIsValid});
            }   
        }else if(prop === "jhSchoolType"){
            if(value === ""){
                let jhSchoolTypeIsValid = {...this.state.jhSchoolTypeIsValid};
                jhSchoolTypeIsValid.status = false;
                jhSchoolTypeIsValid.focused = true;
                jhSchoolTypeIsValid.message = 'This is a required field';
                this.setState({jhSchoolTypeIsValid:jhSchoolTypeIsValid});
            }else{
                let jhSchoolTypeIsValid = {...this.state.jhSchoolTypeIsValid};
                jhSchoolTypeIsValid.status = true;
                jhSchoolTypeIsValid.focused = true;
                jhSchoolTypeIsValid.message = '';
                this.setState({jhSchoolTypeIsValid:jhSchoolTypeIsValid});
            }   
        }else if(prop === "jhYearGraduated"){
            if(value === ""){
                let jhYearGraduatedIsValid = {...this.state.jhYearGraduatedIsValid};
                jhYearGraduatedIsValid.status = false;
                jhYearGraduatedIsValid.focused = true;
                jhYearGraduatedIsValid.message = 'This is a required field';
                this.setState({jhYearGraduatedIsValid:jhYearGraduatedIsValid});
            }else{
                let jhYearGraduatedIsValid = {...this.state.jhYearGraduatedIsValid};
                jhYearGraduatedIsValid.status = true;
                jhYearGraduatedIsValid.focused = true;
                jhYearGraduatedIsValid.message = '';
                this.setState({jhYearGraduatedIsValid:jhYearGraduatedIsValid});
            }   
        }else if(prop === "jhAddress"){
            if(value === ""){
                let jhAddressIsValid = {...this.state.jhAddressIsValid};
                jhAddressIsValid.status = false;
                jhAddressIsValid.focused = true;
                jhAddressIsValid.message = 'This is a required field';
                this.setState({jhAddressIsValid:jhAddressIsValid});
            }else if(!this.isAddressValid(value)){
                let jhAddressIsValid = {...this.state.jhAddressIsValid};
                jhAddressIsValid.status = false;
                jhAddressIsValid.focused = true;
                jhAddressIsValid.message = 'Please enter a valid address';
                this.setState({jhAddressIsValid:jhAddressIsValid});
            }else{
                let jhAddressIsValid = {...this.state.jhAddressIsValid};
                jhAddressIsValid.status = true;
                jhAddressIsValid.focused = true;
                jhAddressIsValid.message = '';
                this.setState({jhAddressIsValid:jhAddressIsValid});
            }   
        }else if(prop === "shSchool"){
            if(value === ""){
                let shSchoolIsValid = {...this.state.shSchoolIsValid};
                shSchoolIsValid.status = false;
                shSchoolIsValid.focused = true;
                shSchoolIsValid.message = 'This is a required field';
                this.setState({shSchoolIsValid:shSchoolIsValid});
            }else if(!this.isNameValid(value)){
                let shSchoolIsValid = {...this.state.shSchoolIsValid};
                shSchoolIsValid.status = false;
                shSchoolIsValid.focused = true;
                shSchoolIsValid.message = 'Please enter a valid name';
                this.setState({shSchoolIsValid:shSchoolIsValid});
            }else{
                let shSchoolIsValid = {...this.state.shSchoolIsValid};
                shSchoolIsValid.status = true;
                shSchoolIsValid.focused = true;
                shSchoolIsValid.message = '';
                this.setState({shSchoolIsValid:shSchoolIsValid});
            }   
        }else if(prop === "shSchoolType"){
            if(value === ""){
                let shSchoolTypeIsValid = {...this.state.shSchoolTypeIsValid};
                shSchoolTypeIsValid.status = false;
                shSchoolTypeIsValid.focused = true;
                shSchoolTypeIsValid.message = 'This is a required field';
                this.setState({shSchoolTypeIsValid:shSchoolTypeIsValid});
            }else{
                let shSchoolTypeIsValid = {...this.state.shSchoolTypeIsValid};
                shSchoolTypeIsValid.status = true;
                shSchoolTypeIsValid.focused = true;
                shSchoolTypeIsValid.message = '';
                this.setState({shSchoolTypeIsValid:shSchoolTypeIsValid});
            }   
        }else if(prop === "shYearGraduated"){
            if(value === ""){
                let shYearGraduatedIsValid = {...this.state.shYearGraduatedIsValid};
                shYearGraduatedIsValid.status = false;
                shYearGraduatedIsValid.focused = true;
                shYearGraduatedIsValid.message = 'This is a required field';
                this.setState({shYearGraduatedIsValid:shYearGraduatedIsValid});
            }else{
                let shYearGraduatedIsValid = {...this.state.shYearGraduatedIsValid};
                shYearGraduatedIsValid.status = true;
                shYearGraduatedIsValid.focused = true;
                shYearGraduatedIsValid.message = '';
                this.setState({shYearGraduatedIsValid:shYearGraduatedIsValid});
            }   
        }else if(prop === "shAddress"){
            if(value === ""){
                let shAddressIsValid = {...this.state.shAddressIsValid};
                shAddressIsValid.status = false;
                shAddressIsValid.focused = true;
                shAddressIsValid.message = 'This is a required field';
                this.setState({shAddressIsValid:shAddressIsValid});
            }else if(!this.isAddressValid(value)){
                let shAddressIsValid = {...this.state.shAddressIsValid};
                shAddressIsValid.status = false;
                shAddressIsValid.focused = true;
                shAddressIsValid.message = 'Please enter a valid address';
                this.setState({shAddressIsValid:shAddressIsValid});
            }else{
                let shAddressIsValid = {...this.state.shAddressIsValid};
                shAddressIsValid.status = true;
                shAddressIsValid.focused = true;
                shAddressIsValid.message = '';
                this.setState({shAddressIsValid:shAddressIsValid});
            }   
        }else if(prop === "collegeSchool"){
            if(value === ""){
                let collegeSchoolIsValid = {...this.state.collegeSchoolIsValid};
                collegeSchoolIsValid.status = false;
                collegeSchoolIsValid.focused = true;
                collegeSchoolIsValid.message = 'This is a required field';
                this.setState({collegeSchoolIsValid:collegeSchoolIsValid});
            }else if(!this.isNameValid(value)){
                let collegeSchoolIsValid = {...this.state.collegeSchoolIsValid};
                collegeSchoolIsValid.status = false;
                collegeSchoolIsValid.focused = true;
                collegeSchoolIsValid.message = 'Please enter a valid name';
                this.setState({collegeSchoolIsValid:collegeSchoolIsValid});
            }else{
                let collegeSchoolIsValid = {...this.state.collegeSchoolIsValid};
                collegeSchoolIsValid.status = true;
                collegeSchoolIsValid.focused = true;
                collegeSchoolIsValid.message = '';
                this.setState({collegeSchoolIsValid:collegeSchoolIsValid});
            }   
        }else if(prop === "collegeSchoolType"){
            if(value === ""){
                let collegeSchoolTypeIsValid = {...this.state.collegeSchoolTypeIsValid};
                collegeSchoolTypeIsValid.status = false;
                collegeSchoolTypeIsValid.focused = true;
                collegeSchoolTypeIsValid.message = 'This is a required field';
                this.setState({collegeSchoolTypeIsValid:collegeSchoolTypeIsValid});
            }else{
                let collegeSchoolTypeIsValid = {...this.state.collegeSchoolTypeIsValid};
                collegeSchoolTypeIsValid.status = true;
                collegeSchoolTypeIsValid.focused = true;
                collegeSchoolTypeIsValid.message = '';
                this.setState({collegeSchoolTypeIsValid:collegeSchoolTypeIsValid});
            }   
        }else if(prop === "collegeYearGraduated"){
            if(value === ""){
                let collegeYearGraduatedIsValid = {...this.state.collegeYearGraduatedIsValid};
                collegeYearGraduatedIsValid.status = false;
                collegeYearGraduatedIsValid.focused = true;
                collegeYearGraduatedIsValid.message = 'This is a required field';
                this.setState({collegeYearGraduatedIsValid:collegeYearGraduatedIsValid});
            }else{
                let collegeYearGraduatedIsValid = {...this.state.collegeYearGraduatedIsValid};
                collegeYearGraduatedIsValid.status = true;
                collegeYearGraduatedIsValid.focused = true;
                collegeYearGraduatedIsValid.message = '';
                this.setState({collegeYearGraduatedIsValid:collegeYearGraduatedIsValid});
            }   
        }else if(prop === "collegeAddress"){
            if(value === ""){
                let collegeAddressIsValid = {...this.state.collegeAddressIsValid};
                collegeAddressIsValid.status = false;
                collegeAddressIsValid.focused = true;
                collegeAddressIsValid.message = 'This is a required field';
                this.setState({collegeAddressIsValid:collegeAddressIsValid});
            }else if(!this.isAddressValid(value)){
                let collegeAddressIsValid = {...this.state.collegeAddressIsValid};
                collegeAddressIsValid.status = false;
                collegeAddressIsValid.focused = true;
                collegeAddressIsValid.message = 'Please enter a valid address';
                this.setState({collegeAddressIsValid:collegeAddressIsValid});
            }else{
                let collegeAddressIsValid = {...this.state.collegeAddressIsValid};
                collegeAddressIsValid.status = true;
                collegeAddressIsValid.focused = true;
                collegeAddressIsValid.message = '';
                this.setState({collegeAddressIsValid:collegeAddressIsValid});
            }   
        }else if(prop === "emergencyContactName"){
            if(value === ""){
                let emergencyContactNameIsValid = {...this.state.emergencyContactNameIsValid};
                emergencyContactNameIsValid.status = false;
                emergencyContactNameIsValid.focused = true;
                emergencyContactNameIsValid.message = 'This is a required field';
                this.setState({emergencyContactNameIsValid:emergencyContactNameIsValid});
            }else if(!this.isNameValid(value)){
                let emergencyContactNameIsValid = {...this.state.emergencyContactNameIsValid};
                emergencyContactNameIsValid.status = false;
                emergencyContactNameIsValid.focused = true;
                emergencyContactNameIsValid.message = 'Please enter a valid name';
                this.setState({emergencyContactNameIsValid:emergencyContactNameIsValid});
            }else{
                let emergencyContactNameIsValid = {...this.state.emergencyContactNameIsValid};
                emergencyContactNameIsValid.status = true;
                emergencyContactNameIsValid.focused = true;
                emergencyContactNameIsValid.message = '';
                this.setState({emergencyContactNameIsValid:emergencyContactNameIsValid});
            }   
        }else if(prop === "emergencyContactRelation"){
            if(value === ""){
                let emergencyContactRelationIsValid = {...this.state.emergencyContactRelationIsValid};
                emergencyContactRelationIsValid.status = false;
                emergencyContactRelationIsValid.focused = true;
                emergencyContactRelationIsValid.message = 'This is a required field';
                this.setState({emergencyContactRelationIsValid:emergencyContactRelationIsValid});
            }else if(!this.isNameValid(value)){
                let emergencyContactRelationIsValid = {...this.state.emergencyContactRelationIsValid};
                emergencyContactRelationIsValid.status = false;
                emergencyContactRelationIsValid.focused = true;
                emergencyContactRelationIsValid.message = 'Please enter valid characters only';
                this.setState({emergencyContactRelationIsValid:emergencyContactRelationIsValid});
            }else{
                let emergencyContactRelationIsValid = {...this.state.emergencyContactRelationIsValid};
                emergencyContactRelationIsValid.status = true;
                emergencyContactRelationIsValid.focused = true;
                emergencyContactRelationIsValid.message = '';
                this.setState({emergencyContactRelationIsValid:emergencyContactRelationIsValid});
            }   
        }else if(prop === "emergencyContactAddress"){
            if(value === ""){
                let emergencyContactAddressIsValid = {...this.state.emergencyContactAddressIsValid};
                emergencyContactAddressIsValid.status = false;
                emergencyContactAddressIsValid.focused = true;
                emergencyContactAddressIsValid.message = 'This is a required field';
                this.setState({emergencyContactAddressIsValid:emergencyContactAddressIsValid});
            }else if(!this.isAddressValid(value)){
                let emergencyContactAddressIsValid = {...this.state.emergencyContactAddressIsValid};
                emergencyContactAddressIsValid.status = false;
                emergencyContactAddressIsValid.focused = true;
                emergencyContactAddressIsValid.message = 'Please enter a valid address';
                this.setState({emergencyContactAddressIsValid:emergencyContactAddressIsValid});
            }else{
                let emergencyContactAddressIsValid = {...this.state.emergencyContactAddressIsValid};
                emergencyContactAddressIsValid.status = true;
                emergencyContactAddressIsValid.focused = true;
                emergencyContactAddressIsValid.message = '';
                this.setState({emergencyContactAddressIsValid:emergencyContactAddressIsValid});
            }   
        }else if(prop === "emergencyContactNumber"){
            if(value === ""){
                let emergencyContactNumberIsValid = {...this.state.emergencyContactNumberIsValid};
                emergencyContactNumberIsValid.status = false;
                emergencyContactNumberIsValid.focused = true;
                emergencyContactNumberIsValid.message = 'This is a required field';
                this.setState({emergencyContactNumberIsValid:emergencyContactNumberIsValid});
            }else if(!this.isContactNumberValid(value)){
                let emergencyContactNumberIsValid = {...this.state.emergencyContactNumberIsValid};
                emergencyContactNumberIsValid.status = false;
                emergencyContactNumberIsValid.focused = true;
                emergencyContactNumberIsValid.message = 'Please enter a valid 11-digit contact number';
                this.setState({emergencyContactNumberIsValid:emergencyContactNumberIsValid});
            }else{
                let emergencyContactNumberIsValid = {...this.state.emergencyContactNumberIsValid};
                emergencyContactNumberIsValid.status = true;
                emergencyContactNumberIsValid.focused = true;
                emergencyContactNumberIsValid.message = '';
                this.setState({emergencyContactNumberIsValid:emergencyContactNumberIsValid});
            }   
        }else if(prop === "campus1"){
            if(value === ""){
                let campus1IsValid = {...this.state.campus1IsValid};
                campus1IsValid.status = false;
                campus1IsValid.focused = true;
                campus1IsValid.message = 'This is a required field';
                this.setState({campus1IsValid:campus1IsValid});
            }else{
                let campus1IsValid = {...this.state.campus1IsValid};
                campus1IsValid.status = true;
                campus1IsValid.focused = true;
                campus1IsValid.message = '';
                this.setState({campus1IsValid:campus1IsValid});
            }   
        }else if(prop === "campus2"){
            if(value === ""){
                let campus2IsValid = {...this.state.campus2IsValid};
                campus2IsValid.status = false;
                campus2IsValid.focused = true;
                campus2IsValid.message = 'This is a required field';
                this.setState({campus2IsValid:campus2IsValid});
            }else{
                let campus2IsValid = {...this.state.campus2IsValid};
                campus2IsValid.status = true;
                campus2IsValid.focused = true;
                campus2IsValid.message = '';
                this.setState({campus2IsValid:campus2IsValid});
            }   
        }else if(prop === "campus3"){
            if(value === ""){
                let campus3IsValid = {...this.state.campus3IsValid};
                campus3IsValid.status = false;
                campus3IsValid.focused = true;
                campus3IsValid.message = 'This is a required field';
                this.setState({campus3IsValid:campus3IsValid});
            }else{
                let campus3IsValid = {...this.state.campus3IsValid};
                campus3IsValid.status = true;
                campus3IsValid.focused = true;
                campus3IsValid.message = '';
                this.setState({campus3IsValid:campus3IsValid});
            }   
        }else if(prop === "course1"){
            if(value === ""){
                let course1IsValid = {...this.state.course1IsValid};
                course1IsValid.status = false;
                course1IsValid.focused = true;
                course1IsValid.message = 'This is a required field';
                this.setState({course1IsValid:course1IsValid});
            }else{
                let course1IsValid = {...this.state.course1IsValid};
                course1IsValid.status = true;
                course1IsValid.focused = true;
                course1IsValid.message = '';
                this.setState({course1IsValid:course1IsValid});
            }   
        }else if(prop === "course2"){
            if(value === ""){
                let course2IsValid = {...this.state.course2IsValid};
                course2IsValid.status = false;
                course2IsValid.focused = true;
                course2IsValid.message = 'This is a required field';
                this.setState({course2IsValid:course2IsValid});
            }else{
                let course2IsValid = {...this.state.course2IsValid};
                course2IsValid.status = true;
                course2IsValid.focused = true;
                course2IsValid.message = '';
                this.setState({course2IsValid:course2IsValid});
            }   
        }else if(prop === "course3"){
            if(value === ""){
                let course3IsValid = {...this.state.course3IsValid};
                course3IsValid.status = false;
                course3IsValid.focused = true;
                course3IsValid.message = 'This is a required field';
                this.setState({course3IsValid:course3IsValid});
            }else{
                let course3IsValid = {...this.state.course3IsValid};
                course3IsValid.status = true;
                course3IsValid.focused = true;
                course3IsValid.message = '';
                this.setState({course3IsValid:course3IsValid});
            }   
        }else if(prop === "major1"){
            if(value === ""){
                const courseMajors =  this.state.courseMajorOptions.filter( (course) => {
                    return course.ProgID == this.state.course1;
                });
            
                if(courseMajors.length >= 1){
                    let major1IsValid = {...this.state.major1IsValid};
                    major1IsValid.status = false;
                    major1IsValid.focused = true;
                    major1IsValid.message = 'This is a required field';
                    this.setState({major1IsValid:major1IsValid});
                }else{
                    let major1IsValid = {...this.state.major1IsValid};
                    major1IsValid.status = true;
                    major1IsValid.focused = true;
                    major1IsValid.message = '';
                    this.setState({major1IsValid:major1IsValid});
                }
                
            }else{
                let major1IsValid = {...this.state.major1IsValid};
                major1IsValid.status = true;
                major1IsValid.focused = true;
                major1IsValid.message = '';
                this.setState({major1IsValid:major1IsValid});
            }   
        }else if(prop === "major2"){
            if(value === ""){
                const courseMajors =  this.state.courseMajorOptions.filter( (course) => {
                    return course.ProgID == this.state.course2;
                });
            
                if(courseMajors.length >= 1){
                    let major2IsValid = {...this.state.major2IsValid};
                    major2IsValid.status = false;
                    major2IsValid.focused = true;
                    major2IsValid.message = 'This is a required field';
                    this.setState({major2IsValid:major2IsValid});
                }else{
                    let major2IsValid = {...this.state.major2IsValid};
                    major2IsValid.status = true;
                    major2IsValid.focused = true;
                    major2IsValid.message = '';
                    this.setState({major2IsValid:major2IsValid});
                }
            }else{
                let major2IsValid = {...this.state.major2IsValid};
                major2IsValid.status = true;
                major2IsValid.focused = true;
                major2IsValid.message = '';
                this.setState({major2IsValid:major2IsValid});
            }   
        }else if(prop === "major3"){
            if(value === ""){
                const courseMajors =  this.state.courseMajorOptions.filter( (course) => {
                    return course.ProgID == this.state.course3;
                });
            
                if(courseMajors.length >= 1){
                    let major3IsValid = {...this.state.major3IsValid};
                    major3IsValid.status = false;
                    major3IsValid.focused = true;
                    major3IsValid.message = 'This is a required field';
                    this.setState({major3IsValid:major3IsValid});
                }else{
                    let major3IsValid = {...this.state.major3IsValid};
                    major3IsValid.status = true;
                    major3IsValid.focused = true;
                    major3IsValid.message = '';
                    this.setState({major3IsValid:major3IsValid});
                }
            }else{
                let major3IsValid = {...this.state.major3IsValid};
                major3IsValid.status = true;
                major3IsValid.focused = true;
                major3IsValid.message = '';
                this.setState({major3IsValid:major3IsValid});
            }   
        }else if(prop === "pwdId"){
            if(value === ""){
                let pwdIdIsValid = {...this.state.pwdIdIsValid};
                pwdIdIsValid.status = false;
                pwdIdIsValid.focused = true;
                pwdIdIsValid.message = 'This is a required field';
                this.setState({pwdIdIsValid:pwdIdIsValid});
            }else{
                let pwdIdIsValid = {...this.state.pwdIdIsValid};
                pwdIdIsValid.status = true;
                pwdIdIsValid.focused = true;
                pwdIdIsValid.message = '';
                this.setState({pwdIdIsValid:pwdIdIsValid});
            }   
        }else if(prop === "connectivity"){
            if(value === ""){
                let connectivityIsValid = {...this.state.connectivityIsValid};
                connectivityIsValid.status = false;
                connectivityIsValid.focused = true;
                connectivityIsValid.message = 'This is a required field';
                this.setState({connectivityIsValid:connectivityIsValid});
            }else{
                let connectivityIsValid = {...this.state.connectivityIsValid};
                connectivityIsValid.status = true;
                connectivityIsValid.focused = true;
                connectivityIsValid.message = '';
                this.setState({connectivityIsValid:connectivityIsValid});
            }   
        }else if(prop === "specialNeed"){
            if(value === ""){
                let specialNeedIsValid = {...this.state.specialNeedIsValid};
                specialNeedIsValid.status = false;
                specialNeedIsValid.focused = true;
                specialNeedIsValid.message = 'This is a required field';
                this.setState({specialNeedIsValid:specialNeedIsValid});
            }else{
                let specialNeedIsValid = {...this.state.specialNeedIsValid};
                specialNeedIsValid.status = true;
                specialNeedIsValid.focused = true;
                specialNeedIsValid.message = '';
                this.setState({specialNeedIsValid:specialNeedIsValid});
            }   
        }else if(prop === "tribe"){
            if(value === ""){
                let tribeIsValid = {...this.state.tribeIsValid};
                tribeIsValid.status = false;
                tribeIsValid.focused = true;
                tribeIsValid.message = 'This is a required field';
                this.setState({tribeIsValid:tribeIsValid});
            }else{
                let tribeIsValid = {...this.state.tribeIsValid};
                tribeIsValid.status = true;
                tribeIsValid.focused = true;
                tribeIsValid.message = '';
                this.setState({tribeIsValid:tribeIsValid});
            }   
        }

    }

    getFullCurrentAddress = () => {
        let fullAddress = `${this.state.presentAddressStreet}, ${this.state.presentAddressBrgy},
                    ${this.state.presentAddressCity}, ${this.state.presentAddressProvince}, 
                    ${this.state.presentAddressRegion}`;
            return fullAddress;
    }

    getFullPermAddress = () => {
        let fullAddress = `${this.state.permanentAddressStreet}, ${this.state.permanentAddressBrgy},
                    ${this.state.permanentAddressCity}, ${this.state.permanentAddressProvince}, 
                    ${this.state.permanentAddressRegion}`;
            return fullAddress;
    }

    getFullFatherName = () => {
        let fullName = `${this.state.fLastName}, ${this.state.fFirstName}
                    ${this.state.fMiddleName} ${this.state.fSuffix}`;
            return fullName;
    }

    getFullMotherName = () => {
        let fullName = `${this.state.mLastName}, ${this.state.mFirstName}
                    ${this.state.mMiddleName}`;
            return fullName;
    }

    // Photo Cropper Event Handlers
    handlePassportPhotoChange = (e) => {
        if(e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            
            this.setState({fileIsSelected: true}, () => {
                const allowedExtensions = ['jpg','png'];
                const sizeLimit = 2000000;
                const MB_UNIT = 1000000;

                const { name:fileName, size:fileSize } = file;
                const fileExtension = fileName.split(".").pop();

                if(!allowedExtensions.includes(fileExtension)){
                    const uploadedFileInfo = {...this.state.uploadedFileInfo};
                    uploadedFileInfo.status = false;
                    uploadedFileInfo.title = 'Invalid File Type';
                    uploadedFileInfo.message = 'Please upload a valid JPG/PNG image file.'
                    this.setState({uploadedFileInfo: uploadedFileInfo})
                
                }else if(fileSize > sizeLimit){
                    const uploadedFileInfo = {...this.state.uploadedFileInfo};
                    uploadedFileInfo.status = false;
                    uploadedFileInfo.title = 'File Too Large';
                    uploadedFileInfo.message = `Please upload a file not exceeding ${sizeLimit/MB_UNIT}MB`;
                    this.setState({uploadedFileInfo: uploadedFileInfo})
                }else{
                    const uploadedFileInfo = {...this.state.uploadedFileInfo};
                    uploadedFileInfo.status = true;
                    uploadedFileInfo.title = 'Successful File Upload ';
                    uploadedFileInfo.message = 'The selected file has been uploaded.'
                    this.setState({uploadedFileInfo: uploadedFileInfo, file_passportPhoto: file})
                    this.setState({file_passportPhoto: file})
                    // this.setState({file_tempPhoto: file})
                    this.handleValidation('file_passportPhoto',file);
                
                    
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        this.setState({file_passportPhotoImgSrc: e.target.result});
                    }
                    reader.readAsDataURL(file);
                }

            });
    
        }
    }

    handlePassportPhotoSave = (photoBlobURL,photoBlob) => { 
        this.setState({
            file_passportPhotoImgSrc: photoBlobURL,
            file_passportPhoto: photoBlob,
            currDiplayedImgSrc: photoBlobURL,
            choosePassportPhotoClicked: false,
            isReviewingPhoto: false
        });
    }

    handlePhotoReview = () => {
        this.setState({isReviewingPhoto: true});
    }
    
    handlePhotoReviewClose = () => {
        this.setState({isReviewingPhoto: false});
    }

    handleIDPhotoSave = (photoBlobURL,photoBlob) => {
        this.setState({
            file_idPhotoImgSrc: photoBlobURL,
            file_idPhoto: photoBlob,
            chooseIDPhotoClicked: false,
            isReviewingPhoto: false
        });   
    }

    handleIDPhotoChange = (e) => {
        if(e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];

            this.setState({fileIsSelected: true}, () => {
                const allowedExtensions = ['jpg','png'];
                const sizeLimit = 2000000;
                const MB_UNIT = 1000000;

                const { name:fileName, size:fileSize } = file;
                const fileExtension = fileName.split(".").pop();

                if(!allowedExtensions.includes(fileExtension)){
                    const uploadedFileInfo = {...this.state.uploadedFileInfo};
                    uploadedFileInfo.status = false;
                    uploadedFileInfo.title = 'Invalid File Type';
                    uploadedFileInfo.message = 'Please upload a valid JPG/PNG image file.'
                    this.setState({uploadedFileInfo: uploadedFileInfo})
                
                }else if(fileSize > sizeLimit){
                    const uploadedFileInfo = {...this.state.uploadedFileInfo};
                    uploadedFileInfo.status = false;
                    uploadedFileInfo.title = 'File Too Large';
                    uploadedFileInfo.message = `Please upload a file not exceeding ${sizeLimit/MB_UNIT}MB`;
                    this.setState({uploadedFileInfo: uploadedFileInfo})
                }else{
                    const uploadedFileInfo = {...this.state.uploadedFileInfo};
                    uploadedFileInfo.status = true;
                    uploadedFileInfo.title = 'Successful File Upload ';
                    uploadedFileInfo.message = 'The selected file has been uploaded.'
                    this.setState({uploadedFileInfo: uploadedFileInfo, file_idPhoto: file})
                    this.handleValidation('file_idPhoto',file);
                
                    
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        this.setState({file_idPhotoImgSrc: e.target.result});
                    }
                    reader.readAsDataURL(file);
                }

            });
            
        }
    }   

    handleSaveSelectedPhotos = () => {
        let currDisplayImgSrc = this.state.file_passportPhotoImgSrc ? this.state.file_passportPhotoImgSrc : null;
        this.setState({
            currDiplayedImgSrc: currDisplayImgSrc,
            choosePhotoClicked: false,
        });
    }

    handleCloseChoosePhoto = () => {
        this.setState({choosePhotoClicked: false})
    }
    // End of Photo Cropper Event Handlers


    // Check if duplicate applicant
    checkDuplicateApplicant = () => {
        // if(this.state.lastName && this.state.firstName && this.state.middleName){
            const applicantName = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                middleName: this.state.middleName
            };
            const isDuplicate = false;

            axios.post(`${API_URL}/admission/checkDuplicateApplicant`, applicantName)
                .then( (response) => {
                    if(response.data.status){
                        
                        this.setState({
                            isDuplicateApplicant:true,
                            showDuplicateApplicantNotice:true,
        
                        });
                    }else{
                        this.setState({
                            isDuplicateApplicant:false,
                            showDuplicateApplicantNotice:false,
                        })
                    }
                });
            
        // }else if(this.state.isDuplicateApplicant && (this.state.lastName || this.state.firstName || this.state.middleName)){
        //     this.setState({
        //         isDuplicateApplicant:false,
        //         showDuplicateApplicantNotice:false,
        //     })
        // }
        
    }

    getNationalityList = () => {
        axios.get(`${API_URL}/admission/getNationalityList`)
            .then( (response) => {
                this.setState({nationalities: response.data});
            });
    }

    getProgramList = () => {
        axios.get(`${API_URL}/admission/getCoursesOffered`)
            .then( (response) => {
                this.setState({coursesOptions: response.data});
                // coursesOptions = {...response.data};
                // 
            });
    }

    getMajorList = () => {
        axios.get(`${API_URL}/admission/getCourseMajorsOffered`)
            .then( (response) => {
                this.setState({courseMajorOptions: response.data});
                // coursesOptions = {...response.data};
                // 
            });
    }

    getTrackList = () => {
        axios.get(`${API_URL}/admission/getSHTracks`)
            .then( (response) => {
                this.setState({shTrackOptions: response.data});
                // coursesOptions = {...response.data};
                 
            });
    }

    getStrandList = () => {
        axios.get(`${API_URL}/admission/getSHStrands`)
            .then( (response) => {
                this.setState({shStrandOptions: response.data});
                // coursesOptions = {...response.data};
                // 
            });
    }

    getAnnualIncomeList = () => {
        axios.get(`${API_URL}/admission/getAnnualIncomeList`)
            .then( (response) => {
                this.setState({annualIncomeOptions: response.data});
                // coursesOptions = {...response.data};
                 
            });
    }

    getSingleParentTypeList = () => {
        axios.get(`${API_URL}/admission/getSingleParentTypeList`)
            .then( (response) => {
                this.setState({singleParentTypeOptions: response.data});
                 
            });
    }

    getReligionList = () => {
        axios.get(`${API_URL}/admission/getReligionList`)
            .then( (response) => {
                this.setState({religionOptions: response.data});
                 
            });
    }

    getSpecialNeedsList = () => {
        axios.get(`${API_URL}/admission/getDisabilityList`)
            .then( (response) => {
                this.setState({specialNeedOptions: response.data});
                 
            });
    }

    getTribeList = () => {
        axios.get(`${API_URL}/admission/getTribeList`)
            .then( (response) => {
                this.setState({tribeOptions: response.data});
                 
            });
    }

    checkAdmissionIsOpen = () => {
        axios.get(`${API_URL}/admission/isAdmissionOpen`)
            .then( (response) => {
                if(response.data.status){
                    this.setState({
                        isAdmissionOpen: true
                    });
                }else{
                    this.setState({
                        isAdmissionOpen: false
                    });
                }
            })
            .catch( (err) => {
                alert(`Something went wrong.`);
            })
    }   

    componentDidUpdate(){
        // if(this.state.alertOnPageLoad){
        //     window.onbeforeunload = () => true;
        // }else{
        //     window.onbeforeunload = () => false;
        // }
    }

    componentDidMount(){
         this.checkAdmissionIsOpen();
         this.getNationalityList();
         this.getProgramList();
         this.getMajorList();
         this.getTrackList();
         this.getStrandList();
         this.getAnnualIncomeList();
         this.getSingleParentTypeList();
         this.getReligionList();
         this.getSpecialNeedsList();
         this.getTribeList();

         socket = io(`${API_URL}`);
         const clientDevice = isMobile ? 'Mobile' : 'Desktop';
         this.setState({
             clientID: nanoid(),
             clientDevice: clientDevice
         }, () => {
            //  console.log(`clientID: ${this.state.clientID}`);
             socket.emit('admission:currentlyFillingOutForm',{clientID: this.state.clientID, clientDevice: this.state.clientDevice});
         });
    }

    render(){
        
        const { classes } = this.props;
        
        let nationalityList = this.state.nationalities.map( (data) => {
            return <MenuItem>{data.Nationality}</MenuItem>;
        });

        // Province List
            // Present 
        let selectedRegion = barangay_list.filter( (data) => {
            return data.region_name === this.state.presentAddressRegion;
        });

        let selectedRegionProvinceList = {};
        let selectedRegionProvinceListArr = [];

        if(this.state.presentAddressRegion !== '' && selectedRegion.length > 0){
            selectedRegionProvinceList = selectedRegion[0].province_list;
            
            for(const province in selectedRegionProvinceList){
                selectedRegionProvinceListArr.push({province_name: province});
                
            }
        }
            // Permanent 
        let selectedPermRegion = barangay_list.filter( (data) => {
            return data.region_name === this.state.permanentAddressRegion;
        });

        let selectedPermRegionProvinceList = {};
        let selectedPermRegionProvinceListArr = [];

        if(this.state.permanentAddressRegion !== '' && selectedPermRegion.length > 0){
            selectedPermRegionProvinceList = selectedPermRegion[0].province_list;
            
            for(const province in selectedPermRegionProvinceList){
                selectedPermRegionProvinceListArr.push({province_name: province});
                
            }
        }
        
        // City List

        let selectedProvince = selectedRegionProvinceListArr.filter( (data) => {
            return data.province_name === this.state.presentAddressProvince;
        });

            // Present
        let selectedRegionCityList = {};
        let selectedRegionCityListArr = [];

        if(this.state.presentAddressProvince !== '' && this.state.presentAddressRegion !== '' && selectedProvince.length > 0){
            selectedRegionCityList = selectedRegionProvinceList[selectedProvince[0].province_name].municipality_list;
            
            for(const city in selectedRegionCityList){
                selectedRegionCityListArr.push({city_name: city});
            }
        }

        let selectedPermProvince = selectedPermRegionProvinceListArr.filter( (data) => {
            return data.province_name === this.state.permanentAddressProvince;
        });

            // Permanent
        let selectedPermRegionCityList = {};
        let selectedPermRegionCityListArr = [];

        if(this.state.permanentAddressProvince !== '' && this.state.permanentAddressRegion !== '' && selectedPermProvince.length > 0){
            selectedPermRegionCityList = selectedPermRegionProvinceList[selectedPermProvince[0].province_name].municipality_list;
            
            for(const city in selectedPermRegionCityList){
                selectedPermRegionCityListArr.push({city_name: city});
            }
        }

        // Barangay List
            
            //  Present
        let selectedCity = selectedRegionCityListArr.filter( (data) => {
            return data.city_name === this.state.presentAddressCity;
        });

        
        let selectedRegionBrgyList = [];
        let selectedRegionBrgyListArr = [];

        if(this.state.presentAddressCity !== '' && this.state.presentAddressProvince !== '' && selectedCity.length > 0){
            selectedRegionBrgyList = selectedRegionCityList[selectedCity[0].city_name].barangay_list;
            
            selectedRegionBrgyListArr = selectedRegionBrgyList.map( (data) => {
                return {brgy_name: data}
            });

        }

            // Permanent
        let selectedPermCity = selectedPermRegionCityListArr.filter( (data) => {
            return data.city_name === this.state.permanentAddressCity;
        });

        
        let selectedPermRegionBrgyList = [];
        let selectedPermRegionBrgyListArr = [];

        if(this.state.permanentAddressCity !== '' && this.state.permanentAddressProvince !== '' && selectedPermCity.length > 0){
            selectedPermRegionBrgyList = selectedPermRegionCityList[selectedPermCity[0].city_name].barangay_list;
            
            selectedPermRegionBrgyListArr = selectedPermRegionBrgyList.map( (data) => {
                return {brgy_name: data}
            });

        }

        
        // Single Parent Options
        const singleParentTypeList =
            <React.Fragment>
                <Grid item xs={12} sm={2}>
                        <Autocomplete
                            id="singleParentType"
                            name="singleParentType"
                            options={this.state.singleParentTypeOptions}
                            getOptionLabel={(option) => option.name}
                            fullWidth     
                            onChange={(e, selectedOption) => { this.changeHandler("singleParentType",selectedOption.id) }}
                            renderInput={(params) => <TextField {...params} variant="outlined" label="Single Parent Type"  />}
                        />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Autocomplete
                        id="numberOfChildren"
                        name="numberOfChildren"
                        options={numOfChildrenOptions}
                        getOptionLabel={(option) => option.type}
                        fullWidth     
                        onChange={(e, selectedOption) => { this.changeHandler("numberOfChildren",selectedOption.value) }}
                        renderInput={(params) => <TextField {...params} variant="outlined" label="Number of Child/ren"  />}
                    />
                </Grid>
            </React.Fragment>
        
        // Disability Options
        const specialNeedsList =
            <React.Fragment>
                <Grid item xs={12} sm={6}>
                        <Autocomplete
                            id="specialNeed"
                            name="specialNeed"
                            options={this.state.specialNeedOptions}
                            getOptionLabel={(option) => option.Disability}
                            fullWidth     
                            onChange={(e, selectedOption) => { this.changeHandler("specialNeed",selectedOption ? selectedOption.DisabilityID : '') }}
                            // onBlur={(e, selectedOption) => { this.changeHandler("specialNeed",selectedOption ? selectedOption.DisabilityID : '') }}
                            renderInput={(params) => <TextField {...params}  label="Please Indicate" error={!this.state.specialNeedIsValid.status && this.state.specialNeedIsValid.focused}
                            helperText={this.state.specialNeedIsValid.message}  />}
                        />
                </Grid>
            </React.Fragment>
        
        // PWD Options
        const pwdIDOption = 
            <React.Fragment>
                <Grid item xs={12} sm={6}>
                    <FormControl component="fieldset" style={{alignItems:"center"}} fullWidth>
                        <FormLabel component="legend" align="center">Do you have a PWD ID?</FormLabel>
                        <RadioGroup 
                            row aria-label="hasPwdId" 
                            name="hasPwdId" 
                            value={this.state.hasPwdId} 
                            onChange={(e, selectedOption) => { this.changeHandler("hasPwdId",e.target.value) }}
                        >
                            <FormControlLabel value="0" control={<Radio />} label="No" />
                            <FormControlLabel value="1" control={<Radio />} label="Yes" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </React.Fragment>
        
        // PWD ID
        const pwdIDInput = 
            <React.Fragment>
                <Grid item xs={12} sm={12}>
                    <TextField 
                        id="pwdId" 
                        name="pwdId" 
                        label="PWD ID" 
                        onBlur={(e, selectedOption) => { this.changeHandler("pwdId",e.target.value) }}
                        fullWidth
                        variant="outlined"
                        error={!this.state.pwdIdIsValid.status && this.state.pwdIdIsValid.focused}
                        helperText={this.state.pwdIdIsValid.message}
                        required
                    />
                </Grid>
            </React.Fragment>

        // Vaccination Plan
        const vaccinationPlan = 
            <React.Fragment>
                <Grid item xs={12} sm={ (this.state.vaccinationStatus === '0') ? 3 : 4}>
                    <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend" align="center">Do you have plans to get vaccinated before the start of the SY 2022-2023?</FormLabel>
                        <RadioGroup 
                            aria-label="hasVaccinationPlan" 
                            name="hasVaccinationPlan" 
                            value={this.state.hasVaccinationPlan} 
                            onChange={(e, selectedOption) => { this.changeHandler("hasVaccinationPlan",e.target.value) }}
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Yes" />
                            <FormControlLabel value="0" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </React.Fragment>

        // Tribe Options
        const tribeList =
            <React.Fragment>
                <Grid item xs={12} sm={12}>
                        <Autocomplete
                            id="tribe"
                            name="tribe"
                            options={this.state.tribeOptions}
                            getOptionLabel={(option) => option.tribeName}
                            fullWidth     
                            onChange={(e, selectedOption) => { this.changeHandler("tribe", selectedOption ? selectedOption.tribeID : '') }}
                            // onBlur={(e, selectedOption) => { this.changeHandler("tribe", selectedOption ? selectedOption.tribeID : '') }}
                            renderInput={(params) => <TextField {...params}  label="Please Indicate" error={!this.state.tribeIsValid.status && this.state.tribeIsValid.focused}
                            helperText={this.state.tribeIsValid.message} />}
                        />
                </Grid>
            </React.Fragment>

        // IP Membership Cert
        const IPMembershipCertUpload = 
            <React.Fragment>
                <Grid item xs={12} sm={3}>
                        <Card>
                            <CardHeader
                                action={
                                    this.state.file_IPmembershipCertIsValid.status ?
                                    <CheckCircleIcon style={{fill:"green"}}  />
                                    : null
                                } 
                                title="Certificate of Tribe Membership"
                                subheader={this.state.file_IPmembershipCertIsValid.status ? "Uploaded" : "Required"} />
                            <CardContent>
                                <input
                                    id="file_IPmembershipCert"
                                    accept="pdf/*"
                                    type="file"
                                    onChange={(e) => { this.changeHandler("file_IPmembershipCert",e.target.files[0])}}
                                    hidden
                                />
                                <label htmlFor="file_IPmembershipCert">
                                    <Button
                                        variant="contained"
                                        component="span"
                                        
                                    >Upload PDF File</Button>
                                </label>
                            </CardContent>
                        </Card>
                    </Grid>
            </React.Fragment>

        // Form 137 File Upload
        const form137Upload = 
            <React.Fragment>
                <Grid item xs={12} sm={(this.state.isMemberOfIP ==='0') ? 4 : 3}>
                    <Card>
                        <CardHeader 
                            action={
                                this.state.file_form137IsValid.status ?
                                <CheckCircleIcon style={{fill:"green"}}  />
                                : null
                            }
                            title="Form 137"
                            subheader={this.state.file_form137IsValid.status ? "Uploaded" : 
                            <React.Fragment>
                                <Typography>Required</Typography> 
                                <Typography><span style={{fontStyle:"italic",color:"maroon"}}>Important:</span>
                                    &nbsp; Please make sure that the uploaded file reflects <span style={{fontWeight:"bold"}}>ALL</span> the Grades data entered in the 
                                    Scholastic Background section of this form. 
                                </Typography>
                            </React.Fragment> 
                        } 
                                
                            />
                        <CardContent>
                            <input
                                id="file_form137"
                                accept="pdf/*"
                                type="file"
                                onChange={(e) => { const file = e.target.files[0]; this.changeHandler("file_form137",file)}}
                                hidden
                            />
                            <label htmlFor="file_form137">
                                <Button
                                    variant="contained"
                                    component="span"
                                >Upload PDF File</Button>
                            </label>
                        </CardContent>
                    </Card>
                </Grid>
            </React.Fragment>

        // ToR File Upload
        const ToRUpload = 
            <React.Fragment>
                <Grid item xs={12} sm={(this.state.isMemberOfIP ==='0') ? 4 : 3}>
                    <Card>
                        <CardHeader 
                            action={
                                this.state.file_ToRIsValid.status ?
                                <CheckCircleIcon style={{fill:"green"}}  />
                                : null
                            }
                            title="Transcript of Records" 
                            subheader={this.state.file_ToRIsValid.status ? "Uploaded" : "Required"}
                            />
                        <CardContent>
                            <input
                                id="file_ToR"
                                accept="pdf/*"
                                type="file"
                                onChange={(e) => { this.changeHandler("file_ToR",e.target.files[0])}}
                                hidden
                            />
                            <label htmlFor="file_ToR">
                                <Button
                                    variant="contained"
                                    component="span"   
                                >Upload PDF File</Button>
                            </label>
                        </CardContent>
                    </Card>
                </Grid>
            </React.Fragment>

        // College Info for Transferee
        const collegeInfoForTransferee = 
            <React.Fragment>
                <Grid item xs={12} sm={3}>
                    <Card>
                        <CardHeader title="College" />
                        <CardContent>
                            <FormControl component="fieldset">
                                <RadioGroup 
                                    row aria-label="collegeSchoolType" 
                                    id="collegeSchoolType" 
                                    name="collegeSchoolType" 
                                    value={this.props.value} 
                                    onChange={(e, selectedOption) => { this.changeHandler("collegeSchoolType",e.target.value) }} 
                                >
                                    <FormControlLabel value="Public" control={<Radio />} label="Public" />
                                    <FormControlLabel value="Private" control={<Radio />} label="Private" />
                                </RadioGroup>
                            </FormControl>
                            <TextField 
                                id="collegeSchool" 
                                name="collegeSchool" 
                                label="Name of School" 
                                onBlur={(e, selectedOption) => { this.changeHandler("collegeSchool",e.target.value) }} 
                                fullWidth
                                error={!this.state.collegeSchoolIsValid.status && this.state.collegeSchoolIsValid.focused}
                                helperText={this.state.collegeSchoolIsValid.message}
                                required 
                            />               
                            <TextField 
                                id="collegeYearGraduated" 
                                name="collegeYearGraduated" 
                                label="Year Graduated" 
                                onBlur={(e, selectedOption) => { this.changeHandler("collegeYearGraduated",e.target.value) }} 
                                fullWidth
                                error={!this.state.collegeYearGraduatedIsValid.status && this.state.collegeYearGraduatedIsValid.focused}
                                helperText={this.state.collegeYearGraduatedIsValid.message}
                                required  
                            /> 
                            <TextField 
                                id="collegeAddress" 
                                name="collegeAddress" 
                                label="Address" 
                                onBlur={(e, selectedOption) => { this.changeHandler("collegeAddress",e.target.value) }} 
                                fullWidth
                                error={!this.state.collegeAddressIsValid.status && this.state.collegeAddressIsValid.focused}
                                helperText={this.state.collegeAddressIsValid.message}
                                required 
                            /> 
                        </CardContent>
                    </Card>
                </Grid>
            </React.Fragment>

        const invalidRequiredFields = this.state.emptyFields.map( (invalidField) => {

            return (
                <Typography style={{color:"maroon"}} variant="body1">{invalidField}</Typography>
            );
        });
            
        const englishGradeInputFields = this.state.applicationType === '1' ? 
            <React.Fragment>
                <TextField 
                    id="grade9GradeEnglish" 
                    name="grade9GradeEnglish" 
                    label="Grade 9" 
                    onChange={(e, selectedOption) => { this.changeHandler("grade9GradeEnglish",e.target.value) }} 
                    onBlur={(e, selectedOption) => { this.changeHandler("grade9GradeEnglish",e.target.value) }} 
                    value={this.state.grade9GradeEnglish} 
                    error={!this.state.grade9GradeEnglishIsValid.status && this.state.grade9GradeEnglishIsValid.focused}
                    helperText={this.state.grade9GradeEnglishIsValid.message} 
                    required
                    fullWidth 
                />
                <TextField 
                    id="grade10GradeEnglish" 
                    name="grade10GradeEnglish" 
                    label="Grade 10" 
                    onChange={(e, selectedOption) => { this.changeHandler("grade10GradeEnglish",e.target.value) }} 
                    value={this.state.grade10GradeEnglish} 
                    fullWidth 
                    onBlur={(e, selectedOption) => { this.changeHandler("grade10GradeEnglish",e.target.value) }} 
                    error={!this.state.grade10GradeEnglishIsValid.status && this.state.grade10GradeEnglishIsValid.focused}
                    helperText={this.state.grade10GradeEnglishIsValid.message} 
                    required

                />  
                <TextField 
                    id="grade11GradeEnglish" 
                    name="grade11GradeEnglish" 
                    label="Grade 11" 
                    onChange={(e, selectedOption) => { this.changeHandler("grade11GradeEnglish",e.target.value) }} 
                    value={this.state.grade11GradeEnglish} 
                    fullWidth 
                    onBlur={(e, selectedOption) => { this.changeHandler("grade11GradeEnglish",e.target.value) }} 
                    error={!this.state.grade11GradeEnglishIsValid.status && this.state.grade11GradeEnglishIsValid.focused}
                    helperText={this.state.grade11GradeEnglishIsValid.message} 
                    required
                />
            </React.Fragment> : 
            <React.Fragment>
                <TextField 
                    id="collegeGradeEnglish" 
                    name="collegeGradeEnglish" 
                    label="College" 
                    onChange={(e, selectedOption) => { this.changeHandler("collegeGradeEnglish",e.target.value) }} 
                    value={this.state.collegeGradeEnglish} 
                    fullWidth 
                    onBlur={(e, selectedOption) => { this.changeHandler("collegeGradeEnglish",e.target.value) }} 
                    error={!this.state.collegeGradeEnglishIsValid.status && this.state.collegeGradeEnglishIsValid.focused}
                    helperText={this.state.collegeGradeEnglishIsValid.message} 
                    required
                />
            </React.Fragment>

        const mathGradeInputFields = this.state.applicationType === '1' ? 
            <React.Fragment>
                <TextField 
                    id="grade9GradeMath" 
                    name="grade9GradeMath" 
                    label="Grade 9" 
                    onChange={(e, selectedOption) => { this.changeHandler("grade9GradeMath",e.target.value) }} 
                    value={this.state.grade9GradeMath} 
                    fullWidth
                    onBlur={(e, selectedOption) => { this.changeHandler("grade9GradeMath",e.target.value) }} 
                    error={!this.state.grade9GradeMathIsValid.status && this.state.grade9GradeMathIsValid.focused}
                    helperText={this.state.grade9GradeMathIsValid.message} 
                    required

                />
                <TextField 
                    id="grade10GradeMath" 
                    name="grade10GradeMath" 
                    label="Grade 10" 
                    onChange={(e, selectedOption) => { this.changeHandler("grade10GradeMath",e.target.value) }} 
                    value={this.state.grade10GradeMath} 
                    fullWidth
                    onBlur={(e, selectedOption) => { this.changeHandler("grade10GradeMath",e.target.value) }} 
                    error={!this.state.grade10GradeMathIsValid.status && this.state.grade10GradeMathIsValid.focused}
                    helperText={this.state.grade10GradeMathIsValid.message} 
                    required
                />  
                <TextField 
                    id="grade11GradeMath" 
                    name="grade11GradeMath" 
                    label="Grade 11" 
                    onChange={(e, selectedOption) => { this.changeHandler("grade11GradeMath",e.target.value) }} 
                    value={this.state.grade11GradeMath} 
                    fullWidth
                    onBlur={(e, selectedOption) => { this.changeHandler("grade11GradeMath",e.target.value) }} 
                    error={!this.state.grade11GradeMathIsValid.status && this.state.grade11GradeMathIsValid.focused}
                    helperText={this.state.grade11GradeMathIsValid.message} 
                    required
                />
            </React.Fragment> :
            <React.Fragment>
                <TextField 
                    id="collegeGradeMath" 
                    name="collegeGradeMath" 
                    label="College" 
                    onChange={(e, selectedOption) => { this.changeHandler("collegeGradeMath",e.target.value) }} 
                    value={this.state.collegeGradeMath} 
                    fullWidth
                    onBlur={(e, selectedOption) => { this.changeHandler("collegeGradeMath",e.target.value) }} 
                    error={!this.state.collegeGradeMathIsValid.status && this.state.collegeGradeMathIsValid.focused}
                    helperText={this.state.collegeGradeMathIsValid.message} 
                    required
                />
            </React.Fragment>
        
        const scienceGradeInputFields = this.state.applicationType === '1' ?
            <React.Fragment>
                <TextField 
                    id="grade9GradeScience" 
                    name="grade9GradeScience" 
                    label="Grade 9" 
                    onChange={(e, selectedOption) => { this.changeHandler("grade9GradeScience",e.target.value) }} 
                    value={this.state.grade9GradeScience} 
                    fullWidth
                    onBlur={(e, selectedOption) => { this.changeHandler("grade9GradeScience",e.target.value) }} 
                    error={!this.state.grade9GradeScienceIsValid.status && this.state.grade9GradeScienceIsValid.focused}
                    helperText={this.state.grade9GradeScienceIsValid.message} 
                    required
                />
                <TextField 
                    id="grade10GradeScience" 
                    name="grade10GradeScience" 
                    label="Grade 10" 
                    onChange={(e, selectedOption) => { this.changeHandler("grade10GradeScience",e.target.value) }} 
                    value={this.state.grade10GradeScience} 
                    fullWidth
                    onBlur={(e, selectedOption) => { this.changeHandler("grade10GradeScience",e.target.value) }} 
                    error={!this.state.grade10GradeScienceIsValid.status && this.state.grade10GradeScienceIsValid.focused}
                    helperText={this.state.grade10GradeScienceIsValid.message} 
                    required
                />  
                <TextField 
                    id="grade11GradeScience" 
                    name="grade11GradeScience" 
                    label="Grade 11" 
                    onChange={(e, selectedOption) => { this.changeHandler("grade11GradeScience",e.target.value) }} 
                    value={this.state.grade11GradeScience} 
                    fullWidth
                    onBlur={(e, selectedOption) => { this.changeHandler("grade11GradeScience",e.target.value) }} 
                    error={!this.state.grade11GradeScienceIsValid.status && this.state.grade11GradeScienceIsValid.focused}
                    helperText={this.state.grade11GradeScienceIsValid.message} 
                    required
                />
            </React.Fragment> : 
            <React.Fragment>
                <TextField 
                    id="collegeGradeScience" 
                    name="collegeGradeScience" 
                    label="College" 
                    onChange={(e, selectedOption) => { this.changeHandler("collegeGradeScience",e.target.value) }} 
                    value={this.state.collegeGradeScience} 
                    fullWidth
                    onBlur={(e, selectedOption) => { this.changeHandler("collegeGradeScience",e.target.value) }} 
                    error={!this.state.collegeGradeScienceIsValid.status && this.state.collegeGradeScienceIsValid.focused}
                    helperText={this.state.collegeGradeScienceIsValid.message} 
                    required
                />
            </React.Fragment>
        
        const gpaGradeInputFields = this.state.applicationType === '1' ?
            <React.Fragment>
                <TextField 
                    id="grade9GPA" 
                    name="grade9GPA" 
                    label="Grade 9" 
                    onChange={(e, selectedOption) => { this.changeHandler("grade9GPA",e.target.value) }} 
                    value={this.state.grade9GPA} 
                    fullWidth 
                    onBlur={(e, selectedOption) => { this.changeHandler("grade9GPA",e.target.value) }} 
                    error={!this.state.grade9GPAIsValid.status && this.state.grade9GPAIsValid.focused}
                    helperText={this.state.grade9GPAIsValid.message} 
                    required

                />
                <TextField 
                    id="grade10GPA" 
                    name="grade10GPA" 
                    label="Grade 10" 
                    onChange={(e, selectedOption) => { this.changeHandler("grade10GPA",e.target.value) }} 
                    value={this.state.grade10GPA} 
                    fullWidth
                    onBlur={(e, selectedOption) => { this.changeHandler("grade10GPA",e.target.value) }} 
                    error={!this.state.grade10GPAIsValid.status && this.state.grade10GPAIsValid.focused}
                    helperText={this.state.grade10GPAIsValid.message} 
                    required
                />  
                <TextField 
                    id="grade11GPA" 
                    name="grade11GPA" 
                    label="Grade 11" 
                    onChange={(e, selectedOption) => { this.changeHandler("grade11GPA",e.target.value) }} 
                    value={this.state.grade11GPA} 
                    fullWidth
                    onBlur={(e, selectedOption) => { this.changeHandler("grade11GPA",e.target.value) }} 
                    error={!this.state.grade11GPAIsValid.status && this.state.grade11GPAIsValid.focused}
                    helperText={this.state.grade11GPAIsValid.message} 
                    required
                />
            </React.Fragment> :
            <React.Fragment>
                <Typography variant="caption">Enter 0 if None</Typography>
                <TextField 
                    id="collegeGPA" 
                    name="collegeGPA" 
                    label="College" 
                    onChange={(e, selectedOption) => { this.changeHandler("collegeGPA",e.target.value) }} 
                    value={this.state.collegeGPA} 
                    fullWidth
                    onBlur={(e, selectedOption) => { this.changeHandler("collegeGPA",e.target.value) }} 
                    error={!this.state.collegeGPAIsValid.status && this.state.collegeGPAIsValid.focused}
                    helperText={this.state.collegeGPAIsValid.message} 
                    required

                />
            </React.Fragment>

        const finalGradesInputFields = this.state.applicationType === '1' ?
            <Grid item xs={12} sm={12}>
                <Card>
                    <CardHeader title="Final Grades" />
                    <CardContent>
                        {/* <Button  style={{fontSize:"12px",marginTop:"-52px"}} onClick={this.handleGradeSubjectInputClick}>How to input Subject Final Grades</Button> */}
                        <Grid item container xs={12} sm={12}>
                            <Grid item xs={4} sm={4}>
                                <Card>
                                    <CardHeader title="English" 
                                        subheader={<Button  style={{fontSize:"12px"}} onClick={() => {this.handleGradeSubjectInputClick('english')} }>How to input English Final Grades</Button>}
                                    />
                                    <CardContent>
                                        {englishGradeInputFields}
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <Card>
                                    <CardHeader title="Math" 
                                        subheader={<Button  style={{fontSize:"12px"}} onClick={() => {this.handleGradeSubjectInputClick('math')} }>How to input Math Final Grades</Button>}
                                    />
                                    <CardContent>
                                        {mathGradeInputFields}
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <Card>
                                    <CardHeader title="Science" 
                                        subheader={<Button  style={{fontSize:"12px"}} onClick={() => {this.handleGradeSubjectInputClick('science')} }>How to input Science Final Grades</Button>}
                                    />
                                    <CardContent>
                                        {scienceGradeInputFields}
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid> : null

        const shTrackInputFields = this.state.applicationType === '1' ?
            <Grid item xs={12} sm={6}>
                <Card>
                    <CardHeader title="Senior High School Track" />
                    <CardContent>
                        <Autocomplete 
                            id="track"
                            name="track"
                            value={this.state.trackObj}
                            inputValue={this.state.trackInputVal}
                            options={this.state.shTrackOptions}
                            getOptionLabel={(option) => option.track_name}
                            fullWidth
                            onChange={(e, selectedOption) => { 
                                this.changeHandler("track", selectedOption ? selectedOption.track_id : '', selectedOption ? selectedOption.track_name : '' ) 
                                this.changeHandler("trackObj", selectedOption) 
                            }}
                            onInputChange={(e, inputValue) => {
                                this.changeHandler("trackInputVal", inputValue)
                            }}
                            onBlur={(e, selectedOption) => { 
                                if(!e.target.value) this.changeHandler("track",selectedOption ? selectedOption.track_id : '') 
                            }}
                            renderInput={(params) => 
                                <TextField {...params} 
                                    required label="Track" 
                                    error={!this.state.trackIsValid.status && this.state.trackIsValid.focused}
                                    helperText={this.state.trackIsValid.message} 
                                />}
                        />
                        <Autocomplete 
                            id="strand"
                            name="strand"
                            value={this.state.strandObj}
                            inputValue={this.state.strandInputVal}
                            options={this.state.shStrandOptions.filter( (strand) => {
                                return strand.track_id === this.state.track;   
                            })}
                            getOptionLabel={(option) => option.strand_name}
                            fullWidth
                            onChange={(e, selectedOption) => { 
                                this.changeHandler("strand", selectedOption ? selectedOption.strand_id : '', selectedOption ? selectedOption.strand_name : '') 
                                this.changeHandler("strandObj", selectedOption);
                            }}
                            onInputChange={(e, inputValue) => {
                                this.changeHandler("strandInputVal", inputValue);
                            }}
                            onBlur={(e, selectedOption) => { 
                                if(!e.target.value) this.changeHandler("strand",selectedOption ? selectedOption.strand_id : '') 
                            }}
                            renderInput={(params) => 
                                <TextField {...params} 
                                    required label="Strand" 
                                    error={!this.state.strandIsValid.status && this.state.strandIsValid.focused}
                                    helperText={this.state.strandIsValid.message} 
                                />}
                        />
                    </CardContent>
                </Card>
            </Grid> : null

        // const incomeSourceFileDetailsDisplay =
        //  this.state.file_incomeSource ? 
        //     <Typography>File selected: {this.state.file_incomeSource.name}</Typography> 
        //     : null;
        
        const permanentAddressInputFieldsEnabled =
            <Autocomplete
                id="permanentAddressRegion"
                name="permanentAddressRegion"
                options={barangay_list}
                getOptionLabel={(option) => option.region_name}
                fullWidth
                value={this.state.permanentAddressRegion}
                // inputValue={this.state.permanentAddressRegion}
                onChange={(e, selectedOption) => { this.changeHandler("permanentAddressRegion", selectedOption ? selectedOption.region_name : '') }}
                onBlur={(e, selectedOption) => { 
                    if(!e.target.value) this.changeHandler("permanentAddressRegion",selectedOption ? selectedOption.region_name: '') 
                }}
                renderInput={(params) => 
                    <TextField {...params} 
                        required label="Region" 
                        error={!this.state.permanentAddressRegionIsValid.status && this.state.permanentAddressRegionIsValid.focused}
                        helperText={this.state.permanentAddressRegionIsValid.message} 
                    />}                                                         
            /> ;

        return(
            // <form noValidate autoComplete="off" onSubmit={this.initialSubmitHandler}>
            <Container maxWidth="lg" >
                <Grid item xs={12} sm={12} >
                    <Typography variant="h4" align="center" className={classes.formHeader}>Admission Application Form</Typography>
                </Grid>

                {/* Personal Information */}
                <Grid item container spacing={2} xs={12} sm={12}>
                    <Container maxWidth="lg" alignItems="flex-start" className={classes.sectionHeader}><Typography variant="h6">Personal Information</Typography></Container>
        
                        {/* Applicant's Photo */}
                        <Grid item container xs={12} sm={3} style={{textAlign:"center"}} >
                            <Paper style={{minHeight:"330px",minWidth:"100%",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
                                <Typography variant="subtitle1">Applicant's Photo</Typography>
                                {this.state.currDiplayedImgSrc ? ( 
                                    <img src={this.state.currDiplayedImgSrc} width="230px" height="330px" display="block" />
                               ) : 
                                <div>
                                    <IconButton onClick={this.handleChoosePhotoClicked} style={{fontSize:"1.2rem"}}>
                                        <CameraAltIcon /> Upload
                                    </IconButton>
                                    {/* <Button onClick={this.handleChoosePhotoClicked}>Upload Photo</Button> */}
                                </div> 
                               }
                            </Paper>
                            {this.state.currDiplayedImgSrc ?
                                <React.Fragment>
                                    <Grid item xs={6} sm={6}>
                                        <Button onClick={() => {this.setState({currDiplayedImgSrc: this.state.file_passportPhotoImgSrc})} }>w/ Name Tag</Button>        
                                    </Grid>        
                                    <Grid item xs={6} sm={6}>
                                        <Button onClick={() => {this.setState({currDiplayedImgSrc: this.state.file_idPhotoImgSrc})} }>w/ ID Card</Button>        
                                    </Grid>        
                                    <Grid item xs={12}>
                                        <Button variant="outlined" onClick={this.handleChoosePhotoClicked}>Edit Photo</Button>
                                    </Grid>
                                </React.Fragment>
                            : null }
                        </Grid>
                        
                        {/* Personal Information Floated Right */}
                        <Grid item xs={12} sm={9} style={{paddingRight:"0"}}>
                            <div>
                                <Grid item container alignItems="stretch" justify="flex-start" spacing={1} xs={12} sm={12} >
                                    {/* Row 1 (lastName...) */}
                                    <Grid item xs={12} sm={3}>
                                        <TextField 
                                            id="lastName" 
                                            name="lastName" 
                                            label="Last Name" 
                                            onBlur={(e, selectedOption) => { this.changeHandler("lastName",e.target.value) }}
                                            fullWidth
                                            variant="outlined"
                                            error={!this.state.lastNameIsValid.status && this.state.lastNameIsValid.focused}
                                            helperText={this.state.lastNameIsValid.message}
                                            required
                                        />
                                    </Grid> 
                                    <Grid item xs={12} sm={4}>
                                        <TextField 
                                            id="firstName" 
                                            name="firstName" 
                                            label="First Name" 
                                            onBlur={(e, selectedOption) => { this.changeHandler("firstName",e.target.value) }}
                                            fullWidth  
                                            // className={classes.txtFields}
                                            variant="outlined"
                                            error={!this.state.firstNameIsValid.status && this.state.firstNameIsValid.focused}
                                            helperText={this.state.firstNameIsValid.message}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>        
                                        <TextField 
                                            id="middleName" 
                                            name="middleName" 
                                            label="Middle Name" 
                                            onBlur={(e, selectedOption) => { this.changeHandler("middleName",e.target.value) }}
                                            fullWidth 
                                            // className={classes.txtFields}
                                            variant="outlined"
                                            error={!this.state.middleNameIsValid.status && this.state.middleNameIsValid.focused}
                                            helperText={this.state.middleNameIsValid.message}
                                        />
                                    </Grid>        
                                    <Grid item xs={12} sm={2}>
                                        <Autocomplete
                                            id="suffix" 
                                            name="suffix" 
                                            options={suffixOptions}
                                            onChange={(e, selectedOption) => { try{ this.changeHandler("suffix",selectedOption ? selectedOption : '') }catch(err){} }}
                                            onBlur={(e, selectedOption) => { 
                                                try{ 
                                                    if(!e.target.value) this.changeHandler("suffix",selectedOption ? selectedOption : '' )  
                                                }catch(err){
                                                    
                                                } 
                                            }}                                            
                                            fullWidth
                                            renderInput={(params) => 
                                                <TextField {...params}  
                                                    variant="outlined" label="Suffix" 
                                                />}
                                        />
                                    </Grid>           
                                    
                                    {/* Row 2 (dateOfBirth...) */}
                                    <Grid item xs={12} sm={3}>
                                        <DatePicker 
                                            id="birthdate"
                                            label="Date of Birth"
                                            format="MM/dd/yyyy"
                                            value={this.state.birthdate}
                                            change={this.handleDateChange}
                                            fullWidth
                                            required
                                        />
                                    </Grid>                    
                                    <Grid item xs={12} sm={7}>
                                        <TextField 
                                            id="birthplace" 
                                            name="birthplace" 
                                            label="Place of Birth" 
                                            onBlur={(e, selectedOption) => { this.changeHandler("birthplace",e.target.value) }}
                                            fullWidth 
                                            variant="outlined"
                                            required
                                            error={!this.state.birthplaceIsValid.status && this.state.birthplaceIsValid.focused}
                                            helperText={this.state.birthplaceIsValid.message}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <Autocomplete
                                            id="gender"
                                            name="gender"
                                            options={sexOptions}
                                            getOptionLabel={(option) => option.type}
                                            fullWidth
            
                                            onChange={(e, selectedOption) => { try{ this.changeHandler("gender",selectedOption ? selectedOption.value : '') }catch(err){} }}
                                            onBlur={(e, selectedOption) => { 
                                                try{ 
                                                    if(!e.target.value) this.changeHandler("gender",selectedOption ? selectedOption.value : '' )  
                                                }catch(err){
                                                    
                                                } 
                                            }}
                                            
                                            renderInput={(params) => 
                                                <TextField {...params}  
                                                    variant="outlined" label="Sex" required 
                                                    error={!this.state.genderIsValid.status && this.state.genderIsValid.focused}
                                                    helperText={this.state.genderIsValid.message}
                                                />}
                                        />
                                    </Grid>           

                                    {/* Row 3 (civilStatus...) */}
                                    <Grid item xs={12} sm={this.state.civilStatus === 5 ? 2 : 4}>
                                        <Autocomplete
                                            id="civilStatus"
                                            name="civilStatus"
                                            options={civilStatusOptions}
                                            getOptionLabel={(option) => option.type}
                                            fullWidth     
                                            onChange={(e, selectedOption) => { this.changeHandler("civilStatus",selectedOption ? selectedOption.value : '', selectedOption ? selectedOption.type : '') }}
                                            onBlur={(e, selectedOption) => { 
                                                if(!e.target.value) this.changeHandler("civilStatus",selectedOption ? selectedOption.value : '') 
                                            }}
                                            renderInput={(params) => 
                                                <TextField {...params} 
                                                    variant="outlined" required label="Civil Status" required
                                                    error={!this.state.civilStatusIsValid.status && this.state.civilStatusIsValid.focused}
                                                    helperText={this.state.civilStatusIsValid.message} 
                                                />}
                                        />
                                    </Grid>

                                    {/* Show Single Parent Type List if Civil Status is Single Parent */}
                                    {this.state.civilStatus === 5 ? singleParentTypeList : null}

                                    <Grid item xs={12} sm={this.state.civilStatus === 5 ? 3 : 4}>
                                        <Autocomplete
                                            id="citizenship"
                                            name="citizenship"
                                            options={this.state.nationalities}
                                            getOptionLabel={(option) => option.Nationality}
                                            fullWidth
                                            onChange={(e, selectedOption) => { this.changeHandler("citizenship",selectedOption ? selectedOption.NationalityID : '', selectedOption ? selectedOption.Nationality : '') }}
                                            onBlur={(e, selectedOption) => { 
                                                if(!e.target.value) this.changeHandler("citizenship",selectedOption ? selectedOption.value : '') 
                                            }}
                                            renderInput={(params) => 
                                                <TextField {...params} 
                                                    variant="outlined" label="Citizenship" required
                                                    error={!this.state.citizenshipIsValid.status && this.state.citizenshipIsValid.focused}
                                                    helperText={this.state.citizenshipIsValid.message} 

                                                />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={this.state.civilStatus === 5 ? 3 : 4}>
                                        <Autocomplete
                                            id="religion"
                                            name="religion"
                                            options={this.state.religionOptions}
                                            getOptionLabel={(option) => option.Religion}
                                            fullWidth
                                            onChange={(e, selectedOption) => { this.changeHandler("religion",selectedOption ? selectedOption.ReligionID : '', selectedOption ? selectedOption.Religion : '') }}
                                            onBlur={(e, selectedOption) => { 
                                                if(!e.target.value) this.changeHandler("religion",selectedOption ? selectedOption.value : '') 
                                            }}
                                            renderInput={(params) => 
                                                <TextField {...params} 
                                                    variant="outlined" label="Religion" required
                                                    error={!this.state.religionIsValid.status && this.state.religionIsValid.focused}
                                                    helperText={this.state.religionIsValid.message} 
  
                                                />} 
                                        />
                                    </Grid>
                                    
                                    {/* Row 4 (contactNumber...) */}
                                    <Grid item xs={12} sm={6}>
                                        <TextField 
                                            id="contactNumber" 
                                            name="contactNumber" 
                                            label="Contact Number" 
                                            onBlur={(e) => { this.changeHandler("contactNumber",e.target.value) }} 
                                            error={!this.state.contactNumberIsValid.status && this.state.contactNumberIsValid.focused}
                                            helperText={this.state.contactNumberIsValid.message}
                                            fullWidth 
                                            variant="outlined"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField 
                                            id="email" 
                                            name="email" 
                                            label="Email Address" 
                                            onBlur={(e) => { this.changeHandler("email",e.target.value) }} 
                                            error={!this.state.emailIsValid.status && this.state.emailIsValid.focused}
                                            helperText={this.state.emailIsValid.message} 
                                            fullWidth
                                            variant="outlined"
                                            required 
                                        />
                                    </Grid>
                                
                                    {/* Row 5 (specialNeeds...) */}
                                    <Grid item container xs={12} sm={6}>
                                        <FormControl component="fieldset" style={{alignItems:"center"}} fullWidth>
                                            <FormLabel component="legend" align="center">Are you a person with special needs?</FormLabel>
                                            <RadioGroup 
                                                row aria-label="hasSpecialNeeds" 
                                                name="hasSpecialNeeds" 
                                                value={this.state.hasSpecialNeeds} 
                                                onChange={(e, selectedOption) => { this.changeHandler("hasSpecialNeeds",e.target.value) }}
                                            >
                                                <FormControlLabel value="0" control={<Radio />} label="No" />
                                                <FormControlLabel value="1" control={<Radio />} label="Yes" />
                                            </RadioGroup>
                                        </FormControl>
                                        {this.state.hasSpecialNeeds === "1" ? specialNeedsList : null}
                                        {this.state.hasSpecialNeeds === "1" ? pwdIDOption : null}
                                        {this.state.hasPwdId === "1" ? pwdIDInput : null}
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <FormControl component="fieldset" style={{alignItems:"center"}} fullWidth>
                                            <FormLabel component="legend" align="center">Are you a member of the Indigenous Peoples?</FormLabel>
                                            <RadioGroup 
                                                row aria-label="isMemberOfIP" 
                                                name="isMemberOfIP" 
                                                value={this.state.isMemberOfIP} 
                                                onChange={(e, selectedOption) => { this.changeHandler("isMemberOfIP",e.target.value) }}
                                            >
                                                <FormControlLabel value="0" control={<Radio />} label="No" />
                                                <FormControlLabel value="1" control={<Radio />} label="Yes" />
                                            </RadioGroup>
                                        </FormControl>
                                        {this.state.isMemberOfIP === "1" ? tribeList : null}
                                    </Grid>

                                </Grid> 
                            </div>
                        </Grid>
                        
                        {/* Distance Learning / COVID-related Questions */}
                        <Grid item xs={12} sm={12}>
                            <div>
                                <Grid item container>
                                    <Grid item xs={12} sm={ (this.state.vaccinationStatus === '0') ? 3 : 4} className={classes.pandemicQuestionDiv}>
                                        <FormControl component="fieldset" style={{alignItems:"center"}} fullWidth>
                                            <FormLabel component="legend" align="center" className={classes.pandemicQuestionHeader}>How do you describe your internet connectivity?</FormLabel>
                                            <Autocomplete
                                                id="connectivity"
                                                name="connectivity"
                                                options={connectivityOptions}
                                                getOptionLabel={(option) => option.type}
                                                style={{width:"90%",paddingTop:"15px"}}
                
                                                onChange={(e, selectedOption) => { try{ this.changeHandler("connectivity",selectedOption ? selectedOption.value : '') }catch(err){} }}
                                                onBlur={(e, selectedOption) => { 
                                                    try{ 
                                                        if(!e.target.value) this.changeHandler("connectivity",selectedOption ? selectedOption.value : '' )  
                                                    }catch(err){
                                                        
                                                    } 
                                                }}
                                                
                                                renderInput={(params) => 
                                                    <TextField {...params}  
                                                        label="" required 
                                                        error={!this.state.connectivityIsValid.status && this.state.connectivityIsValid.focused}
                                                        helperText={this.state.connectivityIsValid.message}
                                                    />}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={ (this.state.vaccinationStatus === '0') ? 3 : 4} className={classes.pandemicQuestionDiv} >
                                        <FormControl component="fieldset"  fullWidth>
                                            <FormLabel component="legend" align="center" className={classes.pandemicQuestionHeader} style={{paddingBottom:"25px"}}>What gadget(s) do you have available for studies?</FormLabel>
                                            <FormGroup style={{paddingLeft:"15px"}}>
                                                <FormControlLabel 
                                                    control={
                                                        <Checkbox 
                                                            checked={this.state.gadgetPcChecked} 
                                                            onChange={(e, selectedOption) => { this.changeHandler("gadgetPcChecked",e.target.checked) }} 
                                                        />
                                                    } 
                                                    label="Personal Computer/Laptop/Desktop" 
                                                />
                                                <FormControlLabel 
                                                    control={
                                                        <Checkbox 
                                                            checked={this.state.gadgetCpChecked} 
                                                            onChange={(e, selectedOption) => { this.changeHandler("gadgetCpChecked",e.target.checked) }} 
                                                        />
                                                    } 
                                                    label="Cellphone/Tablet" 
                                                />
                                                <FormControlLabel 
                                                    control={
                                                        <Checkbox checked={this.state.gadgetNone} onChange={(e, selectedOption) => { this.changeHandler("gadgetNone",e.target.checked) }} />
                                                    } 
                                                    label="None" 
                                                />
                                            </FormGroup>
                                        
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={ (this.state.vaccinationStatus === '0') ? 3 : 4} className={classes.pandemicQuestionDiv}>
                                        <FormControl component="fieldset" fullWidth>
                                            <FormLabel component="legend" align="center" className={classes.pandemicQuestionHeader}>What is your vaccination status against COVID-19 as of this time of your application?</FormLabel>
                                            <RadioGroup 
                                                aria-label="vaccinationStatus" 
                                                name="vaccinationStatus" 
                                                style={{paddingLeft:"15px"}}
                                                value={this.state.vaccinationStatus} 
                                                onChange={(e, selectedOption) => { this.changeHandler("vaccinationStatus",e.target.value) }}
                                            >
                                                <FormControlLabel value="2" control={<Radio />} label="Fully Vaccinated (Complete Doses)" />
                                                <FormControlLabel value="1" control={<Radio />} label="Partially Vaccinated (First Dose)" />
                                                <FormControlLabel value="0" control={<Radio />} label="Not Vaccinated" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    {this.state.vaccinationStatus === "0" ? vaccinationPlan : null}
                                
                                </Grid>
                            </div>
                        </Grid>

                        {/* Applicant's Present Address */}
                        <Grid item xs={12} sm={6}>
                            <div>
                                <Grid item container>
                                    <Grid item xs={12} sm={12}>
                                        <Card>
                                            <CardHeader title="Current Address" />
                                                <CardContent>
                                                    <Autocomplete
                                                        id="presentAddressRegion"
                                                        name="presentAddressRegion"
                                                        value={this.state.presentAddressRegionObj}
                                                        inputValue={this.state.presentAddressRegion}
                                                        options={barangay_list}
                                                        getOptionLabel={(option) => option.region_name}
                                                        fullWidth
                                                        // onChange={(e, selectedOption) => { this.changeHandler("presentAddressRegion", selectedOption ? selectedOption.region_name : '') }}
                                                        onChange={(e, selectedOption) => { this.changeHandler("presentAddressRegionObj", selectedOption) }}
                                                        onInputChange={(e, inputValue) => { 
                                                            this.changeHandler("presentAddressRegion", inputValue) } 
                                                        }
                                                        onBlur={(e, selectedOption) => { 
                                                            if(!e.target.value) {
                                                                this.changeHandler("presentAddressRegion", selectedOption ? selectedOption.region_name : '') 
                                                            } 
                                                        }}
                                                        renderInput={(params) => 
                                                            <TextField {...params} 
                                                                required label="Region" 
                                                                error={!this.state.presentAddressRegionIsValid.status && this.state.presentAddressRegionIsValid.focused}
                                                                helperText={this.state.presentAddressRegionIsValid.message} 
                                                            />}
                                        
                                                    />
                                                    <Autocomplete
                                                        id="presentAddressProvince"
                                                        name="presentAddressProvince"
                                                        value={this.state.presentAddressProvinceObj}
                                                        inputValue={this.state.presentAddressProvince}
                                                        options={selectedRegionProvinceListArr}
                                                        getOptionLabel={(option) => option.province_name}
                                                        fullWidth
                                                        // onChange={(e, selectedOption) => { this.changeHandler("presentAddressProvince", selectedOption ? selectedOption.province_name : '') }}
                                                        onChange={(e, selectedOption) => { this.changeHandler("presentAddressProvinceObj", selectedOption) }}
                                                        onInputChange={(e, inputValue) => { 
                                                            
                                                            this.changeHandler("presentAddressProvince", inputValue) } 
                                                        }
                                                        onBlur={(e, selectedOption) => { 
                                                            if(!e.target.value) this.changeHandler("presentAddressProvince",selectedOption ? selectedOption.province_name: '') 
                                                        }}
                                                        renderInput={(params) => 
                                                            <TextField {...params} 
                                                                required label="Province" 
                                                                error={!this.state.presentAddressProvinceIsValid.status && this.state.presentAddressProvinceIsValid.focused}
                                                                helperText={this.state.presentAddressProvinceIsValid.message} 
                                                            />}                                                        
                                                    />
                                                    <Autocomplete
                                                        id="presentAddressCity"
                                                        name="presentAddressCity"
                                                        value={this.state.presentAdressCityObj}
                                                        inputValue={this.state.presentAddressCity}
                                                        options={selectedRegionCityListArr}
                                                        getOptionLabel={(option) => option.city_name}
                                                        fullWidth
                                                        // onChange={(e, selectedOption) => { this.changeHandler("presentAddressCity", selectedOption ? selectedOption.city_name : '') }}
                                                        onChange={(e, selectedOption) => { this.changeHandler("presentAddressCityObj", selectedOption) }}
                                                        onInputChange={(e, inputValue) => { 
                                                            
                                                            this.changeHandler("presentAddressCity", inputValue) } 
                                                        }
                                                        onBlur={(e, selectedOption) => { 
                                                            if(!e.target.value) this.changeHandler("presentAddressCity",selectedOption ? selectedOption.city_name: '') 
                                                        }}
                                                        renderInput={(params) => 
                                                            <TextField {...params} 
                                                                required label="City" 
                                                                error={!this.state.presentAddressCityIsValid.status && this.state.presentAddressCityIsValid.focused}
                                                                helperText={this.state.presentAddressCityIsValid.message} 
                                                            />}                                                        
                                                        
                                                    />
                                                    <Autocomplete
                                                        id="presentAddressBrgy"
                                                        name="presentAddressBrgy"
                                                        value={this.state.presentAddressBrgyObj}
                                                        inputValue={this.state.presentAddressBrgy}
                                                        options={selectedRegionBrgyListArr}
                                                        getOptionLabel={(option) => option.brgy_name}
                                                        fullWidth
                                                        // onChange={(e, selectedOption) => { this.changeHandler("presentAddressBrgy", selectedOption ? selectedOption.brgy_name : '') }}
                                                        onChange={(e, selectedOption) => { this.changeHandler("presentAddressBrgyObj", selectedOption) }}
                                                        onInputChange={(e, inputValue) => { 
                                                            
                                                            this.changeHandler("presentAddressBrgy", inputValue) } 
                                                        }
                                                        onBlur={(e, selectedOption) => { 
                                                            if(!e.target.value) this.changeHandler("presentAddressBrgy",selectedOption ? selectedOption.brgy_name: '') 
                                                        }}
                                                        renderInput={(params) => 
                                                            <TextField {...params} 
                                                                required label="Barangay" 
                                                                error={!this.state.presentAddressBrgyIsValid.status && this.state.presentAddressBrgyIsValid.focused}
                                                                helperText={this.state.presentAddressBrgyIsValid.message} 
                                                            />}                                                        
                                                        
                                                    />
                                                    <TextField 
                                                        id="presentAddressStreet"
                                                        name="presentAddressStreet" 
                                                        label="Street/Purok/House No." 
                                                        onBlur={(e) => { this.changeHandler("presentAddressStreet",e.target.value) }}
                                                        error={!this.state.presentAddressStreetIsValid.status && this.state.presentAddressStreetIsValid.focused}
                                                        helperText={this.state.presentAddressStreetIsValid.message} 
                                                        required
                                                        fullWidth
                                                         
                                                    />
                                                    <TextField 
                                                        id="presentAddressZipCode" 
                                                        name="presentAddressZipCode" label="Zip Code" 
                                                        onBlur={(e) => { this.changeHandler("presentAddressZipCode",e.target.value) }} 
                                                        error={!this.state.presentAddressZipCodeIsValid.status && this.state.presentAddressZipCodeIsValid.focused}
                                                        helperText={this.state.presentAddressZipCodeIsValid.message} 
                                                        required
                                                        fullWidth 
                                                    /> 
                                                </CardContent>
                                            </Card>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>

                        {/* Applicant's Permanent Address */}
                        <Grid item xs={12} sm={6}>
                            <div>
                                <Grid item container>
                                    <Grid item xs={12} sm={12}>
                                        <Card>
                                            <CardHeader 
                                                title="Permanent Address"
                                                subheader={
                                                    <FormControlLabel 
                                                        control={<Switch checked={this.state.checkedCopyCurrentAdd} onChange={this.handleCopyCurrentAdd} name="checkedCopyAdd" />}
                                                        label="Same as current address"
                                                    />
                                                }
                                                 />
                                                <CardContent>
                                                    {/* {this.state.permAddressDetailsChanged ? permanentAddressInputFieldsDisabled : permanentAddressInputFieldsEnabled } */}
                                                    <Autocomplete
                                                        id="permanentAddressRegion"
                                                        name="permanentAddressRegion"
                                                        value={this.state.permanentAddressRegionObj}
                                                        inputValue={this.state.permanentAddressRegion}
                                                        options={barangay_list}
                                                        getOptionLabel={(option) => option.region_name}
                                                        fullWidth
                                                        disabled={this.state.checkedCopyCurrentAdd}
                                                        // onChange={(e, selectedOption) => { this.changeHandler("permanentAddressRegion", selectedOption ? selectedOption.region_name : '') }}
                                                        onChange={(e, selectedOption) => { this.changeHandler("permanentAddressRegionObj", selectedOption) }}
                                                        onInputChange={(e, inputValue) => { 
                                                            this.changeHandler("permanentAddressRegion", inputValue) } 
                                                        }
                                                        onBlur={(e, selectedOption) => { 
                                                            if(!e.target.value) this.changeHandler("permanentAddressRegion",selectedOption ? selectedOption.region_name: '') 
                                                        }}
                                                        renderInput={(params) => 
                                                            <TextField {...params} 
                                                                required label="Region" 
                                                                error={!this.state.permanentAddressRegionIsValid.status && this.state.permanentAddressRegionIsValid.focused}
                                                                helperText={this.state.permanentAddressRegionIsValid.message} 
                                                            />}                                                         
                                                    />
                                                    <Autocomplete
                                                        id="permanentAddressProvince"
                                                        name="permanentAddressProvince"
                                                        value={this.state.permanentAddressProvinceObj}
                                                        inputValue={this.state.permanentAddressProvince}
                                                        options={selectedPermRegionProvinceListArr}
                                                        getOptionLabel={(option) => option.province_name}
                                                        fullWidth
                                                        disabled={this.state.checkedCopyCurrentAdd}
                                                        // onChange={(e, selectedOption) => { this.changeHandler("permanentAddressProvince", selectedOption ? selectedOption.province_name : '') }}
                                                        onChange={(e, selectedOption) => { this.changeHandler("permanentAddressProvinceObj", selectedOption) }}
                                                        onInputChange={(e, inputValue) => { 
                                                            this.changeHandler("permanentAddressProvince", inputValue) } 
                                                        }
                                                        onBlur={(e, selectedOption) => { 
                                                            if(!e.target.value) this.changeHandler("permanentAddressProvince",selectedOption ? selectedOption.province_name: '') 
                                                        }}
                                                        renderInput={(params) => 
                                                            <TextField {...params} 
                                                                required label="Province" 
                                                                error={!this.state.permanentAddressProvinceIsValid.status && this.state.permanentAddressProvinceIsValid.focused}
                                                                helperText={this.state.permanentAddressProvinceIsValid.message} 
                                                            />}                                                        
                                                    />
                                                    <Autocomplete
                                                        id="permanentAddressCity"
                                                        name="permanentAddressCity"
                                                        value={this.state.permanentAddressCityObj}
                                                        inputValue={this.state.permanentAddressCity}
                                                        options={selectedPermRegionCityListArr}
                                                        getOptionLabel={(option) => option.city_name}
                                                        fullWidth
                                                        disabled={this.state.checkedCopyCurrentAdd}
                                                        // onChange={(e, selectedOption) => { this.changeHandler("permanentAddressCity", selectedOption ? selectedOption.city_name : '') }}
                                                        onChange={(e, selectedOption) => { this.changeHandler("permanentAddressCityObj", selectedOption) }}
                                                        onInputChange={(e, inputValue) => { 
                                                            this.changeHandler("permanentAddressCity", inputValue) } 
                                                        }
                                                        onBlur={(e, selectedOption) => { 
                                                            if(!e.target.value) this.changeHandler("permanentAddressCity",selectedOption ? selectedOption.city_name: '') 
                                                        }}
                                                        renderInput={(params) => 
                                                            <TextField {...params} 
                                                                required label="City" 
                                                                error={!this.state.permanentAddressCityIsValid.status && this.state.permanentAddressCityIsValid.focused}
                                                                helperText={this.state.permanentAddressCityIsValid.message} 
                                                            />}                                                        
                                                    />
                                                    <Autocomplete
                                                        id="permanentAddressBrgy"
                                                        name="permanentAddressBrgy"
                                                        value={this.state.permanentAddressBrgyObj}
                                                        inputValue={this.state.permanentAddressBrgy}
                                                        options={selectedPermRegionBrgyListArr}
                                                        getOptionLabel={(option) => option.brgy_name}
                                                        fullWidth
                                                        disabled={this.state.checkedCopyCurrentAdd}
                                                        // onChange={(e, selectedOption) => { this.changeHandler("permanentAddressBrgy", selectedOption ? selectedOption.brgy_name : '') }}
                                                        onChange={(e, selectedOption) => { this.changeHandler("permanentAddressBrgyObj", selectedOption) }}
                                                        onInputChange={(e, inputValue) => { 
                                                            this.changeHandler("permanentAddressBrgy", inputValue) } 
                                                        }
                                                        onBlur={(e, selectedOption) => { 
                                                            if(!e.target.value) this.changeHandler("permanentAddressBrgy",selectedOption ? selectedOption.brgy_name: '') 
                                                        }}
                                                        renderInput={(params) => 
                                                            <TextField {...params} 
                                                                required label="Barangay" 
                                                                error={!this.state.permanentAddressBrgyIsValid.status && this.state.permanentAddressBrgyIsValid.focused}
                                                                helperText={this.state.permanentAddressBrgyIsValid.message} 
                                                            />}                                
                                                    />

                                                    <TextField 
                                                        id="permanentAddressStreet" 
                                                        name="permanentAddressStreet" 
                                                        label="Street/Purok/House No." 
                                                        onBlur={(e) => { this.changeHandler("permanentAddressStreet",e.target.value) }} 
                                                        onChange={(e) => { this.changeHandler("permanentAddressStreet",e.target.value) }} 
                                                        error={!this.state.permanentAddressStreetIsValid.status && this.state.permanentAddressStreetIsValid.focused}
                                                        helperText={this.state.permanentAddressStreetIsValid.message} 
                                                        required
                                                        fullWidth
                                                        disabled={this.state.checkedCopyCurrentAdd}
                                                        value={this.state.permanentAddressStreet} 
                                                    />

                                                    <TextField 
                                                        id="permanentAddressZipCode" 
                                                        name="permanentAddressZipCode" 
                                                        label="Zip Code" 
                                                        onBlur={(e) => { this.changeHandler("permanentAddressZipCode",e.target.value) }}  
                                                        onChange={(e) => { this.changeHandler("permanentAddressZipCode",e.target.value) }}  
                                                        error={!this.state.permanentAddressZipCodeIsValid.status && this.state.permanentAddressZipCodeIsValid.focused}
                                                        helperText={this.state.permanentAddressZipCodeIsValid.message} 
                                                        required
                                                        fullWidth
                                                        disabled={this.state.checkedCopyCurrentAdd}
                                                        value={this.state.permanentAddressZipCode} 
                                                    />
                                                </CardContent>
                                            </Card>  
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>

                </Grid>

                {/* Family Background */}
                <Grid item container  xs={12} sm={12} >
                    <Container maxWidth="lg" className={classes.sectionHeader}><Typography variant="h6" fullWidth>Family Background</Typography></Container>
                        {/* Father*/}
                        <Grid item xs={12} sm={4}>
                            <Card>
                                <CardHeader title="Father" className={classes.sectionCardHeader}/>
                                <CardContent>
                                    <FormControl component="fieldset">
                                        <RadioGroup 
                                            row aria-label="parentType" 
                                            id="fParentType" name="fParentType" 
                                            value={this.state.fParentType} 
                                            onChange={(e, selectedOption) => { this.changeHandler("fParentType",e.target.value) }}
                                            >
                                                <FormControlLabel name="fParentType"  value='Living' control={<Radio />} label="Living" />
                                                <FormControlLabel name="fParentType" value='Deceased' control={<Radio />} label="Deceased" />
                                                <FormControlLabel name="fParentType" value='Unknown' control={<Radio />} label="Unknown" />
                                        </RadioGroup>
                                    </FormControl>
                                    <TextField 
                                        id="fLastName" 
                                        name="fLastName" 
                                        label="Last Name" 
                                        required
                                        value={this.state.fLastName}
                                        onChange={(e) => { this.changeHandler("fLastName",e.target.value) }}
                                        onBlur={(e) => { this.changeHandler("fLastName",e.target.value) }}  
                                        fullWidth
                                        disabled={this.state.fParentType === 'Unknown'}
                                        error={!this.state.fLastNameIsValid.status && this.state.fLastNameIsValid.focused}
                                        helperText={this.state.fLastNameIsValid.message} 
                                    />        
                                    <TextField 
                                        id="fFirstName" 
                                        name="fFirstName" 
                                        label="First Name"
                                        required
                                        value={this.state.fFirstName}
                                        onChange={(e) => { this.changeHandler("fFirstName",e.target.value) }} 
                                        onBlur={(e) => { this.changeHandler("fFirstName",e.target.value) }} 
                                        fullWidth
                                        disabled={this.state.fParentType === 'Unknown'}
                                        error={!this.state.fFirstNameIsValid.status && this.state.fFirstNameIsValid.focused}
                                        helperText={this.state.fFirstNameIsValid.message} 
                                    />        
                                    <TextField 
                                        id="fMiddleName" 
                                        name="fMiddleName" 
                                        label="Middle Name"
                                        value={this.state.fMiddleName}
                                        onChange={(e) => { this.changeHandler("fMiddleName",e.target.value) }}  
                                        onBlur={(e) => { this.changeHandler("fMiddleName",e.target.value) }} 
                                        fullWidth
                                        disabled={this.state.fParentType === 'Unknown'}
                                        error={!this.state.fMiddleNameIsValid.status && this.state.fMiddleNameIsValid.focused}
                                        helperText={this.state.fMiddleNameIsValid.message} 
                                    /> 
                                    {/* <TextField 
                                        id="fSuffix" 
                                        name="fSuffix" 
                                        label="Suffix" 
                                        disabled={this.state.fParentType === 'Unknown'}
                                        onBlur={(e) => { this.changeHandler("fSuffix",e.target.value) }} 
                                        fullWidth 
                                    />  */}
                                    <Autocomplete
                                        id="fSuffix" 
                                        name="fSuffix" 
                                        options={suffixOptions}
                                        value={this.state.fSuffix}
                                        inputValue={this.state.fSuffixInputVal}
                                        onChange={(e, selectedOption) => { try{ this.changeHandler("fSuffix",selectedOption ? selectedOption : '') }catch(err){} }}
                                        onInputChange={(e, inputValue) => {
                                            this.changeHandler('fSuffixInputVal', inputValue);
                                        }}
                                        onBlur={(e, selectedOption) => { 
                                            try{ 
                                                if(!e.target.value) this.changeHandler("fSuffix",selectedOption ? selectedOption : '' )  
                                            }catch(err){
                                                
                                            } 
                                        }}                                            
                                        fullWidth
                                        disabled={this.state.fParentType === 'Unknown'}
                                        renderInput={(params) => 
                                            <TextField {...params}  
                                                 label="Suffix" 
                                            />}
                                    />
                                    <TextField 
                                        id="fOccupation" 
                                        name="fOccupation" 
                                        label="Occupation"
                                        required 
                                        value={this.state.fOccupation}
                                        onChange={(e) => { this.changeHandler("fOccupation",e.target.value) }} 
                                        onBlur={(e) => { this.changeHandler("fOccupation",e.target.value) }} 
                                        fullWidth
                                        disabled={this.state.fParentType === 'Unknown' || this.state.fParentType === 'Deceased'}
                                        error={!this.state.fOccupationIsValid.status && this.state.fOccupationIsValid.focused}
                                        helperText={this.state.fOccupationIsValid.message}  
                                    /> 
                                    
                                    <Autocomplete 
                                        id="fAnnualIncome"
                                        name="fAnnualIncome"
                                        value={this.state.fAnnualIncomeObj}
                                        inputValue={this.state.fAnnualIncomeInputVal}
                                        options={this.state.annualIncomeOptions.map( (incomeBracket) => {
                                            let formatter = new Intl.NumberFormat('en-US', {
                                                style: 'currency',
                                                currency: 'PHP'
                                            });
                                            return {
                                                ...incomeBracket,
                                                income_range: `${formatter.format(incomeBracket.income_from)} - ${formatter.format(incomeBracket.income_to)}`
                                            }
                                        })}
                                        getOptionLabel={(option) => option.income_range.toString()}
                                        fullWidth
                                        onChange={(e, selectedOption) => { 
                                            if(selectedOption){ 
                                                this.changeHandler("fAnnualIncome", selectedOption.income_id,selectedOption.income_range.toString());
                                                this.changeHandler("fAnnualIncomeObj", selectedOption); 
                                            }
                                        }}
                                        onInputChange={(e, inputValue) => {
                                            this.changeHandler("fAnnualIncomeInputVal", inputValue)
                                        }}                                        
                                        onBlur={(e, selectedOption) => { 
                                            if(!e.target.value){ 
                                                this.changeHandler("fAnnualIncome", selectedOption ? selectedOption.income_id : '', selectedOption ? selectedOption.income_range.toString() : '') 
                                                this.changeHandler("fAnnualIncomeInputVal", '') 
                                            }                                   
                                        }}
                                        disabled={this.state.fParentType === 'Unknown' || this.state.fParentType === 'Deceased'}
                                        renderInput={(params) => 
                                            <TextField {...params} required label="Annual Income" 
                                                error={!this.state.fAnnualIncomeIsValid.status && this.state.fAnnualIncomeIsValid.focused}
                                                helperText={this.state.fAnnualIncomeIsValid.message} 
                                            />
                                        }
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                        
                        {/* Mother */}
                        <Grid item xs={12} sm={4}>
                            <Card>
                                <CardHeader title="Mother" />
                                <CardContent>
                                    <FormControl component="fieldset">
                                        <RadioGroup 
                                            row aria-label="parentType" 
                                            id="mParentType" name="mParentType" 
                                            value={this.state.mParentType} 
                                            onChange={(e, selectedOption) => { this.changeHandler("mParentType" ,e.target.value) }}
                                        >
                                            <FormControlLabel value="Living" control={<Radio />} label="Living" />
                                            <FormControlLabel value="Deceased" control={<Radio />} label="Deceased" />
                                            <FormControlLabel value='Unknown' control={<Radio />} label="Unknown" />
                                        </RadioGroup>
                                    </FormControl>
                                    <TextField 
                                        id="mLastName" 
                                        name="mLastName" 
                                        label="Last Name"
                                        required
                                        value={this.state.mLastName} 
                                        onChange={(e, selectedOption) => { this.changeHandler("mLastName" ,e.target.value) }}
                                        onBlur={(e, selectedOption) => { this.changeHandler("mLastName" ,e.target.value) }} 
                                        fullWidth
                                        disabled={this.state.mParentType === 'Unknown'}
                                        error={!this.state.mLastNameIsValid.status && this.state.mLastNameIsValid.focused}
                                        helperText={this.state.mLastNameIsValid.message} 
                                    />        
                                    <TextField 
                                        id="mFirstName" 
                                        name="mFirstName" 
                                        label="First Name" 
                                        required
                                        value={this.state.mFirstName}
                                        onChange={(e, selectedOption) => { this.changeHandler("mFirstName" ,e.target.value) }} 
                                        onBlur={(e, selectedOption) => { this.changeHandler("mFirstName" ,e.target.value) }} 
                                        fullWidth
                                        disabled={this.state.mParentType === 'Unknown'}
                                        error={!this.state.mFirstNameIsValid.status && this.state.mFirstNameIsValid.focused}
                                        helperText={this.state.mFirstNameIsValid.message} 
                                    />        
                                    <TextField 
                                        id="mMiddleName" 
                                        name="mMiddleName" 
                                        label="Middle Name"
                                        value={this.state.mMiddleName} 
                                        onChange={(e, selectedOption) => { this.changeHandler("mMiddleName" ,e.target.value) }} 
                                        onBlur={(e, selectedOption) => { this.changeHandler("mMiddleName" ,e.target.value) }} 
                                        fullWidth
                                        disabled={this.state.mParentType === 'Unknown'}
                                        error={!this.state.mMiddleNameIsValid.status && this.state.mMiddleNameIsValid.focused}
                                        helperText={this.state.mMiddleNameIsValid.message} 
                                    /> 
                                    <TextField 
                                        id="mOccupation" 
                                        name="mOccupation" 
                                        label="Occupation" 
                                        required
                                        value={this.state.mOccupation}
                                        onChange={(e, selectedOption) => { this.changeHandler("mOccupation" ,e.target.value) }} 
                                        onBlur={(e, selectedOption) => { this.changeHandler("mOccupation" ,e.target.value) }} 
                                        fullWidth
                                        disabled={this.state.mParentType === 'Unknown' || this.state.mParentType === 'Deceased'}
                                        error={!this.state.mOccupationIsValid.status && this.state.mOccupationIsValid.focused}
                                        helperText={this.state.mOccupationIsValid.message} 
                                    /> 
                                    
                                    <Autocomplete 
                                        id="mAnnualIncome"
                                        name="mAnnualIncome"
                                        value={this.state.mAnnualIncomeObj}
                                        inputValue={this.state.mAnnualIncomeInputVal}
                                        options={this.state.annualIncomeOptions.map( (incomeBracket) => {
                                            let formatter = new Intl.NumberFormat('en-US', {
                                                style: 'currency',
                                                currency: 'PHP'
                                            });
                                            return {
                                                ...incomeBracket,
                                                income_range: `${formatter.format(incomeBracket.income_from)} - ${formatter.format(incomeBracket.income_to)}`
                                            }
                                        })}
                                        getOptionLabel={(option) => option.income_range.toString()}
                                        fullWidth
                                        disabled={this.state.mParentType === 'Unknown' || this.state.mParentType === 'Deceased'}
                                        onChange={(e, selectedOption) => { 
                                            if(selectedOption){ 
                                                this.changeHandler("mAnnualIncome", selectedOption.income_id,selectedOption.income_range.toString()) 
                                                this.changeHandler("mAnnualIncomeObj", selectedOption)
                                            }
                                        }}
                                        onInputChange={(e, inputValue) => {
                                            this.changeHandler("mAnnualIncomeInputVal", inputValue)
                                        }} 
                                        onBlur={(e, selectedOption) => { 
                                            if(!e.target.value){ 
                                                this.changeHandler("mAnnualIncome", selectedOption ? selectedOption.income_id : '', selectedOption ? selectedOption.income_range.toString() : '') 
                                                this.changeHandler("mAnnualIncomeInputVal", '')
                                            }    
                                        }}                                        
                                        renderInput={(params) => <TextField {...params} required label="Annual Income" 
                                                error={!this.state.mAnnualIncomeIsValid.status && this.state.mAnnualIncomeIsValid.focused}
                                                helperText={this.state.mAnnualIncomeIsValid.message}/>}
                                    />
                                    
                                </CardContent>
                            </Card>
                        </Grid>
                        
                        {/* Guardian */}
                        <Grid item xs={12} sm={4}>
                            <Card>
                                <CardHeader title="Guardian"
                                    subheader={
                                        <FormControlLabel 
                                            control={<Switch checked={this.state.checkedEnableGuardianInput} onChange={this.handleEnableGuardianInput} name="checkedCopyAdd" />}
                                            label="Toggle to input guardian details"
                                        />
                                    }
                                 />
                                <CardContent>
                                    <TextField 
                                        id="gLastName" 
                                        name="gLastName" 
                                        label="Last Name" 
                                        onBlur={(e, selectedOption) => { this.changeHandler("gLastName" ,e.target.value) }} 
                                        fullWidth
                                        disabled={!this.state.checkedEnableGuardianInput}
                                        required={this.state.checkedEnableGuardianInput}
                                        error={!this.state.gLastNameIsValid.status && this.state.gLastNameIsValid.focused}
                                        helperText={this.state.gLastNameIsValid.message}  
                                    />        
                                    <TextField 
                                        id="gFirstName" 
                                        name="gFirstName" 
                                        label="First Name" 
                                        onBlur={(e, selectedOption) => { this.changeHandler("gFirstName" ,e.target.value) }} 
                                        fullWidth
                                        disabled={!this.state.checkedEnableGuardianInput}
                                        required={this.state.checkedEnableGuardianInput}
                                        error={!this.state.gFirstNameIsValid.status && this.state.gFirstNameIsValid.focused}
                                        helperText={this.state.gFirstNameIsValid.message} 
                                    />        
                                    <TextField 
                                        id="gMiddleName" 
                                        name="gMiddleName" 
                                        label="Middle Name" 
                                        onBlur={(e, selectedOption) => { this.changeHandler("gMiddleName" ,e.target.value) }} 
                                        fullWidth
                                        disabled={!this.state.checkedEnableGuardianInput}
                
                                        error={!this.state.gMiddleNameIsValid.status && this.state.gMiddleNameIsValid.focused}
                                        helperText={this.state.gMiddleNameIsValid.message} 
                                    /> 
                                    <TextField 
                                        id="gRelationship" 
                                        name="gRelationship" 
                                        label="Relationship" 
                                        onBlur={(e, selectedOption) => { this.changeHandler("gRelationship" ,e.target.value) }} 
                                        fullWidth
                                        disabled={!this.state.checkedEnableGuardianInput}
                                        required={this.state.checkedEnableGuardianInput}
                                        error={!this.state.gRelationshipIsValid.status && this.state.gRelationshipIsValid.focused}
                                        helperText={this.state.gRelationshipIsValid.message} 
                                    /> 
                                    <TextField 
                                        id="gOccupation" 
                                        name="gOccupation" 
                                        label="Occupation" 
                                        onBlur={(e, selectedOption) => { this.changeHandler("gOccupation" ,e.target.value) }} 
                                        fullWidth
                                        disabled={!this.state.checkedEnableGuardianInput}
                                        required={this.state.checkedEnableGuardianInput} 
                                        error={!this.state.gOccupationIsValid.status && this.state.gOccupationIsValid.focused}
                                        helperText={this.state.gOccupationIsValid.message}
                                    /> 
                                    
                                    <Autocomplete 
                                        id="gAnnualIncome"
                                        name="gAnnualIncome"
                                        options={this.state.annualIncomeOptions.map( (incomeBracket) => {
                                            let formatter = new Intl.NumberFormat('en-US', {
                                                style: 'currency',
                                                currency: 'PHP'
                                            });
                                            return {
                                                ...incomeBracket,
                                                income_range: `${formatter.format(incomeBracket.income_from)} - ${formatter.format(incomeBracket.income_to)}`
                                            }
                                        })}
                                        getOptionLabel={(option) => option.income_range.toString()}
                                        fullWidth
                                        disabled={!this.state.checkedEnableGuardianInput}
                                        required={this.state.checkedEnableGuardianInput}
                                        onChange={(e, selectedOption) => { 
                                            if(selectedOption) 
                                                this.changeHandler("gAnnualIncome", selectedOption.income_id,selectedOption.income_range.toString()) 
                                            else
                                                this.changeHandler("gAnnualIncome", '','')
                                        }}

                                        // onBlur={(e, selectedOption) => { 
                                        //     if(selectedOption) 
                                        //         this.changeHandler("gAnnualIncome", selectedOption.income_id,selectedOption.income_range.toString()) 
                                        //     else
                                        //         this.changeHandler("gAnnualIncome", '','')
                                        // }}
                                        renderInput={(params) => <TextField {...params} label="Annual Income" 
                                            error={!this.state.gAnnualIncomeIsValid.status && this.state.gAnnualIncomeIsValid.focused}
                                            helperText={this.state.gAnnualIncomeIsValid.message}
                                        />}
                                    />
                                </CardContent>
                                </Card>
                        </Grid>
                </Grid>
                
                {/* Educational Background */}
                <Grid item container xs={12} sm={12}>
                    <Container maxWidth="lg" className={classes.sectionHeader}><Typography variant="h6" fullWidth>Educational Background</Typography></Container>
                    {/* <Container style={{display: 'flex'}}> */}
                        <Grid item xs={12} sm={this.state.applicationType === '2' ? 3 : 4}>
                            <Card>
                                <CardHeader title="Elementary" />
                                <CardContent>
                                    <FormControl component="fieldset">
                                        <RadioGroup 
                                            row aria-label="elemSchoolType" 
                                            id="elemSchoolType" 
                                            name="elemSchoolType" 
                                            value={this.state.elemSchoolType} 
                                            onChange={(e, selectedOption) => { this.changeHandler("elemSchoolType",e.target.value) }}
                                        >
                                            <FormControlLabel value="Public" control={<Radio />} label="Public" />
                                            <FormControlLabel value="Private" control={<Radio />} label="Private" />
                                        </RadioGroup>
                                    </FormControl>
                                    <TextField 
                                        id="elemSchool" 
                                        name="elemSchool" 
                                        label="Name of School" 
                                        onBlur={(e, selectedOption) => { this.changeHandler("elemSchool",e.target.value) }} 
                                        fullWidth
                                        error={!this.state.elemSchoolIsValid.status && this.state.elemSchoolIsValid.focused}
                                        helperText={this.state.elemSchoolIsValid.message}
                                        required 
                                    />             
                                    {/* <TextField 
                                        id="elemYearGraduated" 
                                        name="elemYearGraduated" 
                                        label="Year Graduated" 
                                        onBlur={(e, selectedOption) => { this.changeHandler("elemYearGraduated",e.target.value) }} 
                                        fullWidth 
                                        error={!this.state.elemYearGraduatedIsValid.status && this.state.elemYearGraduatedIsValid.focused}
                                        helperText={this.state.elemYearGraduatedIsValid.message}
                                        required 
                                    />  */}
                                    <Autocomplete
                                        id="elemYearGraduated" 
                                        name="elemYearGraduated" 
                                        options={yearGraduatedOptions}
                                        onChange={(e, selectedOption) => { try{ this.changeHandler("elemYearGraduated",selectedOption ? selectedOption : '') }catch(err){} }}
                                        onBlur={(e, selectedOption) => { 
                                            try{ 
                                                if(!e.target.value) this.changeHandler("elemYearGraduated",selectedOption ? selectedOption : '' )  
                                            }catch(err){
                                                
                                            } 
                                        }}                                            
                                        fullWidth
                                        renderInput={(params) => 
                                            <TextField {...params}  
                                                label="Year Graduated" 
                                                error={!this.state.elemYearGraduatedIsValid.status && this.state.elemYearGraduatedIsValid.focused}
                                                helperText={this.state.elemYearGraduatedIsValid.message}
                                                required 
                                            />}
                                    />
                                    <TextField 
                                        id="elemAddress" 
                                        name="elemAddress" 
                                        label="Address" 
                                        onBlur={(e, selectedOption) => { this.changeHandler("elemAddress",e.target.value) }} 
                                        fullWidth
                                        error={!this.state.elemAddressIsValid.status && this.state.elemAddressIsValid.focused}
                                        helperText={this.state.elemAddressIsValid.message}
                                        required 
 
                                    /> 
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={this.state.applicationType === '2' ? 3 : 4}>
                            <Card>
                                <CardHeader title="Junior High School" />
                                <CardContent>
                                    <FormControl component="fieldset">
                                        <RadioGroup 
                                            row aria-label="jhSchoolType" 
                                            id="jhSchoolType" 
                                            name="jhSchoolType" 
                                            value={this.state.jhSchoolType} 
                                            onChange={(e, selectedOption) => { this.changeHandler("jhSchoolType",e.target.value) }} 
                                        >
                                            <FormControlLabel value="Public" control={<Radio />} label="Public" />
                                            <FormControlLabel value="Private" control={<Radio />} label="Private" />
                                        </RadioGroup>
                                    </FormControl>
                                    <TextField 
                                        id="jhSchool" 
                                        name="jhSchool" 
                                        label="Name of School" 
                                        onBlur={(e, selectedOption) => { this.changeHandler("jhSchool",e.target.value) }}  
                                        fullWidth 
                                        error={!this.state.jhSchoolIsValid.status && this.state.jhSchoolIsValid.focused}
                                        helperText={this.state.jhSchoolIsValid.message} 
                                        required
                                    />              
                                    {/* <TextField 
                                        id="jhYearGraduated" 
                                        name="jhYearGraduated" 
                                        label="Year Graduated" 
                                        onBlur={(e, selectedOption) => { this.changeHandler("jhYearGraduated",e.target.value) }} 
                                        fullWidth
                                        error={!this.state.jhYearGraduatedIsValid.status && this.state.jhYearGraduatedIsValid.focused}
                                        helperText={this.state.jhYearGraduatedIsValid.message} 
                                        required
                                    /> */}
                                    <Autocomplete
                                        id="jhYearGraduated" 
                                        name="jhYearGraduated" 
                                        options={yearGraduatedOptions}
                                        onChange={(e, selectedOption) => { try{ this.changeHandler("jhYearGraduated",selectedOption ? selectedOption : '') }catch(err){} }}
                                        onBlur={(e, selectedOption) => { 
                                            try{ 
                                                if(!e.target.value) this.changeHandler("jhYearGraduated",selectedOption ? selectedOption : '' )  
                                            }catch(err){
                                                
                                            } 
                                        }}                                            
                                        fullWidth
                                        renderInput={(params) => 
                                            <TextField {...params}  
                                                label="Year Graduated" 
                                                error={!this.state.jhYearGraduatedIsValid.status && this.state.jhYearGraduatedIsValid.focused}
                                                helperText={this.state.jhYearGraduatedIsValid.message}
                                                required 
                                            />}
                                    /> 
                                    <TextField 
                                        id="jhAddress" 
                                        name="jhAddress" 
                                        label="Address" 
                                        onBlur={(e, selectedOption) => { this.changeHandler("jhAddress",e.target.value) }} 
                                        fullWidth
                                        error={!this.state.jhAddressIsValid.status && this.state.jhAddressIsValid.focused}
                                        helperText={this.state.jhAddressIsValid.message} 
                                        required
                                    /> 
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={this.state.applicationType === '2' ? 3 : 4}>
                            <Card>
                                <CardHeader title="Senior High School" />
                                <CardContent>
                                    <FormControl component="fieldset">
                                        <RadioGroup 
                                            row aria-label="shSchoolType" 
                                            id="shSchoolType" 
                                            name="shSchoolType" 
                                            value={this.state.shSchoolType} 
                                            onChange={(e, selectedOption) => { this.changeHandler("shSchoolType",e.target.value) }} 
                                            >
                                            <FormControlLabel value="Public" control={<Radio />} label="Public" />
                                            <FormControlLabel value="Private" control={<Radio />} label="Private" />
                                        </RadioGroup>
                                    </FormControl>
                                    <TextField 
                                        id="shSchool" 
                                        name="shSchool" 
                                        label="Name of School" 
                                        onBlur={(e, selectedOption) => { this.changeHandler("shSchool",e.target.value) }} 
                                        fullWidth
                                        error={!this.state.shSchoolIsValid.status && this.state.shSchoolIsValid.focused}
                                        helperText={this.state.shSchoolIsValid.message} 
                                        required
                                    />             
                                    {/* <TextField 
                                        id="shYearGraduated" 
                                        name="shYearGraduated" 
                                        label="Year Graduated" 
                                        onBlur={(e, selectedOption) => { this.changeHandler("shYearGraduated",e.target.value) }} 
                                        fullWidth
                                        error={!this.state.shYearGraduatedIsValid.status && this.state.shYearGraduatedIsValid.focused}
                                        helperText={this.state.shYearGraduatedIsValid.message} 
                                        required
                                    /> */}
                                    <Autocomplete
                                        id="shYearGraduated" 
                                        name="shYearGraduated" 
                                        options={yearGraduatedOptions}
                                        onChange={(e, selectedOption) => { try{ this.changeHandler("shYearGraduated",selectedOption ? selectedOption : '') }catch(err){} }}
                                        onBlur={(e, selectedOption) => { 
                                            try{ 
                                                if(!e.target.value) this.changeHandler("shYearGraduated",selectedOption ? selectedOption : '' )  
                                            }catch(err){
                                                
                                            } 
                                        }}                                            
                                        fullWidth
                                        renderInput={(params) => 
                                            <TextField {...params}  
                                                label="Year Graduated" 
                                                error={!this.state.shYearGraduatedIsValid.status && this.state.shYearGraduatedIsValid.focused}
                                                helperText={this.state.shYearGraduatedIsValid.message}
                                                required 
                                            />}
                                    /> 
                                    <TextField 
                                        id="shAddress" 
                                        name="shAddress" 
                                        label="Address" 
                                        onBlur={(e, selectedOption) => { this.changeHandler("shAddress",e.target.value) }} 
                                        fullWidth
                                        error={!this.state.shAddressIsValid.status && this.state.shAddressIsValid.focused}
                                        helperText={this.state.shAddressIsValid.message} 
                                        required
                                    /> 
                                </CardContent>
                            </Card>
                        </Grid>
                        {this.state.applicationType === '2' ? collegeInfoForTransferee : null}
                    {/* </Container> */}
                </Grid>

                {/* Scholastic Background */}
                <Grid item container xs={12} sm={12}>
                    <Container maxWidth="lg" className={classes.sectionHeader}><Typography variant="h6" fullWidth>Scholastic Background</Typography></Container>
                    {/* <Container>     */}
                    {finalGradesInputFields}
                    <Grid item xs={12} sm={this.state.applicationType === '1' ? 6 : 12}>
                        <Card>
                            <CardHeader title="Grade Point Average/General Average"
                                subheader={<Button style={{fontSize:"12px"}}  onClick={this.handleGradeAverageInputClick}>How to input GPA/GA</Button>
                            }
                            />
                            <CardContent>
                                {/* <Button style={{fontSize:"12px",marginTop:"-52px"}}  onClick={this.handleGradeAverageInputClick}>How to input GPA/GA</Button> */}
                                {gpaGradeInputFields}  
                            </CardContent>
                        </Card>
                    </Grid>
                    {shTrackInputFields}
                </Grid>

                {/* Course/Program Information */}
                <Grid item container xs={12} sm={12}>
                    <Container maxWidth="lg" className={classes.sectionHeader}><Typography variant="h6">Course/Program Information</Typography></Container>
                        {/* <Container style={{display: 'flex'}}> */}
                            <Grid item xs={12} sm={4}>
                                <Card>
                                    <CardHeader title="First Preference" />
                                    <CardContent>
                                        <Autocomplete 
                                            id="campus1"
                                            name="campus1"
                                            options={campusOptions}
                                            getOptionLabel={(option) => option.type}
                                            fullWidth
                                            onChange={(e, selectedOption) => { this.changeHandler("campus1", selectedOption ? selectedOption.value : '', selectedOption ? selectedOption.type : '') }}
                                            onBlur={(e, selectedOption) => { 
                                                if(!e.target.value) this.changeHandler("campus1",selectedOption ? selectedOption.value : '') 
                                            }}
                                            renderInput={(params) => 
                                                <TextField {...params} 
                                                    required label="Campus" 
                                                    error={!this.state.campus1IsValid.status && this.state.campus1IsValid.focused}
                                                    helperText={this.state.campus1IsValid.message} 
                                                />}
                                        />
                                        <Autocomplete
                                            id="course1"
                                            name="course1"
                                            value={this.state.course1Obj}
                                            inputValue={this.state.course1InputVal}
                                            options={this.state.coursesOptions.filter( (course) => {
                                                return course.CampusID == this.state.campus1;
                                            })}
                                            getOptionLabel={(option) => option.ProgName}
                                            fullWidth
                                            // onChange={(e, selectedOption) => { this.changeHandler("course1", selectedOption ? selectedOption.ProgID : '', selectedOption ? selectedOption.ProgName : '') }}
                                            onChange={(e, selectedOption) => { 
                                                this.changeHandler("course1Obj", selectedOption);
                                                this.changeHandler("course1", selectedOption ? selectedOption.ProgID : '', selectedOption ? selectedOption.ProgName : ''); 
                                            }}
                                            onInputChange={(e, inputValue) => { 
                                                this.changeHandler("course1InputVal", inputValue) } 
                                            }
                                            onBlur={(e, selectedOption) => { 
                                                if(!e.target.value) this.changeHandler("course1",selectedOption ? selectedOption.ProgID : '') 
                                            }}
                                            renderInput={(params) => 
                                                <TextField {...params} 
                                                    required label="Course" 
                                                    error={!this.state.course1IsValid.status && this.state.course1IsValid.focused}
                                                    helperText={this.state.course1IsValid.message} 
                                                />}
                                        />
                                        <Autocomplete
                                            id="major1"
                                            name="major1"
                                            value={this.state.major1Obj}
                                            inputValue={this.state.major1InputVal}
                                            options={this.state.courseMajorOptions.filter( (course) => {
                                                return course.ProgID == this.state.course1;
                                            })}
                                            getOptionLabel={(option) => option.Major}
                                            fullWidth
                                            disabled={!this.state.isMajor1Required}
                                            // onChange={(e, selectedOption) => { this.changeHandler("major1", selectedOption ? selectedOption.MajorID : '', selectedOption ? selectedOption.Major : '') }}
                                            onChange={(e, selectedOption) => { 
                                                this.changeHandler("major1Obj", selectedOption); 
                                                this.changeHandler("major1", selectedOption ? selectedOption.MajorID : '', selectedOption ? selectedOption.Major : '');
                                            }}
                                            onInputChange={(e, inputValue) => { 
                                                this.changeHandler("major1InputVal", inputValue) } 
                                            }
                                            onBlur={(e, selectedOption) => { 
                                                if(!e.target.value) this.changeHandler("major1",selectedOption ? selectedOption.MajorID : '') 
                                            }}
                                            renderInput={(params) => 
                                                <TextField {...params} 
                                                    required={this.state.isMajor1Required} label="Major" 
                                                    error={!this.state.major1IsValid.status && this.state.major1IsValid.focused}
                                                    helperText={this.state.major1IsValid.message} 
                                                />}

                                        />

                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Card>
                                    <CardHeader title="Second Preference" />
                                    <CardContent>
                                        <Autocomplete 
                                            id="campus2"
                                            name="campus2"
                                            options={campusOptions}
                                            getOptionLabel={(option) => option.type}
                                            fullWidth
                                            onChange={(e, selectedOption) => { this.changeHandler("campus2", selectedOption ? selectedOption.value : '', selectedOption ? selectedOption.type : '') }}
                                            onBlur={(e, selectedOption) => { 
                                                if(!e.target.value) this.changeHandler("campus2",selectedOption ? selectedOption.value : '') 
                                            }}
                                            renderInput={(params) => 
                                                <TextField {...params} 
                                                    required label="Campus" 
                                                    error={!this.state.campus2IsValid.status && this.state.campus2IsValid.focused}
                                                    helperText={this.state.campus2IsValid.message} 
                                                />}
                                        />
                                        <Autocomplete
                                            id="course2"
                                            name="course2"
                                            value={this.state.course2Obj}
                                            inputValue={this.state.course2InputVal}
                                            options={this.state.coursesOptions.filter( (course) => {
                                                return course.CampusID == this.state.campus2;
                                            })}
                                            getOptionLabel={(option) => option.ProgName}
                                            fullWidth
                                            // onChange={(e, selectedOption) => { this.changeHandler("course2", selectedOption ? selectedOption.ProgID : '', selectedOption ? selectedOption.ProgName : '') }}
                                            onChange={(e, selectedOption) => { 
                                                this.changeHandler("course2Obj", selectedOption); 
                                                this.changeHandler("course2", selectedOption ? selectedOption.ProgID : '', selectedOption ? selectedOption.ProgName : '');
                                            }}
                                            onInputChange={(e, inputValue) => { 
                                                this.changeHandler("course2InputVal", inputValue) } 
                                            }
                                            renderInput={(params) => 
                                                <TextField {...params} 
                                                    required label="Course" 
                                                    error={!this.state.course2IsValid.status && this.state.course2IsValid.focused}
                                                    helperText={this.state.course2IsValid.message} 
                                                />}
                    
                                        />
                                        <Autocomplete
                                            id="major2"
                                            name="major2"
                                            value={this.state.major2Obj}
                                            inputValue={this.state.major2InputVal}
                                            options={this.state.courseMajorOptions.filter( (course) => {
                                                return course.ProgID == this.state.course2;
                                            })}
                                            getOptionLabel={(option) => option.Major}
                                            fullWidth
                                            disabled={!this.state.isMajor2Required}
                                            // onChange={(e, selectedOption) => { this.changeHandler("major2", selectedOption ? selectedOption.MajorID : '', selectedOption ? selectedOption.Major : '') }}
                                            onChange={(e, selectedOption) => { 
                                                this.changeHandler("major2Obj", selectedOption); 
                                                this.changeHandler("major2", selectedOption ? selectedOption.MajorID : '', selectedOption ? selectedOption.Major : '');
                                            }}
                                            onInputChange={(e, inputValue) => { 
                                                this.changeHandler("major2InputVal", inputValue) } 
                                            }
                                            onBlur={(e, selectedOption) => { 
                                                if(!e.target.value) this.changeHandler("major2",selectedOption ? selectedOption.MajorID : '') 
                                            }}
                                           
                                            renderInput={(params) => 
                                                <TextField {...params} 
                                                    required={this.state.isMajor2Required} label="Major" 
                                                    error={!this.state.major2IsValid.status && this.state.major2IsValid.focused}
                                                    helperText={this.state.major2IsValid.message} 
                                                />}
                                        />        
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Card>
                                    <CardHeader title="Third Preference" />
                                    <CardContent>
                                    <Autocomplete 
                                            id="campus3"
                                            name="campus3"
                                            options={campusOptions}
                                            getOptionLabel={(option) => option.type}
                                            fullWidth
                                            onChange={(e, selectedOption) => { this.changeHandler("campus3", selectedOption ? selectedOption.value : '', selectedOption ? selectedOption.type : '') }}
                                            onBlur={(e, selectedOption) => { 
                                                if(!e.target.value) this.changeHandler("campus3",selectedOption ? selectedOption.value : '') 
                                            }}
                                            renderInput={(params) => 
                                                <TextField {...params} 
                                                    required label="Campus" 
                                                    error={!this.state.campus3IsValid.status && this.state.campus3IsValid.focused}
                                                    helperText={this.state.campus3IsValid.message} 
                                                />}
                                        />
                                        <Autocomplete
                                            id="course3"
                                            name="course3"
                                            value={this.state.course3Obj}
                                            inputValue={this.state.course3InputVal}
                                            options={this.state.coursesOptions.filter( (course) => {
                                                return course.CampusID == this.state.campus3;
                                            })}
                                            getOptionLabel={(option) => option.ProgName}
                                            fullWidth
                                            // onChange={(e, selectedOption) => { this.changeHandler("course3", selectedOption ? selectedOption.ProgID : '', selectedOption ? selectedOption.ProgName : '') }}
                                            onChange={(e, selectedOption) => { 
                                                this.changeHandler("course3Obj", selectedOption) 
                                                this.changeHandler("course3", selectedOption ? selectedOption.ProgID : '', selectedOption ? selectedOption.ProgName : '');
                                            }}
                                            onInputChange={(e, inputValue) => { 
                                                this.changeHandler("course3InputVal", inputValue) } 
                                            }
                                            onBlur={(e, selectedOption) => { 
                                                if(!e.target.value) this.changeHandler("course3",selectedOption ? selectedOption.ProgID : '') 
                                            }}
                                            renderInput={(params) => 
                                                <TextField {...params} 
                                                    required label="Course" 
                                                    error={!this.state.course3IsValid.status && this.state.course3IsValid.focused}
                                                    helperText={this.state.course3IsValid.message} 
                                                />}
                    
                                        />
                                        <Autocomplete
                                            id="major3"
                                            name="major3"
                                            value={this.state.major3Obj}
                                            inputValue={this.state.major3InputVal}
                                            options={this.state.courseMajorOptions.filter( (course) => {
                                                return course.ProgID == this.state.course3;
                                            })}
                                            getOptionLabel={(option) => option.Major}
                                            fullWidth
                                            disabled={!this.state.isMajor3Required}
                                            // onChange={(e, selectedOption) => { this.changeHandler("major3", selectedOption ? selectedOption.MajorID : '', selectedOption ? selectedOption.Major : '') }}
                                            onChange={(e, selectedOption) => { 
                                                this.changeHandler("major3Obj", selectedOption); 
                                                this.changeHandler("major3", selectedOption ? selectedOption.MajorID : '', selectedOption ? selectedOption.Major : '');
                                            }}
                                            onInputChange={(e, inputValue) => { 
                                                this.changeHandler("major3InputVal", inputValue) } 
                                            }
                                            onBlur={(e, selectedOption) => { 
                                                if(!e.target.value) this.changeHandler("major3",selectedOption ? selectedOption.MajorID : '') 
                                            }}
                                            renderInput={(params) => 
                                                <TextField {...params} 
                                                    required={this.state.isMajor3Required} label="Major" 
                                                    error={!this.state.major3IsValid.status && this.state.major3IsValid.focused}
                                                    helperText={this.state.major3IsValid.message} 
                                                />}
                                        />         
                                    </CardContent>
                                </Card>
                            </Grid>
                        {/* </Container> */}
                </Grid>

                {/* Emergency Contact */}
                <Grid item xs={12} sm={12}>
                    <Container maxWidth="lg" className={classes.sectionHeader}>
                        <Typography variant="h6">Person To Contact In Case of Emergency</Typography>
                    </Container>
                    <Container>
                        <TextField 
                            id="emergencyContactName" 
                            name="emergencyContactName" 
                            label="Name" 
                            onBlur={(e) => { this.changeHandler("emergencyContactName",e.target.value) }}  
                            fullWidth
                            error={!this.state.emergencyContactNameIsValid.status && this.state.emergencyContactNameIsValid.focused}
                            helperText={this.state.emergencyContactNameIsValid.message} 
                            required
                        />
                        <TextField 
                            id="emergencyContactRelation" 
                            name="emergencyContactRelation" 
                            label="Relationship" 
                            onBlur={(e) => { this.changeHandler("emergencyContactRelation",e.target.value) }}  
                            fullWidth 
                            error={!this.state.emergencyContactRelationIsValid.status && this.state.emergencyContactRelationIsValid.focused}
                            helperText={this.state.emergencyContactRelationIsValid.message} 
                            required

                        />
                        <TextField 
                            id="emergencyContactAddress" 
                            name="emergencyContactAddress" 
                            label="Address" 
                            onBlur={(e) => { this.changeHandler("emergencyContactAddress",e.target.value) }}  
                            fullWidth
                            error={!this.state.emergencyContactAddressIsValid.status && this.state.emergencyContactAddressIsValid.focused}
                            helperText={this.state.emergencyContactAddressIsValid.message} 
                            required
 
                        />
                        <TextField 
                            id="emergencyContactNumber" 
                            name="emergencyContactNumber" 
                            label="Contact Number" 
                            onBlur={(e) => { this.changeHandler("emergencyContactNumber",e.target.value) }}  
                            fullWidth
                            error={!this.state.emergencyContactNumberIsValid.status && this.state.emergencyContactNumberIsValid.focused}
                            helperText={this.state.emergencyContactNumberIsValid.message} 
                            required
 
                        />
                    </Container>
                </Grid>
                
                {/* Document Upload */}
                <Grid item container xs={12} sm={12}>
                    <Container maxWidth="lg" alignItems="flex-start" className={classes.sectionHeader}><Typography variant="h6">Document Upload</Typography></Container>
                    <Container>
                        <Typography variant="body1" align="justify">
                            <span style={{fontWeight:"bold",color:"maroon"}}>GENERAL GUIDELINES:</span> 
                            &nbsp; Uploaded files must be <span style={{fontWeight:"bold"}}>clear</span>, <span style={{fontWeight:"bold"}}>legible</span> and <span style={{fontWeight:"bold"}}>complete</span>. 
                            The University reserves the right to <span style={{fontWeight:"bold",color:"maroon"}}>discard applications</span>  with <span style={{fontWeight:"bold",color:"maroon"}}>blurry</span>,  
                            <span style={{fontWeight:"bold",color:"maroon"}}> unrecognizable</span> and/or <span style={{fontWeight:"bold",color:"maroon"}}>incomplete</span> submitted documents. 
                            Please review your documents meticulously before submitting your application.
                            If you still have lacking documents, please DO NOT submit an application yet. 
                            <span style={{fontWeight:"bold"}}>You may only submit ONE application</span>.

                        </Typography>
                    </Container>
                    {this.state.applicationType === '1' ? form137Upload : ToRUpload }
                    <Grid item xs={12} sm={(this.state.isMemberOfIP ==='0') ? 4 : 3}>
                        <Card>
                            <CardHeader 
                                action={
                                    this.state.file_incomeSourceIsValid.status ?
                                    <CheckCircleIcon style={{fill:"green"}}  />
                                    : null
                                }
                                title="Income Source"
                                subheader= {this.state.file_incomeSourceIsValid.status ? 
                                    <Typography>Uploaded</Typography>
                                    :
                                    <React.Fragment>
                                        <Typography>Required</Typography> 
                                        <Typography><span style={{fontStyle:"italic",color:"maroon"}}>Important:</span>
                                            &nbsp; Please make sure that the uploaded file coincides with <span style={{fontWeight:"bold"}}>ALL</span> the Annual Income data entered in the 
                                            Family Background section of this form. 
                                        </Typography>
                                    </React.Fragment> 
                                }  
                            />
                            <CardContent>
                                <input
                                    id="file_incomeSource"
                                    accept="pdf/*"
                                    type="file"
                                    onChange={(e) => { this.changeHandler("file_incomeSource",e.target.files[0])}}
                                    hidden
                                />
                                <label htmlFor="file_incomeSource">
                                    <Button
                                        variant="contained"
                                        component="span"
                                        
                                    >Upload PDF File</Button>
                                </label>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={(this.state.isMemberOfIP ==='0') ? 4 : 3}>
                         <Card>
                            <CardHeader 
                                action={
                                    this.state.file_applicantSignatureIsValid.status ?
                                    <CheckCircleIcon style={{fill:"green"}}  />
                                    : null
                                }
                                title="Applicant's Signature" 
                                subheader={this.state.file_applicantSignatureIsValid.status ? "Uploaded": "Required" }
                                />
                            <CardContent>
                                <input
                                    id="file_applicantSignature"
                                    accept="pdf/*"
                                    type="file"
                                    onChange={(e) => { this.changeHandler("file_applicantSignature",e.target.files[0])}}
                                    hidden
                                />
                                <label htmlFor="file_applicantSignature">
                                    <Button
                                        variant="contained"
                                        component="span"
                                        
                                    >Upload Photo</Button>
                                </label>
                            </CardContent>
                        </Card>
                    </Grid>

                    {this.state.isMemberOfIP === '1' ? IPMembershipCertUpload : null}
    
                </Grid>

            <Container align="center" style={{display: 'flex'}}>
                <Button 
                    variant="contained" align="center" color="primary" 
                    onClick={this.handleReviewFormClick}
                    style={{margin:"15px auto",borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} >
                        Review</Button>        
            </Container>
            
            {/* Landing Page Dialog */}
            <Dialog fullScreen open={this.state.firstPageLoad} >
                <DialogContent style={{textAlign: "center"}}>
                    
                    {this.state.isAdmissionOpen ?
                    (
                    <React.Fragment>
                        <Container maxWidth="sm">
                            <img src={usepLogoHeader} style={{width:"20%",display:"block",margin:"0 auto"}} />
                            <img src={landingImage} style={{width:"70%",marginTop:"-22px"}} />
                            <Typography variant="h6" style={{fontSize:"1.5rem"}}>Welcome!</Typography>
                            <Typography variant="h6" style={{fontSize:"1rem"}}>to the</Typography>
                            <Typography variant="h6" style={{fontSize:"1.4rem",color:"#d75d5f"}}>University of Southeastern Philippines</Typography>
                            <Typography variant="h6" style={{marginTop:"-7px"}}>Online Application for Admission</Typography>
                            <Typography variant="h6"  style={{marginTop:"10px",fontSize:"0.9rem",fontStyle:"italic"}}>
                                Applicable to local undergraduate applicants only. <br/>
                                Foreign undergraduate applicants should refer to this <a target="_blank" href="https://www.usep.edu.ph/wp-content/uploads/2020/06/FS-Application-Form-V4.pdf" style={{fontWeight:"bold",textDecoration:"underline",color:"maroon"}}>form</a> 
                            </Typography> 
                            {/* <Typography variant="h6" style={{marginTop:"-7px",fontSize:"0.9rem",fontStyle:"italic"}}>
                                For foreign undergraduate applicants, please refer to this <a target="_blank" href="https://www.usep.edu.ph/wp-content/uploads/2020/06/FS-Application-Form-V4.pdf" style={{fontWeight:"bold",textDecoration:"underline",color:"maroon"}}>form</a> 
                                </Typography> */}
                        </Container>
                        <Container maxWidth="sm" style={{marginTop:"25px",paddingBottom:"30px"}}>
                        
                            <Typography style={{marginBottom:"10px"}}>
                                For a better application experience, please make sure you already have the following
                                required documents before proceeding:
                            </Typography>
                            <List component="nav">
                                <ListItem button>
                                    <ListItemText primary="1. Scanned certified true copy of High School Form 137 (Permanent Record) in Grades 9, 10 and 11 (for Incoming First Year Students) OR Transcript of Records (for Transferees)" secondary="Required file type: PDF  |  Max file size: 2MB" />
                                </ListItem>
                                <ListItem button>
                                    <ListItemText primary="2. Passport size Digital Photo of you holding a handwritten Name Tag below your face" secondary="Required file type: JPG/PNG  |  Max file size: 2MB" />
                                    <Button onClick={() => {this.handleViewPhotoSample('withNameTag')} }>View Sample</Button>
                                </ListItem>
                                <ListItem button>
                                    <ListItemText primary="3. Digital Photo of you holding a valid ID (Barangay, Postal, Voter's, or School ID) card beside your face. " secondary="Required file type: JPG/PNG   |  Max file size: 2MB" />
                                    <Button onClick={() => {this.handleViewPhotoSample('withIDCard')} } >View Sample</Button>
                                </ListItem>
                                <ListItem button>
                                    <ListItemText primary="4. Digital Photo of your signature" secondary="Required file type: JPG/PNG   |  Max file size: 2MB"/>
                                </ListItem>
                                <ListItem button>
                                    <ListItemText primary="5.  Parents' or Guardian's or Your Own Income Tax Return document. Alternatively, you may submit a Certificate of Low Income document
                                    if the Income Tax Return document is not applicable. If there are two ITR/Certificate of Low Income documents applicable, (e.g. both parents have ITR/Cert of Low Income) please combine all the documents into one single PDF file." secondary="Required file type: PDF   |  Max file size: 2MB" />
                                </ListItem>
                                <ListItem button>
                                    <ListItemText primary="6. NCIP-issued Certificate of Membership to the Indigenous Peoples (for members of the Indigenous Peoples)" secondary="Required file type: PDF   |  Max file size: 2MB"/>
                                </ListItem>
                            </List>
                            <Typography variant="body1" align="justify">
                                If you have admission-related concerns, please email us at <a style={{color:"maroon",fontWeight:"bold"}}>admissionsunit@usep.edu.ph</a>. 
                                Should you encounter technical issues in using this website, please email <a style={{color:"maroon",fontWeight:"bold"}}>admissions-techsupport@usep.edu.ph</a>.
                            </Typography>
                            <Typography style={{marginTop:"10px",marginBottom:"10px"}}>
                                If you are ready, please select an application type to proceed.
                            </Typography>
                            <Button 
                                style={{margin:"0 20px", borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} 
                                onClick={() => this.handleCloseLandingPage('1')} >First Year</Button>
                            <Button 
                                style={{margin:"0 20px",borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} 
                                onClick={() => this.handleCloseLandingPage('2')} >Transferee</Button>
                    </Container>
                    </React.Fragment>
                    ) :
                    (
                        <Container maxWidth="sm">
                            <img src={usepLogoHeader} style={{width:"20%",display:"block",margin:"0 auto"}} />
                            <img src={landingImage} style={{width:"70%",marginTop:"-22px"}} />
                            <Typography variant="h6" style={{fontSize:"1.4rem",color:"#d75d5f"}}>
                                The application submission period has already ended.
                            </Typography>
                            {/* <Typography variant="h6" style={{fontSize:"1rem",color:"#d75d5f"}}>
                                We regret to inform you that due to some technical limitations, the results of the admissions application are not yet ready for release. 
                                Kindly continue to follow the official USeP website for updates.
                                <br/>
                                Thank you for your understanding.
                            </Typography> */}
                        </Container>
                    )}

                </DialogContent>
            </Dialog>
            
            {/* Data Privacy Dialog */}
            <Dialog fullScreen open={this.state.dataPrivacyLoad} >
                <DialogContent style={{textAlign: "center"}}>
                   
                    <Container maxWidth="lg">
                        <img src={dataPrivacyImage} style={{width:"70%"}} />
                        <Typography variant="h6" style={{fontSize:"1.4rem",color:"#d75d5f"}}>PRIVACY NOTICE</Typography>
                        <Typography variant="h6" style={{marginTop:"-7px"}}></Typography>
                    </Container>
                    <Container maxWidth="sm" style={{marginTop:"25px",paddingBottom:"30px"}}>
                        
                            <Typography align="justify" style={{marginBottom:"10px"}}>
                                Pursuant to the University of Southeastern Philippines (USeP) data privacy policy and the Data Privacy Act of 2012, we will collect and process the following personal information from you when you manually or electronically fill in this form:
                            </Typography>
                            <List component="nav">
                                <ListItem button style={{paddingTop:"0",paddingBottom:"0"}}>
                                    <ListItemText primary="Full name" />
                                </ListItem>
                                <ListItem button style={{paddingTop:"0",paddingBottom:"0"}}>
                                    <ListItemText primary="Date and Place of Birth" />
                                </ListItem>
                                <ListItem button style={{paddingTop:"0",paddingBottom:"0"}}>
                                    <ListItemText primary="Gender" />
                                </ListItem>
                                <ListItem button style={{paddingTop:"0",paddingBottom:"0"}}>
                                    <ListItemText primary="Marital Status" />
                                </ListItem>
                                <ListItem button style={{paddingTop:"0",paddingBottom:"0"}}>
                                    <ListItemText primary="Citizenship" />
                                </ListItem>
                                <ListItem button style={{paddingTop:"0",paddingBottom:"0"}}>
                                    <ListItemText primary="Present and Permanent Address" />
                                </ListItem>
                                <ListItem button style={{paddingTop:"0",paddingBottom:"0"}}>
                                    <ListItemText primary="Email Address" />
                                </ListItem>
                                <ListItem button style={{paddingTop:"0",paddingBottom:"0"}}>
                                    <ListItemText primary="Contact Number" />
                                </ListItem>
                                <ListItem button style={{paddingTop:"0",paddingBottom:"0"}}>
                                    <ListItemText primary="Family Background" />
                                </ListItem>
                                <ListItem button style={{paddingTop:"0",paddingBottom:"0"}}>
                                    <ListItemText primary="Scholastic and Educational Background" />
                                </ListItem>
                            </List>
                            <Typography align="justify" style={{marginTop:"10px",fontWeight:"bold"}}>
                                USE: The collected personal information will be utilized solely for student admission, profiling and CHEd reportorial requirement purposes. 
                            </Typography>
                            <Typography align="justify" style={{marginTop:"10px",fontWeight:"bold"}}>
                                PROTECTION MEASURE: These personal information shall be held in utmost confidentiality and shall not be transferred or divulged to other persons or entity without your express consent. Only authorized personnel can have access to this personal information. The Admissions Unit - Office of the University Registrar (AU-OUR) will only retain the collected personal data as long as necessary for the fulfilment of the purpose. 
                            </Typography>
                            <Typography align="justify" style={{marginTop:"10px"}}>
                                Click 'I Agree' if you agree to the collection and processing of your personal information.
                                Otherwise, choose 'I Disagree' to return to the previous page.
                            </Typography>
                            <Button 
                                style={{margin:"0 20px", borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} 
                                onClick={() => this.handleDataPrivacyAgreement(1)} >I Agree</Button>
                            <Button 
                                style={{margin:"0 20px",borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} 
                                onClick={() => this.handleDataPrivacyAgreement(0)} >I Disagree</Button>
                    </Container>
                    
                </DialogContent>
            </Dialog>
            
            {/* // View Sample Photo Modal */}
            <Dialog maxWidth="lg" onClose={this.handleViewPhotoSampleClose} open={this.state.viewSamplePhoto} >
                <DialogTitle>{this.state.selectedSamplePhoto === 'withNameTag' ? "Passport Size Photo w/ Name Tag" : "Photo w/ ID Card" }</DialogTitle>
                <DialogContent style={{textAlign:"center"}}>
                    <img src={this.state.selectedSamplePhoto === 'withNameTag' ? passportPhotoSample : idPhotoSample } 
                        width={this.state.selectedSamplePhoto === 'withNameTag' ? "250" : "350" } />
                    <DialogContentText>
                        {this.state.selectedSamplePhoto === 'withNameTag' ? 
                            "Please ensure that your name is legible." : 
                            "Please ensure that your face and name in the ID card are recognizable in the uploaded photo." }
                        
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleViewPhotoSampleClose} >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            {/* // Grade Subject Input Guide Modal */}
            <Dialog maxWidth="lg" onClose={this.handleGradeSubjectInputClosed} open={this.state.hasClickedGradeSubjectInput} >
                <DialogTitle>Subject Final Grades Input</DialogTitle>
                <DialogContent>
                    <img src={this.state.selectedGradeSubjectInput === 'english' ? gradeSubjectInputGuide : (this.state.selectedGradeSubjectInput === 'math' ? gradeSubjectInputGuideMath : gradeSubjectInputGuideScience)}  height="100%" width="100%" />
                    <DialogContentText>
                        
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Grid item container>
                    <Button style={{margin:"10px auto"}} size="large" variant="contained" onClick={this.handleGradeSubjectInputClosed}  color="primary">
                        OK
                    </Button>
                </Grid>
                    
                </DialogActions>
            </Dialog>

            {/* // Grade Avg Input Guide Modal */}
            <Dialog maxWidth="lg" onClose={this.handleGradeAverageInputClosed} open={this.state.hasClickedGradeAverageInput} >
                <DialogTitle>Grade Average Input</DialogTitle>
                <DialogContent>
                    <img src={this.state.applicationType === '1' ? gradeAvgInputGuide : gradeAvgInputGuideCollege } height="100%" width="100%" />
                    <DialogContentText>
                        
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleGradeAverageInputClosed} >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            {/* // Invalid Submission Modal */}
            <Dialog open={this.state.hasSubmittedInvalidForm} >
                <DialogTitle>Invalid Submission</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Invalid entries were found in your application form. Please check the following fields:
                        <br />
                        {invalidRequiredFields}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseInvalidSubmissionDialog}  color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Review Form Data Dialog */}
            <Dialog fullScreen open={this.state.hasClickedReviewForm} onClose={this.handleReviewFormClose} >
                <AppBar className={classes.modalAppBar} >
                    <Toolbar >
                        <IconButton edge="start" color="inherit" onClick={this.handleReviewFormClose} aria-label="close">
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography style={{textAlign:"center"}} variant="h6" className={classes.title}>
                            Review Application
                        </Typography>
                        {this.state.isReviewComplete ? 
                            <Button 
                                autoFocus 
                                disabled={!this.state.isReviewComplete} 
                                color="inherit" 
                                onClick={this.initialSubmitHandler}
                                style={{margin:"15px auto",borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} >
                                Submit
                            </Button>
                        : null }
                    </Toolbar>
                </AppBar>
                
                <Container maxWidth="md">
                    <Grid item container xs={12} sm={12} align="center" style={{textAlign:"center"}}>
                        
                        <HorizontalStepper 
                            steps={
                                (this.state.applicationType === '1' && this.state.isMemberOfIP === '0') ?
                                    reviewSteps :
                                (this.state.applicationType === '1' && this.state.isMemberOfIP === '1') ?
                                    reviewStepsIPMember :
                                (this.state.applicationType === '2' && this.state.isMemberOfIP === '0') ?
                                    reviewStepsTransferee : 
                                    reviewStepsIPMemberTransferee
                            }
                            onChange={this.handleReviewStepChange} 
                        />

                        {this.state.currentReviewStep === 1 ? 
                        (   <React.Fragment>       
                                <Grid item container xs={12} sm={3} style={{textAlign:"center"}} >
                                    <Paper style={{minHeight:"330px", minWidth:"100%"}}>
                                        <Typography variant="subtitle1">Applicant's Photo</Typography>
                                        {this.state.currDiplayedImgSrc ? ( 
                                            <img src={this.state.currDiplayedImgSrc} width="230px" height="330px" display="block" />
                                        ) : '' }
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={9}>
                            <div>
                                <Grid item container>
                                    <Grid item xs={12} sm={4}>
                                        <ListItem button>
                                            <ListItemText style={{fontSize:"1.5rem"}}  primary={this.state.lastName} secondary="Last Name" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <ListItem button>
                                            <ListItemText style={{fontSize:"1.5rem"}} primary={this.state.firstName} secondary="First Name" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <ListItem button>
                                            <ListItemText style={{fontSize:"1.5rem"}} primary={this.state.middleName} secondary="Middle Name" />
                                        </ListItem>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.birthdate.toString()} secondary="Date of Birth" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.birthplace} secondary="Place of Birth" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.gender} secondary="Sex" />
                                        </ListItem>
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={4}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.citizenshipTxt}  
                                            secondary="Citizenship" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={5}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.civilStatusTxt} secondary="Civil Status" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.religionTxt} secondary="Religion" />
                                        </ListItem>
                                    </Grid>

                                    <Grid item xs={12} sm={3}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.contactNumber} secondary="Contact Number" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.email} secondary="Email Address" />
                                        </ListItem>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        <ListItem button>
                                            <ListItemText primary={this.getFullCurrentAddress()} secondary="Current Address" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <ListItem button>
                                            <ListItemText primary={this.getFullPermAddress()} secondary="Permanent Address" />
                                        </ListItem>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <ListItem button>
                                            <ListItemText primary={this.getFullFatherName()} secondary="Father's Name" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.fOccupation} secondary="Father's Occupation" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.fAnnualIncomeTxt} secondary="Father's Annual Income" />
                                        </ListItem>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <ListItem button>
                                            <ListItemText primary={this.getFullMotherName()} secondary="Mother's Name" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.mOccupation} secondary="Mother's Occupation" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.mAnnualIncomeTxt} secondary="Mother's Annual Income" />
                                        </ListItem>
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={4}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.elemSchool} secondary="Elementary School" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.elemYearGraduated} secondary="Year Graduated" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.elemAddress} secondary="Address" />
                                        </ListItem>
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={4}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.jhSchool} secondary="Junior High School" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.jhYearGraduated} secondary="Year Graduated" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.jhAddress} secondary="Address" />
                                        </ListItem>
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={4}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.shSchool} secondary="Senior High School" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.shYearGraduated} secondary="Year Graduated" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.shAddress} secondary="Address" />
                                        </ListItem>
                                    </Grid>


                                    <Grid item xs={12} sm={4}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.grade9GradeEnglish} secondary="Grade 9|English" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.grade10GradeEnglish} secondary="Grade 10|English" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.grade11GradeEnglish} secondary="Grade 11|English" />
                                        </ListItem>
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={4}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.grade9GradeMath} secondary="Grade 9|Math" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.grade10GradeMath} secondary="Grade 10|Math" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.grade11GradeMath} secondary="Grade 11|Math" />
                                        </ListItem>
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={4}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.grade9GradeScience} secondary="Grade 9|Science" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.grade10GradeScience} secondary="Grade 10|Science" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.grade11GradeScience} secondary="Grade 11|Science" />
                                        </ListItem>
                                    </Grid>
                                    
                                    <Grid item xs={12} sm={this.state.collegeGPA ? 3 : 4}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.grade9GPA} secondary="Grade 9|GPA" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={this.state.collegeGPA ? 3 : 4}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.grade10GPA} secondary="Grade 10|GPA" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={this.state.collegeGPA ? 3 : 4}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.grade11GPA} secondary="Grade 11|GPA" />
                                        </ListItem>
                                    </Grid>
                                    {this.state.collegeGPA ? 
                                        <Grid item xs={12} sm={3}>
                                            <ListItem button>
                                                <ListItemText primary={this.state.collegeGPA} secondary="College|GPA" />
                                            </ListItem>
                                        </Grid>
                                    : null}

                                    <Grid item xs={12} sm={3}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.trackTxt} secondary="Senior HS Track" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.strandTxt} secondary="Senior HS Strand" />
                                        </ListItem>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.course1Txt} secondary="Course|1st Choice" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.major1Txt} secondary="Major" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.campus1Txt} secondary="Campus|1st Choice" />
                                        </ListItem>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.course2Txt} secondary="Course|2nd Choice" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.major2Txt} secondary="Major" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.campus2Txt} secondary="Campus|2nd Choice" />
                                        </ListItem>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.course3Txt} secondary="Course|3rd Choice" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.major3Txt} secondary="Major" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.campus3Txt} secondary="Campus|3rd Choice" />
                                        </ListItem>
                                    </Grid>

                                    <Grid item xs={12} sm={3}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.emergencyContactName} secondary="Emergency Contact" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.emergencyContactRelation} secondary="Relationship" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.emergencyContactAddress} secondary="Address" />
                                        </ListItem>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <ListItem button>
                                            <ListItemText primary={this.state.emergencyContactNumber} secondary="Contact Number" />
                                        </ListItem>
                                    </Grid>

                                </Grid>
                            </div>
                        </Grid>
                            </React.Fragment> 
                        ) : null }

                        { (this.state.currentReviewStep === 2 && this.state.applicationType === '1') ? 
                            <React.Fragment>
                                <Grid item container xs={12} sm={9} align="center" style={{textAlign:"center",margin:"0 auto"}}>
                                    {/* <iframe src={this.state.file_form137_src} height="1000px" width="100%"></iframe>  */}
                                    <PdfViewer url={this.state.file_form137_src} />
                                </Grid>
                            </React.Fragment>
                        : null}

                        { (this.state.currentReviewStep === 2 && this.state.applicationType === '2') ? 
                            <React.Fragment>
                                <Grid item container xs={12} sm={9} align="center" style={{textAlign:"center",margin:"0 auto"}}>
                                    {/* <iframe src={this.state.file_ToR_src} height="1000px" width="100%"></iframe>  */}
                                    <PdfViewer url={this.state.file_ToR_src} />
                                </Grid>
                            </React.Fragment>
                        : null}
                        
                        {this.state.currentReviewStep === 3 ? 
                            <React.Fragment>
                                <Grid item container xs={12} sm={9} align="center" style={{textAlign:"center",margin:"0 auto"}}>
                                    {/* <iframe src={this.state.file_incomeSource_src} height="1000px" width="100%"></iframe>  */}
                                    <PdfViewer url={this.state.file_incomeSource_src} />
                                </Grid>
                            </React.Fragment>
                        : null}

                        {this.state.currentReviewStep === 4 ? 
                            <React.Fragment>
                                <Grid item container xs={12} sm={9} align="center" style={{textAlign:"center",margin:"0 auto"}}>
                                    <Container maxWidth="md"><img src={this.state.file_applicantSignature_src} width="400px" /></Container> 
                                </Grid>
                            </React.Fragment>
                        : null}

                        { (this.state.currentReviewStep === 5 && this.state.isMemberOfIP === '1') ? 
                            <React.Fragment>
                                <Grid item container xs={12} sm={9} align="center" style={{textAlign:"center",margin:"0 auto"}}>
                                    <iframe src={this.state.file_IPmembershipCert_src} height="1000px" width="100%"></iframe> 
                                </Grid>
                            </React.Fragment>
                        : null}
                    
                    </Grid>
                </Container>
            </Dialog>           
                            
            {/* // Submission Confirmation Modal */}
            <Dialog open={this.state.hasConfirmedSubmission} >
                <DialogTitle>Confirm Submission</DialogTitle>
                <DialogContent>
                    <DialogContentText >
                        <Typography className={classes.confirmSubmissionMsg} paragraph variant="body1">{submitConfirmMsg1}</Typography>
                        {/* <Typography className={classes.confirmSubmissionMsg} paragraph variant="body1">{submitConfirmMsg2}</Typography> */}
                        {/* <Typography className={classes.confirmSubmissionMsg} paragraph variant="body1">{submitConfirmMsg3}</Typography> */}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={this.handleCancelSubmit} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.submitHandler} disabled={this.state.submitting} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>

            {/* // Submission Success Modal */}
            <Dialog open={this.state.isSubmissionSuccessful} >
                <DialogTitle align="center" style={{fontSize:"1.3rem"}} >
                    <CheckCircleIcon style={{fill:"green"}} />
                    <Typography variant="h6">Application Submitted Successfully</Typography>
                </DialogTitle>
                <DialogContent>
                    
                    <DialogContentText>
                        <Typography align="center" variant="subtitle1" displayInline>Application ID</Typography>
                            <Typography align="center" style={{color:"maroon"}} variant="h6" displayInline>{this.state.responseAppNo}</Typography>
                        <Typography align="center" variant="subtitle1" displayInline>Application Passcode</Typography>
                            <Typography align="center" style={{color:"maroon"}} variant="h6">{this.state.responsePassCode}</Typography>

                        <Box textAlign="center" style={{marginTop:"10px",marginBottom:"20px"}}>
                            <PDFDownloadLink 
                                document={<AppDocPdf 
                                    passCode={this.state.responsePassCode} 
                                    appNo={this.state.responseAppNo} 
                                    appName={`${this.state.lastName}, ${this.state.firstName} ${this.state.middleName} ${this.state.suffix}`} 
                                    imgSrc={this.state.file_passportPhotoImgSrc}
                                    headerLogo={usepLogoHeader}
                                    dateGenerated={format(new Date(), 'MM/dd/yyyy')}
                                    />} fileName="appdoc.pdf">
                                {({ blob, url, loading, error }) => {
                                    return (
                                        loading ? 'Loading document...' : 
                                            <div>
                                                <a id="hiddenPdf" href={url} style={{display:'none'}}> Download</a>
                                                <label htmlFor="hiddenPdf">
                                                    <Button align="center" variant="contained" style={{textDecoration:'none'}}>Download Application Document</Button>
                                                </label>
                                            </div>
                                    )
                                }}
                            </PDFDownloadLink>
                            {/* <Button align="center" variant="contained" onClick={this.handleAppDocDownload}>Download Application Document</Button> */}
                        </Box>
                        <Typography display="inline" style={{marginTop:"15px",fontWeight:"500",color:"maroon"}} variant="h6">IMPORTANT: </Typography>
                        <Typography display="inline" variant="body1" style={{fontSize:"1.1rem",fontWeight:"400",color:"black"}}>
                            Please take note of your <span style={{fontWeight:"bold"}}>Application ID</span> and <span style={{fontWeight:"bold"}}>Passcode</span> as these will be used in
                            accessing your individual application result in the future. These information are also found in the
                            downloadable admission application document. Please click the "Download" button to secure
                            an official copy of your admission application.
                        </Typography>
                        
                        <Typography style={{marginTop:"10px",fontWeight:"bold"}}>
                            For announcements and tips on how to access the official results of your application, please regularly 
                            visit this website <span style={{fontStyle:"italic",color:"maroon"}}>https://register.usep.edu.ph</span>
                        </Typography>
                        
                        <Divider style={{marginTop:"20px"}} />
                        
                        <Typography align="justify"  style={{fontWeight:"500",color:"#60c217"}} variant="h6">
                            Congratulations! </Typography>
                        <Typography align="justify"   variant="body1" style={{fontSize:"1.1rem",fontWeight:"400",color:"black",marginBottom:"15px"}}>
                             You have successfully submitted your application for admission to the 
                            University of Southeastern Philippines. You are one step closer to building your dreams without limits!
                        </Typography>
                        <Divider style={{marginTop:"20px"}} />
                    </DialogContentText>
                
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseSubmissionDialog}  color="primary">
                        Exit
                    </Button>
                </DialogActions>
            </Dialog>
            
            {/* System Usability Test Survey */}
            <Dialog
                open={this.state.showSystemUsabilityTestDialog}
            >
                <DialogTitle disableTypography style={{display: "flex",justifyContent: "space-between",alignItems: "center"}}>
                    <Typography variant="h6">System Usability Survey</Typography>
                    <IconButton onClick={this.handleCloseSystemUsabilityDialog}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography align="justify">
                        Please let us know of your experience in using the system. We would appreciate it if you could take
                        a quick System Usability Survey by clicking the button below. 
                        <br/>
                        Thank you very much.
                    </Typography>
                    <Container align="center" style={{display: 'flex'}}>
                        <Button style={{margin:"15px auto",borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSeX0vWKp5Bm1q0yy3TbUACm_Mu0Gp3lscNWMWgHQJMXDpaSFw/viewform?fbclid=IwAR1u1oMEc_AsEiC1bXPlugxyVVGJvf5HmwthkOjQGClAkLAgKsZNnTNMR44">
                            Take System Usability Survey
                        </Button>
                    </Container>
                </DialogContent>
            </Dialog>

            {/* Upload Photo Modal */}
            <Dialog 
                open={this.state.choosePhotoClicked}
                onClose={this.handleCloseChoosePhoto}
                fullWidth>
                <DialogTitle>
                    Upload Photo
                    <IconButton style={{textAlign:"right"}} onClick={this.handleCloseChoosePhoto} >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography variant="body1">
                            Upload two (2) photos of yourself. One with a handwritten nametag below your face; the other
                            with any valid Identification Card beside your face.
                        </Typography>
                        <Typography variant="body1">
                            <span style={{color:"green",fontWeight:"bold"}}>Tip:</span> &nbsp; If you are using your phone's camera, you may need to go to your camera's settings and set the picture quality to 'Standard' or 'Low' to fit the file size limit.
                        </Typography>
                        <Grid item container style={{textAlign:"center"}}>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="h6">Photo w/ Name Tag</Typography>
                                <div>
                                    <Button variant="outlined" onClick={this.handleChoosePassportPhotoClicked}>Upload</Button>
                                </div>
                                
                                {/* <input type="file" onChange={this.handlePassportPhotoChange} display="block" accept="image/*" /> */}
                                {this.state.file_passportPhoto ? ( 
                                    <img src={this.state.file_passportPhotoImgSrc} width="250px" height="350px" display="block" />
                                ) : '' } 
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="h6">Photo w/ ID Card</Typography>
                                <div>
                                    <Button variant="outlined" onClick={this.handleChooseIDPhotoClicked}>Upload</Button>
                                </div>
                                {/* <input type="file" onChange={this.handleIDPhotoChange} display="block" accept="image/*" /> */}
                                {this.state.file_idPhoto ? ( 
                                    <img src={this.state.file_idPhotoImgSrc} width="250px" height="350px" display="block" />
                                ) : '' } 
                            </Grid>
                        </Grid>                   
                        <Grid item container alignContent="center">
                            <Button 
                                style={{margin:"15px auto",padding:"5px 30px",borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}}  
                                onClick={this.handleSaveSelectedPhotos}>
                                    Save
                            </Button>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            
            {/* Invalid File Upload Modal */}
            <Dialog
                open={this.state.fileIsSelected}
                onClose={this.handleCloseFileSelection}
            >
                <DialogTitle>{this.state.uploadedFileInfo.title}</DialogTitle>
                <DialogContent>
                    <Typography>
                        {this.state.uploadedFileInfo.message}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Grid item container>
                        <Button style={{margin:"10px auto"}} size="large" variant="contained" onClick={this.handleCloseFileSelection}  color="primary">
                            OK
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>
            
            {/* Duplicate Applicant Modal */}
            <Dialog
                open={this.state.showDuplicateApplicantNotice}
                onClose={this.handleCloseDuplicateApplicantDialog}
            >
                <DialogTitle>Duplicate Application Error</DialogTitle>
                <DialogContent>
                    <Typography align="justify">
                        The name you entered already has an existing submitted application based on our records.
                        Each individual applicant may only submit one application for admission.
                    </Typography>
                    <Typography align="left">
                        If you believe this is a mistake, please contact us at admissionsunit@usep.edu.ph to discuss 
                        this matter. Thank you.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Grid item container>
                        <Button style={{margin:"10px auto"}} size="large" variant="contained" onClick={this.handleCloseDuplicateApplicantDialog}  color="primary">
                            OK
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>
            
            {/* Upload Passport Photo */}
            {this.state.choosePassportPhotoClicked ? 
                <PassportPhotoDialog 
                    choosePassportPhotoClicked={this.state.choosePassportPhotoClicked}
                    closeDialog={this.handleChoosePassportPhotoClosed}
                    file_passportPhoto={this.state.file_passportPhoto}
                    isReviewingPhoto={this.state.isReviewingPhoto}
                    handlePassportPhotoChange={this.handlePassportPhotoChange}
                    file_passportPhotoImgSrc={this.state.file_passportPhotoImgSrc}
                    handlePhotoReview={this.handlePhotoReview}
                    handlePhotoReviewClose={this.handlePhotoReviewClose}
                    handlePassportPhotoSave={this.handlePassportPhotoSave}
                />
            : null }

            {/* Upload ID Photo */}
            {this.state.chooseIDPhotoClicked ? 
                <IDPhotoDialog 
                    chooseIDPhotoClicked={this.state.chooseIDPhotoClicked}
                    closeDialog={this.handleChooseIDPhotoClosed}
                    file_idPhoto={this.state.file_idPhoto}
                    isReviewingPhoto={this.state.isReviewingPhoto}
                    handleIDPhotoChange={this.handleIDPhotoChange}
                    file_idPhotoImgSrc={this.state.file_idPhotoImgSrc}
                    handlePhotoReview={this.handlePhotoReview}
                    handlePhotoReviewClose={this.handlePhotoReviewClose}
                    handleIDPhotoSave={this.handleIDPhotoSave}
                />
            : null }
          
            <Backdrop open={this.state.submitting} style={{zIndex:"9000"}}>
                <CircularProgress style={{zIndex:"9000"}}/> 
            </Backdrop > 
    
            </Container>
        
        // </form>
        
        );
    }

};


// const mapStateToProps = state => {
//     return{
//         ctr: state.counter
//     };
// };

const mapDispatchToProps = dispatch => {
    return {
        onRegFormSubmit: (formData) => dispatch({type: actionTypes.REGFORM_SUBMIT, payload: {...formData} })
    };
}


export default connect(null, mapDispatchToProps)(withStyles(styles)(RegistrationForm));