
import React from 'react';
import { Component } from 'react';
import axios from 'axios';

import {
    Container, 
    Typography,
    Grid, 
    Card,
    CardHeader,
    CardContent,
    Button,
    TextField,
    TextareaAutosize,
    Dialog,
    DialogTitle,
    DialogContent, 
    DialogContentText,
    DialogActions,
    Image,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    CircularProgress,
    Backdrop,
} from '@material-ui/core';
import { ThemeProvider, withStyles } from '@material-ui/core/styles';

import { API_URL } from './config';
import { sub } from 'date-fns';

class EncodedGrades extends Component{

    state = {
        appNo: this.props.appNo,
        encodedGrades: null,
        Eng_Gr9: '',
        Eng_Gr10: '',
        Eng_Gr11: '',
        Mat_Gr9: '',
        Mat_Gr10: '',
        Mat_Gr11: '',
        Sci_Gr9: '',
        Sci_Gr10: '',
        Sci_Gr11: '',
        Grade_9: '',
        Grade_10: '',
        Grade_11: '',
        College_GPA: '',
        userid: '',
        remarks: '',
        hasClickedSaveGrades: false,
        successfulGradeChange: false,

      
    }

    changeHandler = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        }, () => {
            console.log(`currentPropChanged: ${[e.target.id]} | currentPropVal: ${this.state[e.target.id]}`)
        });
    }

    getEncodedGrades = () => {
        axios.get(`${API_URL}/admission/getEncodedGrades/${this.props.appNo}`)
            .then( (response) => {
                console.log(`encodedGradesResponse: ${JSON.stringify(response.data)}`);
                let fetchedEncodedGrades = response.data;        
                this.setState({
                    encodedGrades: response.data
                }, () => {
                    Object.keys(fetchedEncodedGrades).forEach( (subject) => {
                        for(const grade of fetchedEncodedGrades[subject]){
                            const gradeObj = {[ grade.id ]: grade.value}
                            this.setState({
                                [grade.id] : grade.value
                            });
                        }
                    })
                });
                
                console.log(`encodedGradesState: ${JSON.stringify(this.state)}`);
                
                
            });
    }

    handleClickSaveGrade = () => {
        this.setState({hasClickedSaveGrades: true});
    }
    handleCloseConfirmSaveGrades = () => {
        this.setState({hasClickedSaveGrades: false});
    }

    handleCloseSuccessfulGradeChange = () => {
        this.setState({
            successfulGradeChange: false,
                 
        });
    }

    handleSaveGradeChange = () => {
        let {encodedGrades, ...payload} = this.state; 
        axios.post(`${API_URL}/admission/updateEncodedGrades`, payload)
            .then( (response) => {
                if(response.data.status){
                    this.setState({ 
                        hasClickedSaveGrades: false,
                        successfulGradeChange: true
                    });
                    this.getEncodedGrades();
                }
                // console.log(`updateEncodedGradesResponse: ${response.data.status}`);
            });
    }

    componentDidUpdate(prevProps){
        if(this.props.appNo !== prevProps.appNo){
            this.setState({appNo: this.props.appNo}, () => {
                this.getEncodedGrades();
            })
        }
    }

    componentDidMount() {
        this.getEncodedGrades();
        this.setState({userid: localStorage.getItem('user')}, () => {
            console.log( `userID: ${this.state.userid}`);
        })

        console.log(`propsIsTransferee: ${this.props.isTransferee}`);
    }

    render() {
        
        const encodedGradesListMath = this.state.encodedGrades && this.state.encodedGrades.math ? this.state.encodedGrades.math.map( (subject) => {
            return (
                subject.label === 'Grade 9' ?
                    <React.Fragment>
                        <Typography variant="h6">Math</Typography> 
                        <TextField label={subject.label} id={subject.id} value={this.state[subject.id]} onChange={this.changeHandler} />
                    </React.Fragment> : <TextField label={subject.label} id={subject.id} value={this.state[subject.id]} onChange={this.changeHandler}/>
            )
        }) : null
        
        const encodedGradesListEnglish = this.state.encodedGrades && this.state.encodedGrades.english ? this.state.encodedGrades.english.map( (subject) => {
            return (
                subject.label === 'Grade 9' ?
                    <React.Fragment>
                        <Typography variant="h6">English</Typography> 
                        <TextField label={subject.label} id={subject.id} value={this.state[subject.id]} onChange={this.changeHandler}/>
                    </React.Fragment> : <TextField label={subject.label} id={subject.id} value={this.state[subject.id]}  onChange={this.changeHandler} />
            )
        }) : null

        const encodedGradesListScience = this.state.encodedGrades && this.state.encodedGrades.science ? this.state.encodedGrades.science.map( (subject) => {
            return (
                subject.label === 'Grade 9' ?
                    <React.Fragment>
                        <Typography variant="h6">Science</Typography> 
                        <TextField label={subject.label} id={subject.id} value={this.state[subject.id]} onChange={this.changeHandler} />
                    </React.Fragment> : <TextField label={subject.label} id={subject.id} value={this.state[subject.id]}  onChange={this.changeHandler} />
            )
        }) : null
        
        const encodedGradesListGPA = this.state.encodedGrades && this.state.encodedGrades.gpa ? this.state.encodedGrades.gpa.map( (subject) => {
            return (
                subject.label === 'Grade 9' ?
                    <React.Fragment>
                        <Typography variant="h6">GPA</Typography> 
                        <TextField label={subject.label} id={subject.id} value={this.state[subject.id]} onChange={this.changeHandler} />
                    </React.Fragment> : <TextField label={subject.label} id={subject.id} value={this.state[subject.id]}  onChange={this.changeHandler} />
            )
        }) : null

        const encodedGradesListTransferee = this.state.encodedGrades && this.state.encodedGrades.gpa ? this.state.encodedGrades.gpa.map( (subject) => {
            return (
                subject.label === 'College' ?
                    <React.Fragment>
                        <Typography variant="h6">College GPA</Typography> 
                        <TextField label={subject.label} id={subject.id} value={this.state[subject.id]} onChange={this.changeHandler} />
                    </React.Fragment> : null
            )
        }) : null

        return (
            <React.Fragment>
                <Grid item>
                    {this.props.isTransferee ? 
                        encodedGradesListTransferee
                    : 
                        [encodedGradesListMath,
                        encodedGradesListEnglish,
                        encodedGradesListScience,  
                        encodedGradesListGPA]
                    }
                    
                </Grid>  
                <Grid item>
                    <Button onClick={this.handleClickSaveGrade} variant="outlined" color="secondary">Save</Button>
                </Grid>

                {/* Confirm Grade Change Dialog */}
                <Dialog open={this.state.hasClickedSaveGrades} >
                    <DialogTitle>Confirm Grade Change</DialogTitle>
                    <DialogContent>
                        <Typography variant="h6">
                            You are about to update the encoded grades of this applicant.
                            Please click 'Confirm' to finalize this action. 
                            You may also add relevant descriptive remarks/comments in the text box below.
                        </Typography>
                        <TextareaAutosize id="remarks" onChange={this.changeHandler} rowsMin={8} style={{width:"100%"}} placeholder="Enter descriptive remarks"/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleSaveGradeChange}  color="primary">
                            Confirm
                        </Button>
                        <Button onClick={this.handleCloseConfirmSaveGrades}  color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Successful Grade Update Dialog */}
                <Dialog open={this.state.successfulGradeChange} >
                    <DialogTitle>Grades Update Success</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Typography variant="h6" displayInline>The applicant's encoded grades have been updated.</Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseSuccessfulGradeChange}  color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>   
        );
    }

}

export default EncodedGrades;
