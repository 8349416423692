import React, { useState, useCallback } from 'react';

import {
    Grid,
    Paper,
    IconButton,
    Typography,
    Divider,
    Card,
    CardHeader,
    CardContent,
    TableContainer,
    TableRow,
    Table,
    TableHead,
    TableCell,
    TableBody
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import CameraAltIcon from '@material-ui/icons/CameraAlt';

const rankingDetails = (props) => {

    const [rankingDetails, setRankingDetails] = useState(null);
    const [rows, setRows] = useState(null);
    const [columns, setColumns] = useState(null);
    
    // console.log(`tableRows: ${JSON.stringify(props.tableRows)}`); 
    // console.log(`tableColumns: ${JSON.stringify(props.tableColumns)}`); 
    console.log(`tableRowsFinalGrades: ${props.tableRowsFinalGrades}`);

    const tableRowsFinalGrades = [
        {id: 1, subject: "English", grade9: 99.9, grade10: 99.9, grade11: 99.9, average: 99.9},
        {id: 2, subject: "Math", grade9: 99.9, grade10: 99.9, grade11: 99.9, average: 99.9},
        {id: 3, subject: "Science", grade9: 99.9, grade10: 99.9, grade11: 99.9, average: 99.9}
    ];

    const tableRowsGPA = [
        {id: 1, grade9: 69.9, grade10: 70.9, grade11: 80.9, average: 90.9 }
    ];

    const tableRowsIncome = [
        {id: 1, source: 'Mother', income: 'PHP 0.00 - PHP 60,000.00', incomePts: 100},
        {id: 2, source: 'Father', income: 'PHP 120,001.00 - PHP 180,000.00', incomePts: 70},
        {id: 3, source: 'Guardian', income: 'PHP 120,001.00 - PHP 180,000.00', incomePts: 70}
    ]


    return(
        <div style={{ height: 400, width: '100%' }}>
    
            <Grid item container xs={12} sm={12}>
                <Grid item container xs={4} sm={5}>
                    <Paper style={{minHeight:"150px",minWidth:"100%",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
                        {/* <IconButton  style={{fontSize:"1.2rem"}}>
                            <CameraAltIcon />
                        </IconButton> */}
                        <img src={props.applicantProfilePhoto ? props.applicantProfilePhoto.fileSrc : ''} width="100%" height="100%" display="block" />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={7} style={{padding:'0 10px'}}>
                    <Typography style={{fontSize:'1.15rem'}}>{props.applicantInfo ? props.applicantInfo.appNo: ''}</Typography>
                    <Typography style={{fontSize:'1.15rem',fontWeight:'bold'}}>{props.applicantInfo ? props.applicantInfo.appName : ''}</Typography>
                    
                    {props.userType !== 'dean' ?
                        <Typography style={{fontSize:'0.9rem',margin:'10px auto'}}>{props.applicantInfo ? props.applicantInfo.passCode : null}</Typography>
                    : null }

                    <Typography style={{fontSize:'0.9rem',margin:'10px auto'}}>{props.applicantInfo ? props.applicantInfo.schoolSH : null}</Typography>
                    <Typography style={{fontSize:'0.9rem',margin:'10px auto'}}>{props.applicantInfo ? props.applicantInfo.track : null} | {props.applicantInfo ? props.applicantInfo.strand : null}</Typography>
                
                    
                </Grid>
            </Grid>

            <Divider />
            
            {/* Course Preferences */}
            <Grid item container xs={12} sm={12}>
                <Typography style={{fontSize:'1rem',width:'50%',textAlign:'center',backgroundColor:'#dcd9d9',marginTop:'5px'}}>Course Preferences</Typography>
                <Typography style={{fontSize:'0.9rem',width:'100%'}}>
                    <span style={{fontWeight:'bold'}}>1st:</span>&nbsp;&nbsp;
                        {props.applicantInfo ? props.applicantInfo.courseChoice1 : ''} 
                        {
                            props.applicantInfo ? 
                                props.applicantInfo.courseChoiceMajor1 ? `| ${props.applicantInfo.courseChoiceMajor1}` : null
                            : null
                        } 
                </Typography>
                <Typography style={{fontSize:'0.9rem',width:'100%'}}>
                    <span style={{fontWeight:'bold'}}>2nd:</span>&nbsp;&nbsp;
                    {props.applicantInfo ? props.applicantInfo.courseChoice2 : ''} 
                    {
                        props.applicantInfo ? 
                            props.applicantInfo.courseChoiceMajor2 ? `| ${props.applicantInfo.courseChoiceMajor2}` : null
                        : null
                    } 
                </Typography>
                <Typography style={{fontSize:'0.9rem',width:'100%'}}>
                    <span style={{fontWeight:'bold'}}>3rd:</span>&nbsp;&nbsp;
                    {props.applicantInfo ? props.applicantInfo.courseChoice3 : ''} 
                    {
                        props.applicantInfo ? 
                            props.applicantInfo.courseChoiceMajor3 ? `| ${props.applicantInfo.courseChoiceMajor3}` : null
                        : null
                    } 
                </Typography>
            </Grid>

            {/* Final Grades */}
            <Grid item container xs={12} sm={12} style={{marginTop:'10px'}}>
                <Typography align="center" variant="h6">Final Grades</Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Subject</TableCell>
                                <TableCell align="center">Grade 9</TableCell>
                                <TableCell align="center">Grade 10</TableCell>
                                <TableCell align="center">Grade 11</TableCell>
                                <TableCell align="center">Average</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.tableRowsFinalGrades.map( (row) => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row" style={{textTransform:'capitalize'}}>
                                        {row.subject}
                                    </TableCell>
                                    <TableCell align="center">{row.grade9}</TableCell>
                                    <TableCell align="center">{row.grade10}</TableCell>
                                    <TableCell align="center">{row.grade11}</TableCell>
                                    <TableCell align="center">{row.average}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableRow>
                                <TableCell colSpan={4} align="center">
                                    FINAL GRADES AVERAGE
                                </TableCell>
                                <TableCell align="center" style={{fontSize:"14px",fontWeight:"bold"}}>
                                    {props.finalGradesAvg}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={4} align="center">
                                    FINAL GRADES WEIGHT
                                </TableCell>
                                <TableCell align="center" style={{fontSize:"20px",fontWeight:"bold"}}>
                                    {props.finalGradesWeight}
                                </TableCell>
                            </TableRow>
                        </Table>
                    </TableContainer>
                </TableContainer>

            </Grid>
        
            {/* GPA Grades */}
            <Grid item container xs={12} sm={12}>
                <Typography align="center" variant="h6">GPA Grades</Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Grade 9</TableCell>
                                <TableCell align="center">Grade 10</TableCell>
                                <TableCell align="center">Grade 11</TableCell>
                                <TableCell align="center">Average</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.tableRowsGPA.map( (row) => (
                                <TableRow key={row.id}>
                                    {/* <TableCell component="th" scope="row">
                                        {row.subject}
                                    </TableCell> */}
                                    <TableCell align="center">{row.grade9}</TableCell>
                                    <TableCell align="center">{row.grade10}</TableCell>
                                    <TableCell align="center">{row.grade11}</TableCell>
                                    <TableCell align="center">{row.average}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableRow>
                                <TableCell colSpan={4} align="center">
                                    GPA GRADES AVERAGE
                                </TableCell>
                                <TableCell align="center" style={{fontSize:"14px",fontWeight:"bold"}}>
                                    {props.gpaGradesAvg}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={4} align="center">
                                    GPA GRADES WEIGHT
                                </TableCell>
                                <TableCell align="center" style={{fontSize:"20px",fontWeight:"bold"}}>
                                    {props.gpaGradesWeight}
                                </TableCell>
                            </TableRow>
                        </Table>
                    </TableContainer>
                </TableContainer>
            </Grid>
            
            {/* Income Source */}
            <Grid item container xs={12} sm={12}>
                <Typography align="center" variant="h6">Income Source</Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Source</TableCell>
                                <TableCell align="center">Annual Income</TableCell>
                                <TableCell align="center">Equivalent Points</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.tableRowsIncome.map( (row) => (
                                <TableRow key={row.id}>
                                    <TableCell align="center">{row.source}</TableCell>
                                    <TableCell align="center">{row.income}</TableCell>
                                    <TableCell align="center">{row.incomePts}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableRow>
                                <TableCell colSpan={3} align="center">
                                    INCOME SOURCE AVERAGE
                                </TableCell>
                                <TableCell align="center" style={{fontSize:"14px",fontWeight:"bold"}}>
                                    {props.incomeSourceAvg}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={3} align="center">
                                    INCOME SOURCE WEIGHT
                                </TableCell>
                                <TableCell align="center" style={{fontSize:"20px",fontWeight:"bold"}}>
                                    {props.incomeSourceWeight}
                                </TableCell>
                            </TableRow>
                        </Table>
                    </TableContainer>
                </TableContainer>
            </Grid>
            
            

        </div>
    );
}

export default rankingDetails;

