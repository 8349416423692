import React from 'react';
import ImageCropper from './ImageCropper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import {
    Container,
    Grid,
    Typography,
    Button, 
    Dialog,
    DialogTitle,
    DialogContent, 
    DialogContentText,
    DialogActions,
    Paper,
    Slider,
    IconButton,

} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const PassportPhotoDialog = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    console.log(`nisulod sa passportPhoto component`);
    return (
        <Dialog
            open={props.chooseIDPhotoClicked} 
            fullScreen={fullScreen}
        >
            <DialogTitle style={{paddingBottom:"0"}}>
                Photo w/ ID Card
                <IconButton style={{textAlign:"right"}} onClick={props.closeDialog} >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{paddingTop:"0"}}>
                <Container maxWidth="sm" style={{padding:"0"}}>
                    {!props.file_idPhoto ? 
                        (<Grid item container>
                            <Typography align="justify" variant="body1">
                            Upload a passport size digital photo of yourself holding a valid ID card beside your face.
                                    Please ensure that your face and name in the ID card are recognizable in the uploaded photo.
                                    The maximum file size allowed is 2MB and the file types accepted are JPEG and PNG.
                            </Typography>
                        </Grid>)
                    : !props.isReviewingPhoto  ?
                            (<Grid item container>
                                <Typography align="justify" variant="body1">
                                    To ensure that your photo fits nicely within the passport size dimensions, you may position the
                                    photo by clicking and dragging it within the outlined square and/or zooming it in by using the 
                                    zoom slider.
                                </Typography>
                            </Grid> )
                        :
                            (<Grid item container>
                                <Typography align="justify" variant="body1">
                                    Review your photo. To finalize your changes, click Save. To continue editing, click Back.
                                </Typography>
                            </Grid> )
                    } 

                    <Grid item container style={{textAlign:"center",marginTop:"15px",padding:"0"}}>
                        <Grid item xs={12} sm={12}>
                            <input type="file" id="file_idPhoto" onChange={props.handleIDPhotoChange} hidden accept="image/*" />
                            
                            {!props.isReviewingPhoto ? 
                                (<label htmlFor="file_idPhoto">
                                    <Button
                                        variant="contained"
                                        component="span"
                                    >{props.file_idPhoto ? 'Change Photo' : 'Choose File' }</Button>
                                </label>)
                            : null
                            }
                        
                        </Grid>
                        <div style={{margin:"0 auto",width:"100%"}}>
                        {props.file_idPhoto ? ( 
                            <ImageCropper 
                                selectedImage={props.file_idPhotoImgSrc}
                                onPreview={props.handlePhotoReview}
                                returnFromPreview={props.handlePhotoReviewClose}
                                onSave={props.handleIDPhotoSave}
                            />
                        ) : '' }
                        </div>  
                    </Grid>
                </Container>
            </DialogContent>
        </Dialog>
    ); 
}

export default PassportPhotoDialog;