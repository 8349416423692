import * as actionTypes from './actions';


const initialState = {

};

const reducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.REGFORM_SUBMIT:
            const tempObj = action.payload.lastName;
            console.log('temObj: ' + tempObj);
            return tempObj;
        default:
            return state;
    }

}

export default reducer;