import React from 'react';
import { Component } from 'react';
import axios from 'axios';
import { Container, Link, Typography, Button } from '@material-ui/core';
import AppDocPdf from './AppDocPdf';
import { format } from 'date-fns';

import { PDFDownloadLink, BlobProvider, pdf, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer'


import usepLogoImage from './../landing-image.png' ; 
import usepLogoHeader from './../usep-logo.png' ; 


class AppStatusChecker extends Component{

    state = {
        fileSrc: null,
        passCode:`sydfl1234l`,
        appNo:`2021-000000001`,
        appName:`Jandayan, Michael Anthony R.`,
        blob: null,
        dateGenerated: format(new Date(),'MM/dd/yyyy') ,

    }


    componentDidMount(){
        // this.getUploadedFile('2021-0000000039',6);
    }

    render(){
        
        return (
            <Container maxWidth="lg">
                <AppDocPdf 
                    passCode={this.state.passCode} 
                    appNo={this.state.appNo} 
                    appName={this.state.appName} 
                    imgSrc={usepLogoImage}
                />
               
                <PDFDownloadLink 
                    document={
                        <AppDocPdf 
                            passCode={this.state.passCode} 
                            appNo={this.state.appNo} 
                            appName={this.state.appName} 
                            imgSrc={usepLogoImage}
                            headerLogo={usepLogoHeader}
                            dateGenerated={this.state.dateGenerated}
                   />} fileName='admissionDoc'>
                    {({ blob, url, loading, error }) => {
                        // if(url){
                        //     const link = document.createElement("a");
                        //     link.download = 'appdoc';
                        //     link.href = url;
                        //     document.body.appendChild(link);
                        //     link.click();
                        //     document.body.removeChild(link);
                        // }

                        return (
                            loading ? 'Loading document...' : 
                                <div>
                                    <a id="hiddenPdf" href={url} style={{display:'none'}}> Download</a>
                                    <label htmlFor="hiddenPdf">
                                        <Button variant="contained" style={{textDecoration:'none'}}>Download Application Document</Button>
                                    </label>
                                </div>
                        )
                    } }
                </PDFDownloadLink>

            </Container>
            
        );
    }
}

export default AppStatusChecker

// get all applications (appNo,name,)
//