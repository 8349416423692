import React from 'react';
import { GoogleLogout } from 'react-google-login';

const clientId = '160410271574-6jb7q1t9jb6cnkv4mrha41b6b7h4l61d.apps.googleusercontent.com';

function Login(){
    const onSuccess = (res) => {
        console.log(`You have successfully logged out.`);
    }

    const onFailure = (res) => {
        console.log(`[Login failed] res:${res}`);
    }

    return(
        <div>
            <GoogleLogout
                clientId={clientId}
                buttonText="Logout"
                onLogoutSuccess={onSuccess}
            />  
        </div>
    )
}

export default Login;