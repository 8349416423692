import { Typography,Grid,AppBar,Toolbar, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import logo from '../oneLogo.png';
import sdmdLogo from '../sdmd-logo.png';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles({
    logo: {
        maxWidth: 280,
    },
    appBar: {
        background: '#fff'
    }
});


const header = () => {
    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return(
        <Grid item>
            <AppBar 
                position="static"
                className={classes.appBar}
            >
                <Toolbar>
                    {smallScreen ? 
                        <img src={logo} alt="header" className={classes.logo} />
                    :
                        <div style={{margin:"auto",display:"flex",alignItems:"center",justifyContent:"space-between", width:"100%"}}>
                            <img src={logo} alt="header" style={{height:"48px"}} />
                            <img src={sdmdLogo} alt="sdmd-logo" style={{height:"48px"}} />
                        </div>
                    }
                    {/* <div>
                        <IconButton onClick={}>
                            <MailOutlineIcon />Message Us
                        </IconButton>
                    </div> */}
                </Toolbar>
            </AppBar> 
        </Grid>
    );
};

export default header;

