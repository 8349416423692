import React, { useState } from 'react';
import { 
    Container, 
    Typography,
    Grid, 
    Button,
    Paper,
    TableContainer,
    TableRow,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent, 
    DialogContentText,
    DialogActions,
} from '@material-ui/core';

const listOfProgramsWithSlotsBeta = (props) => { 
    
    let [selectBtnClicked, setSelectBtnClicked] = useState(false);
    let [selectedProgram, setSelectedProgram] = useState(null);
    // let [selectedMajorName, setSelectedMajorName] = useState('');
    let [submittingState, setSubmittingState] = useState(false);
    let [userEnteredPasscode, setUserEnteredPasscode] = useState('');
    let [passCodeIsValid, setPassCodeIsValid] = useState(false);
    let [passCodeHelperText, setPassCodeHelperText] = useState('');
    let idForTableRow = 0;

    const handlePassCodeInput = (e) => {    
        // alert(`passCode entered: ${e.target.value}`);
        setUserEnteredPasscode(e.target.value);
        
    }

    const handleCancelConfirmDialog = () => {
        setSelectBtnClicked(false);
        setPassCodeIsValid(false);
        setUserEnteredPasscode('');
        setPassCodeHelperText('');
    }

    const handleSelectProgram = (program) => {
        setSelectedProgram(program);
        setSelectBtnClicked(true); 
    }

    const handleConfirmBtnClick = () => {
        if(userEnteredPasscode === props.passCode){
            setPassCodeIsValid(true);
            setPassCodeHelperText('');
            props.handleSelectAvailProgramClick(selectedProgram,userEnteredPasscode);
            setSelectBtnClicked(false); 
        }else{
            setPassCodeIsValid(false);
            setPassCodeHelperText('Incorrect passcode.');
        }
    }

    return(
        <Container maxWidth="sm">
            <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" colSpan={2}>List of Programs w/ Available Slots</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { props.programList.map( (program) => {
                                idForTableRow++;
                                return(
                                    <TableRow key={idForTableRow}>
                                        <TableCell component="th" scope="row" style={{textTransform:'capitalize'}}>
                                            <div>{program.ProgName} {program.MajorName ? `major in ${program.MajorName}` : ''}</div>
                                            <div>{program.CampusName}</div>
                                            <div style={{fontWeight:'bold'}}>{`(Available slots: ${program.availableSlots} )`}</div>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Button 
                                                onClick={() => { handleSelectProgram(program) }}
                                                variant="contained" align="center" color="primary" 
                                                style={{margin:"15px auto",borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} 
                                            >   
                                                Select
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                )
                            } )}
                        </TableBody>
                    </Table>
                </TableContainer>
            
            {/* Program Selection Confirmation Dialog */}
            <Dialog fullScreen open={selectBtnClicked} >
                <DialogContent>
                    <DialogContentText >
                        <Typography paragraph variant="body1">
                            You are about to select the academic program: &nbsp;&nbsp;
                            <div style={{fontWeight:'bold'}}>
                                {selectedProgram ? selectedProgram.ProgName : null}
                                {selectedProgram ? (selectedProgram.MajorName ? ` major in ${selectedProgram.MajorName}` : null ) : null}
                            </div>
                            <br/>
                            Please key in your passcode and hit the 'Confirm' button to confirm your selection.
                            <br/> 
                            <span style={{color:'maroon',fontSize:'0.9rem',fontStyle:'italic'}}>(Warning! This cannot be undone.)</span> 
                        </Typography>
                    </DialogContentText>
                    <TextField 
                        id="passCode"
                        name="passCode"
                        label="Pass Code"
                        onBlur={handlePassCodeInput}
                        // onChange={handlePassCodeInput}
                        fullWidth
                        variant="outlined"
                        required
                        error={!passCodeIsValid}
                        helperText={passCodeHelperText}    
                    />
                    <Button autoFocus onClick={handleCancelConfirmDialog} color="primary">
                        Cancel
                    </Button>
                    {/* <Button onClick={handleConfirmBtnClick} disabled={!passCodeIsValid} color="primary"> */}
                    <Button onClick={handleConfirmBtnClick} color="primary">
                        Confirm
                    </Button>
                </DialogContent>
            </Dialog>

        </Container>
    )

}

export default listOfProgramsWithSlotsBeta;
