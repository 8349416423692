import React from 'react';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Container from '@material-ui/core/Container';
import { 
    Button, 
    Card, 
    CardContent, 
    CardHeader, 
    Select, 
    MenuItem, 
    TextField, 
    InputLabel, 
    Dialog,
    DialogTitle,
    DialogContent, 
    DialogContentText,
    DialogActions,
    Paper,
    Slider,
    IconButton,
    ListItem,
    AppBar,
    Toolbar,
    ListItemText,
    Divider,
    Box,
    List,
    Avatar,
    CircularProgress,
    Backdrop,
    FormGroup,
    Switch,
    Checkbox
} from '@material-ui/core';

import landingImage from './../landing-image.png' ;
import dataPrivacyImage from './../DataPrivacy.jpg' ;
import usepLogoHeader from './../usep-logo.png' ; 

const LandingPage = () => {

    return (
        <div>
            <Dialog fullScreen open={true}>
                <DialogContent style={{textAlign: "center"}}>
                    <React.Fragment>
                            <Container maxWidth="sm">
                                <img src={usepLogoHeader} style={{width:"20%",display:"block",margin:"0 auto"}} />
                                {/* <img src={landingImage} style={{width:"70%",marginTop:"-22px"}} /> */}
                                {/* <Typography variant="h6" style={{fontSize:"1.5rem"}}>The admission application result is not available at this time. An official announcement will be made in time for the release of the USePAT Results!</Typography> */}
                                {/* <Typography variant="h6" style={{fontSize:"1.5rem"}}>The admission application result is not yet available at this time. Please try again later.</Typography> */}
                                {/* <Typography variant="h6" style={{fontSize:"1.5rem"}}>The confirmation for qualified applicants is now closed. Those who are on the waiting list are advised to visit this page again for updates. </Typography> */}
                                <Typography variant="h6" style={{fontSize:"1.5rem"}}>The period for checking the application results has already ended. </Typography>
                                {/* <Typography variant="h6" style={{fontSize:"1.5rem"}}>Welcome!</Typography>
                                <Typography variant="h6" style={{fontSize:"1rem"}}>to the</Typography> */}
                                {/* <Typography variant="h6" style={{fontSize:"1.4rem",color:"#d75d5f"}}>University of Southeastern Philippines</Typography> */}
                                {/* <Typography variant="h6" style={{marginTop:"-7px"}}>Online Application for Admission</Typography> */}
                            </Container>
                    </React.Fragment>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default LandingPage;