
import React from 'react';
import { Component } from 'react';
import axios from 'axios';

import {
    Container, 
    Typography,
    Grid, 
    Card,
    CardHeader,
    CardContent,
    Button,
    TextField,
    TextareaAutosize,
    Dialog,
    DialogTitle,
    DialogContent, 
    DialogContentText,
    DialogActions,
    Image,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    CircularProgress,
    Backdrop,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ThemeProvider, withStyles } from '@material-ui/core/styles';

import { API_URL } from './config';
import { sub } from 'date-fns';

class EncodedIncome extends Component{

    state = {
        appNo: this.props.appNo,
        encodedIncome: null,
        annualIncomeOptions: null,
        Father_Income: '',
        Mother_Income: '',
        Guardian_Income: '',

        userid: '',
        remarks: '',
        hasClickedSaveIncome: false,
        successfulIncomeChange: false,
    }

    changeHandler = (prop,value) => {
        this.setState({
            [prop]: value
        }, () => {
            // console.log(`currentPropChanged: ${[e.target.id]} | currentPropVal: ${this.state[e.target.id]}`)
        });
    }

    getEncodedIncome = () => {
        axios.get(`${API_URL}/admission/getEncodedIncome/${this.props.appNo}`)
            .then( (response) => {
                console.log(`encodedIncomeResponse: ${JSON.stringify(response.data)}`);
                let fetchedEncodedIncome = response.data; 
                
                Object.keys(fetchedEncodedIncome).forEach( (key) => {
                    this.setState({
                        [key] : fetchedEncodedIncome[key]
                    });
                })

                this.setState({
                    encodedIncome: fetchedEncodedIncome
                });
            });
    }

    handleClickSaveIncome = () => {
        this.setState({hasClickedSaveIncome: true});
    }
    handleCloseConfirmSaveIncome = () => {
        this.setState({hasClickedSaveIncome: false});
    }

    handleCloseSuccessfulIncomeChange = () => {
        this.setState({
            successfulIncomeChange: false,
                 
        });
    }

    handleSaveIncomeChange = () => {
        let {encodedIncome, annualIncomeOptions, ...payload} = this.state; 
        axios.post(`${API_URL}/admission/updateEncodedIncome`, payload)
            .then( (response) => {
                if(response.data.status){
                    this.setState({ hasClickedSaveIncome: false,
                        successfulIncomeChange: true});
                }
                console.log(`updateEncodedIncomeResponse: ${response.data.status}`);
            });
    }
    
    getAnnualIncomeList = () => {
        axios.get(`${API_URL}/admission/getAnnualIncomeList`)
            .then( (response) => {
                this.setState({annualIncomeOptions: response.data});
                 console.log(`annualIncome: ${this.state.annualIncomeOptions}`);
            });
    }

    componentDidUpdate(prevProps){
        if(this.props.appNo !== prevProps.appNo){
            this.setState({appNo: this.props.appNo}, () => {
                this.getEncodedIncome();
            });
        }
    }

    componentDidMount() {
        this.getAnnualIncomeList();
        this.getEncodedIncome();
        this.setState({userid: localStorage.getItem('user')}, () => {
            console.log( `userID: ${this.state.userid}`);
        })
    }


    render() {
        
        let encodedIncomeList = [];
        
         if(this.state.encodedIncome && this.state.annualIncomeOptions) { 
            Object.keys(this.state.encodedIncome).forEach( (key) => {
                let encodedIncome = this.state.annualIncomeOptions.filter( (elem) => {
                    return elem.income_id === this.state.encodedIncome[key] ;
                })

                let formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'PHP'
                });
                
                let defaultObject = {};
                if(encodedIncome[0]){
                    defaultObject = {...encodedIncome[0], income_range: `${formatter.format(encodedIncome[0].income_from)} - ${formatter.format(encodedIncome[0].income_to)}`}
                }

                if( this.state.encodedIncome[key] !== null){
                    encodedIncomeList = [...encodedIncomeList, 
                        <Autocomplete 
                            id={key}
                            name={key}
                            options={this.state.annualIncomeOptions.map( (incomeBracket) => {
                                let formatter = new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'PHP'
                                });
                                console.log(`incomeBracket: ${JSON.stringify(incomeBracket)}`);
                                return {
                                    ...incomeBracket,
                                    income_range: `${formatter.format(incomeBracket.income_from)} - ${formatter.format(incomeBracket.income_to)}`
                                }
                            })}
                            defaultValue={defaultObject}
                            getOptionLabel={(option) => option.income_range.toString()}
                            fullWidth
                            onChange={(e, selectedOption) => { this.changeHandler(key, selectedOption ? selectedOption.income_id : '')} }
                            renderInput={(params) => <TextField {...params} label={key} />}
                        />
                    ];
                }
            })
        }

        return (
            <React.Fragment>
                <Grid item>
                    {encodedIncomeList}
                </Grid>  
                <Grid item>
                    <Button onClick={this.handleClickSaveIncome} variant="outlined" color="secondary">Save</Button>
                </Grid>
                
                {/* Confirm Grade Change Dialog */}
                <Dialog open={this.state.hasClickedSaveIncome} >
                    <DialogTitle>Confirm Income Change</DialogTitle>
                    <DialogContent>
                        <Typography variant="h6">
                            You are about to update the encoded income details of this applicant.
                            Please click 'Confirm' to finalize this action. 
                            You may also add relevant descriptive remarks/comments in the text box below.
                        </Typography>
                        <TextareaAutosize id="remarks" onChange={(e) => {this.changeHandler('remarks',e.target.value)} } rowsMin={8} style={{width:"100%"}} placeholder="Enter descriptive remarks"/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleSaveIncomeChange}  color="primary">
                            Confirm
                        </Button>
                        <Button onClick={this.handleCloseConfirmSaveIncome}  color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Successful Grade Update Dialog */}
                <Dialog open={this.state.successfulIncomeChange} >
                    <DialogTitle>Income Update Success</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Typography variant="h6" displayInline>The applicant's encoded income details have been updated.</Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseSuccessfulIncomeChange}  color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>

            </React.Fragment>
        );
    }

}

export default EncodedIncome;
