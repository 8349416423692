
import React, {Component} from 'react';

import { 
    Container, 
    Typography,
    Grid, 
    Dialog,
    DialogTitle,
    DialogContent, 
    DialogContentText,
    DialogActions,
} from '@material-ui/core';

import usepLogoHeader from './../usep-logo.png' ; 
import landingImage from './../landing-image.png' ;

const siteOfflineNotice = () => {

    return (

        <Dialog fullScreen open >
            <DialogContent style={{textAlign: "center"}}>
                <Container maxWidth="sm">
                    <img src={usepLogoHeader} style={{width:"20%",display:"block",margin:"0 auto"}} />
                    <Typography variant="h6" style={{fontSize:"1rem",color:"black"}}>
                        The site is currently down for scheduled maintenance. Thanks for your patience.
                    </Typography>
                </Container>
            </DialogContent>
        </Dialog>
        

    )
}

export default siteOfflineNotice;
