import React from 'react';
import ReactDOM from 'react-dom';

import { Document, Page, Text, View, StyleSheet, Image, PDFDownloadLink } from '@react-pdf/renderer'

import usepLogoImage from './../landing-image.png' ; 

const styles = StyleSheet.create({
    page: {
    //   flexDirection: 'row',
    //   backgroundColor: '#E4E4E4'
        padding: 30
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
    },
    leftColumn: {
        flexDirection: 'column',
        width: 150,
        paddingTop: 30,
        paddingRight: 15,

    }, 
    rightColumn: {
        flexDirection: 'row'
    }, 
    section: {
      marginTop: 5,
      padding: 10,
      
      textAlign: 'center',
    //   display: 'flex',
    //   flexDirection: 'row',
    //   justifyContent: 'center'
    },
    sectionValue: {
        marginTop: -10,
        padding: 10
    },
    universityHeader: {
        fontSize: 12,
    },
    docTitle: {
        fontSize: 16,
        marginBottom: 0,
        marginTop: -8
    },
    textFieldValue: {
        padding: 10,
        textAlign: 'center',
        marginTop: -15,
        fontSize: 16
    },
    textField: {
        fontSize: 12
    },
    image: {
       width: 140,
       height: 180,
       textAlign: 'center',
       marginLeft: 190
    },
    logoHeader: {
        width: 50,
        marginLeft: 250
    }
  });


const AppDocPdf = ({passCode,appNo,appName,imgSrc,headerLogo,dateGenerated}) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View >
                    <Image 
                        src={headerLogo}
                        style={styles.logoHeader}
                    />
                </View>
                <View style={styles.section}>
                    <Text style={styles.universityHeader}>University of Southeastern Philippines</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.docTitle}>Admission Application Details</Text>
                </View>
               
                <View style={styles.section}>
                    {/* <Text>Space for Profile Photo</Text> */}
                    <Image 
                        src={imgSrc}
                        style={styles.image}
                    />
                    
                </View>
                
                <View style={styles.section}>
                    <Text style={styles.textField}>Application No.:</Text>
                </View>
                <View style={styles.sectionValue}>
                    <Text style={styles.textFieldValue}>{appNo}</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.textField}>Applicant's Name:</Text>
                </View>
                <View style={styles.sectionValue}>
                    <Text style={styles.textFieldValue}>{appName}</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.textField}>Pass Code:</Text>
                </View>
                <View style={styles.sectionValue}>
                    <Text style={styles.textFieldValue}>{passCode}</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.textField}>Application Date:</Text>
                </View>
                <View style={styles.sectionValue}>
                    <Text style={styles.textFieldValue}>{dateGenerated}</Text>
                </View>
                
             
            </Page>
        </Document>
    )
};


export default AppDocPdf;


