import React from 'react';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';


const PdfViewer = (props) => {
    // const [url, setUrl] = React.useState('');

    // const onChange = (e) => {
    //     const files = e.target.files;
    //     (files.length > 0) && setUrl(URL.createObjectURL(files[0]));
    // }

    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
            <div style={{width:"100%"}}>
                {/* <input type="file" accept=".pdf" onChange={onChange} /> */}

                <div style={{ height: '750px' }}>
                    {
                        props.url
                            ? (
                                <div
                                    style={{
                                        border: '1px solid rgba(0, 0, 0, 0.3)',
                                        height: '100%',
                                    }}
                                >
                                    <Viewer fileUrl={props.url} />
                                </div>
                            )
                            : null
                    }
                </div>
            </div>
        </Worker>
    )
};

export default PdfViewer;
